import React from 'react';
import './setting.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { message,Spin ,TreeSelect} from "antd";
const { TreeNode } = TreeSelect;
class Setting extends React.Component {
    state = {
        maxCities:1,
        searchKm:1,
        androidAppVersion:"",
        iosAppVersion:"",
        adsCost:0,
        taxRatio:0,
        expireAfter:0,
        adsNumbers:0,
        officeNumbers:0,
        mainPage:"MAP",
        Setting:[],
        loading:true,
        
    };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        this.getSetting()
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        this.getSetting()
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    getSetting = () =>{
        this.setState({loading:true})
        axios.get(`${BASE_END_POINT}setting`,{
          headers:{
            'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
          }
        })
        .then(response=>{
          console.log("Setting   ",response.data)
          this.setState({
            loading:false,
            Setting:response.data.setting,
            maxCities:response.data.setting.maxCities,
            adsNumbers:response.data.setting.adsNumbers,
            officeNumbers:response.data.setting.officeNumbers,
            searchKm:response.data.setting.searchKm,
            androidAppVersion:response.data.setting.androidAppVersion,
            iosAppVersion:response.data.setting.iosAppVersion,
            adsCost:response.data.setting.adsCost,
            taxRatio:response.data.setting.taxRatio,
            expireAfter:response.data.setting.expireAfter,
            adsRememberDays:response.data.setting.adsRememberDays,
            archivedAds:response.data.setting.archivedAds,
            mainPage:response.data.setting.mainPage,
          })
        })
        .catch(error=>{
          console.log("error   ",error.response)
          console.log("error2   ",error)
          this.setState({loading:false,})
        })
      }
    update = () => {
        const {maxCities,searchKm,androidAppVersion,iosAppVersion,adsCost
        ,taxRatio,expireAfter,adsRememberDays,archivedAds,adsNumbers,officeNumbers,mainPage} = this.state
            let l = message.loading('Wait..', 2.5)
            const data={
                "mainPage":mainPage,
                "officeNumbers":officeNumbers,
                "adsNumbers":adsNumbers,
                "maxCities":maxCities,
                "searchKm":searchKm,
                "androidAppVersion":androidAppVersion,
                "iosAppVersion":iosAppVersion,
                "adsCost":adsCost,
                "taxRatio":taxRatio,
                "expireAfter":expireAfter,
                "archivedAds":archivedAds,//if office package end before x days
                "adsRememberDays":adsRememberDays//ads request under status on progress
            }
            console.log(data)
            axios.put(`${BASE_END_POINT}setting/${this.state.Setting.id}`,JSON.stringify(data),{
            headers: {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            console.log("Setting UPDATED  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        
    }

   

    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.update()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
   
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('FEATURES')) return <Redirect to='/Home' />

  return (
    <div className="App">
        <Spin spinning={this.state.loading} tip={allStrings.loading} size="large" style={{color:'#00B483'}}>
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#00B483'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>info</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{allStrings.setting}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form5" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.androidAppVersion}</label>
                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.Setting?this.state.Setting.androidAppVersion:0} type="text"  id="form5" name="androidAppVersion" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form55" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.iosAppVersion}</label>
                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.Setting?this.state.Setting.iosAppVersion:0} type="text" id="form55" name="iosAppVersion" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.maxCities}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.Setting?this.state.Setting.maxCities:0} type="number" min={0} id="form65" name="maxCities" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form64" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.searchKm}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.Setting?this.state.Setting.searchKm:0} type="number" min={0} id="form64" name="searchKm" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form61" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.adsCost}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.Setting?this.state.Setting.adsCost:0} type="number" min={0} id="form61" name="adsCost" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form62" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.taxRatio}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.Setting?this.state.Setting.taxRatio:0} type="number" min={0} id="form62" name="taxRatio" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form601" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.archivedAds}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.Setting?this.state.Setting.archivedAds:0} type="number" min={0} id="form601" name="archivedAds" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form602" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.adsRememberDays}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.Setting?this.state.Setting.adsRememberDays:0} type="number" min={0} id="form602" name="adsRememberDays" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form601" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.adsNumbers}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.Setting?this.state.Setting.adsNumbers:0} type="number" min={0} id="form6001" name="adsNumbers" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form602" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.officeNumbers}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.Setting?this.state.Setting.officeNumbers:0} type="number" min={0} id="form6002" name="officeNumbers" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.expireAfter}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.Setting?this.state.Setting.expireAfter:0} type="number" min={0} id="form605" name="expireAfter" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.mainPage}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false} 
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.mainPage}
                                                value={this.state.mainPage}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({mainPage:value});
                                                }} 
                                            
                                            >
                                                <TreeNode value="MAP" title={allStrings.map} key="MAP" />
                                                <TreeNode value="SPECIAL-ADS" title={allStrings.specialAds} key="SPECIAL-ADS" />
                                                
                                            </TreeSelect>
                                        </MDBCol>
                                    </MDBRow>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                        <MDBBtn
                                        type="submit" style={{background:'#00B483',color:'#fff'}}
                                        rounded
                                        className="btn-block z-depth-1a"
                                        >
                                        <MDBIcon icon="edit" className="mr-2" />
                                            {allStrings.edit}          
                                        </MDBBtn>
                                       
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
      </MDBContainer>
      </Spin>
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(Setting)
  );