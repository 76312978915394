import LocalizedStrings from 'react-localization';

export default  new LocalizedStrings({
    en: {
        usersTable:'USERS',
        firstName:'First name',
        lastName:'Last name',
        email:'Email',
        phone:'Phone',
        block:'Block',
        action:'Action',
        yes:'yes',
        no:'no',
        areYouSureDelete:'Are you sure to delete ?',
        addNewRecored:'ADD NEW RECORD',
        first:'FIRST',
        last:'LAST',
        next:'NEXT',
        prev:'PREV',
        login:"Login",
        signin:'SIGN IN',
        password:'Password',
        userDataIncorrect:'User data in correct',
        thisUserIsnotAdmin:'This user is not admin',
        active:'Active',
        type:'Type',
        signUpFrom:'Signup from',
        address:'Address',
        updateProfile:'Update Profile',
        area:'Area',
        activeButton:'ACTIVE',
        disActiveButton:'DIS-ACTIVE',
        blockButton:'BLOCK',
        unblockButton:'UN-BLOCK',
        remove:'REMOVE',
        productsTable:'PRODUCTS',
        name:'Name',
        company:'Company',
        price:'Price',
        quantity:'Quantity',
        hasOffer:'Has offer',
        offerPrice:'Offer price',
        offerRatio:'Offer ratio',
        top:'Top',
        category:'Category',
        sallCount:'sallCount',
        userProfile:'User Info',
        addOffer:'ADD OFFER',
        removeOffer:'REMOVE OFFER',
        disActive:'Dis-Active',
        normal:'NORMAL',
        categoriesTable:'CATEGORIES',
        arabicName:'Arabic name',
        englishName:'English name',
        categoryInfo:'Category Info',
        updateCategory:'Update Category',
        cities:'Cities',
        citiesTable:'CITIES',
        delivaryCost:'Delivary cost',
        cityInfo:'City Info',
        updateCity:'Update city',
        areaInfo:'Area Info',
        updateArea:'Update area',
        username:'UserName',
        forgetPassword:'Forget Password?',
        sendCode:'Send Code',
        enter:'Enter',
        code:'Code',
        newPassword:'New Password',
        newTime:'Repeate Password',
        enterCorrectPhone:'UnCorrect Phone',
        enterCorrectEmail:'UnCorrect Email',
        correctCode:'UnCorrect Code',
        passwordMustEqualConfirmPassword:'Password Not Equal Confirm Password',
        done:'done',
        addCategory:'Add Category',
        areYouSure:"Are You Sure?",
        ok:'Ok',
        cancel:'Cancel',
        noData:'No Data',
        completeData:'Complete Require Data',
        arabicCategoryName:'Arabic Category Name',
        englishCategoryName:'English Category Name',
        categoryImg:'Category Img',
        chooseFile:'Choose File ',
        dropHere:'Drop File Here',
        add:'Add',
        edit:'Edit',
        addProduct:'Add Product',
        idFilter:' Filter (ID)',
        nameFilter:' Filter (Name)',
        cardFilter:'Filter (Card)',
        salesManFilter:'Filter (salesMan)',
        salesMan:'SalesMan',
        all:'All',
        print:'Print',
        mainImg:'Main Image',
        arabicProductName:'Arabic Product Name',
        englishProductName:'English Product Name',
        writeArabicName:'Write Arabic Name',
        writeEnglishName:'Write English Name',
        EnterQuantity:'Enter Quantity',
        purchasePrice:'Purchase Price',
        EnterPrice:'Enter Price',
        commission:'Commission',
        imgsGroup:'Imgs Group',
        cashPrice:'Cash Price',
        Properties:'Properties',
        installmentPackages:'Installment Packages',
        firstPaid:'First Paid',
        months:'Months',
        installment:'Installment',
        theInstallment:'Installment',
        pound:'Pound',
        available:'Available',
        onlyInstallment:'Only Installment',
        ratio:'Ratio',
        property:'Property',
        description:'Description',
        theMonths:'Months',
        addAds:'Add Ads',
        day:'Day ',
        show:'Visable',
        notShow:'Not Visable',
        product:'Product',
        imgOrVideo:'img / video',
        duration:'Duration',
        enterName:'Enter Name',
        enterDuration:'Enter Duration',
        chooseCategory:'Choose Category ',
        enterPhone:'Enter Phone ',
        enterLink:'Enter Link (Optional)',
        link:'Link',
        chooseCompanys:'Choose Companies (Optional)',
        chooseProducts:'Choose Products (Optional)',
        optional:'Optional',
        startDate:'Start Date',
        companies:'Companies',
        categories:'Categories',
        products:'Products',
        chooseDate:'Choose Date',
        visable:'Visable',
        addClient:'Add Client',
        chooseCity:'Choose City',
        city:'City',
        note:'Note',
        anotherPhone:'Another Phone',
        EnterPhone:'Enter Phone',
        EnterAnotherPhone:'Enter Another Phone',
        enterNote:'Enter Notes',
        enterAddress:'Enter Address',
        enterCardNum:'Enter Card Num',
        cardNum:'Card Num',
        profileImg:'Profile img',
        receipts:'Reciepts Imgs',
        cardImg:'Card Img',
        client:'Client',
        guarantor:'Guarantor',
        enterPassword:'Enter Password',
        delayPremuim:'Delay Premuim',
        payCash:'Pay Cash',
        collectPremuims:'Collect Premuims',
        printReceitp:'Print Receitp',
        sendNotif:'Send Notif',
        showLocation:'Show Location',
        sendMessage:'Send Message',
        recievedProducts:'recievedProducts',
        DliveryNewProduct:'Dlivery New Product',
        buySalesMan:'Buy SalesMan',
        collectSalesMan:'Collect SalesMan',
        firstPay:'First Pay',
        premuimCost:'Premuim Cost',
        premuimsCount:'Premuims Count',
        delayPremuims:'Delay Premuims',
        payPremuims:'Paid Premuims',
        remainPremuims:'Remain Premuims',
        payCost:'Paid Cost',
        remainCost:'Remain Cost',
        cash:'Cash',
        chooseProduct:'Choose Product',
        count:'Count',
        adsViewsCount:'Ads Views Count',
        allSoldProducts:'All Sold Products',
        TotalSalesOfAllCompanies:'Total Sales Of All Companies',
        companiesInApp:'companies In App',
        allUsersCount:'All Users Count',
        newUsers:'New Users',
        topSalesMan:'Top SalesMan',
        topCompany:'Top Company',
        topProduct:'Top Sold Product',
        topProducts:'Top Sold Products',
        ads:'Ads',
        showAll:'Show All',
        dalyViews:'Daly Views',
        allViews:'All Views',
        overView:'Over View',
        statistic:'Statistic',
        viewsCount:'Views Count',
        ordersCount:'Orders Count',
        title:'Title',
        theAdd:'The Add',
        addSalesMan:'Add SalesMan',
        lastSeen:'Last seen',
        collectedClients:'Collected Clients',
        Receivables:'Receivables',
        TransferCollections:'TransferCollections',
        receiveMoney:'Receive Money',
        changeSalesMan:'changeSalesMan',
        receivedProducts:'Received Products',
        daily:'Total Daily',
        allPremiums:'All Collect',
        additionCollect:'Addition Collect',
        finishedPremiums:'Finished Premiums',
        pendingPremiums:'Pending Premiums',
        Dues:'Dues',
        recieveReceivables:'Recieve Receivables',
        money:'Money',
        date:'Date',
        to:'To',
        from:'From',
        addSupplier:'Add Supplier',
        addTransaction:'Add Transaction',
        theNaming:'Naming',
        receive:'Receive',
        give:'Give',
        cost:'Cost',
        addCost:'Add Cost',
        chooseSalesMan:'Choose SalesMan',
        chooseReceiptType:'Choose Receipt Type',
        onlyLastMonth:'Only Last Month',
        paid:'Paid',
        monthNum:'Month Num',

        storeName:'Store Name',
        subCategory:'Sub-Category',
        StoresTable:'Stores Table',
        storeAbout:'About Store',
        workTime:'Work Time',
        salesCode:'Sales Code',
        chooseSubCategory:'Choose SubCategory',
        package:'Packge',
        discount:'Discount',
        firstPackage:'First Package',
        secondPackage:'Second Package',
        chooseArea:'Choose Area',
        storeProfile:'store Info',
        updateStore:'Update Store',
        AddCategory:'Add Category',
        addSubCategory:'Add Sub Category',
        storeUsers:' Store Users ',
        priority:'Priority',
        addStoreUser:'Add Store User',
        storePhone:'Store Phone',
        storeName_ar:'Arabic Store Name',
        childUsers:'Child Users',
        addArea:'Add Area',
        addCity:'Add City',
        branches:'Branches',
        branchName:'Arabic Branch Name',
        main:'Main',
        branch:'Branch',
        chooseStoreType:'Choose Store Type',
        chooseMainStore:'Choose Main Store',
        mainStore:'Main Store',
        storeType:'Store Type',
        salesCodeType:'salesCodeType',
        self:'Self',
        branchName_en:'English Branch Name ',
        casher:"Casher",
        endPrice:'End Price',
        NoRule:'No Discount Rule',
        Stores:'Stores',
        bills:'Bills',
        home:'Home',
        Clients:'Clients',
        moga:'Moga',
        rule:'Discount Rule',
        BillInfo:'Bill Info',
        canceled:'Canceled',
        addDiscountRule:'Add Discount Rule',
        rules:'Discount Rules',
        RuleInfo:'Discount Rule Info',
        updateRule:'Update Rule',
        billPassword:"bills Password",
        facebookLink:"Facebook Link",
        reNew:'Re New',
        problemType:'Problem Type',
        problem:'Problem',
        problems:'Problems',
        reports:'Reports',
        isReply:'is Reply',
        ProblemInfo:'Problem Info',
        reply:'Reply',
        SendNotif:'Send Notif',
        about:'About Us',
        NewsTable:'News Table',
        NewsInfo:'News Info',
        addNews:'Add News',
        news:'News',
        update:'Update',
        EventsTable:'Events Table',
        EventInfo:'Event Info',
        addEvent:'Add Event',
        Events:'Events',
        error:'error' ,
        eventDate:'Event Date',
        loadMore:'Load More',
        faculites:'Faculites',
        years:'Years',
        AddYear:'addYear',
        YearsTable:'Year Table',
        YearInfo:'Year Info',
        FaculitesTable:'Faculites Table',
        FaculityInfo:'Faculity Info',
        addFaculty:'Add Faculty',
        yearsNum:'Years Number',
        hasDivision:'Has Division',
        contactUs:'contactUs',
        faculty:'Faculty',
        year:'Year',
        department:'Department',
        division:'Divisions',
        DivisionsTable:'Divisions Table',
        AddDivision:'Add Division',
        DivisionInfo:'Division Info', 
        Divisions:'Divisions',
        DepartmentsTable:'Departments Table',
        AddDepartment:'Add Department',
        DepartmentInfo:'Department Info',
        Departments:'Departments',
        Subjects:'Subjects',
        Subject:'Subject',
        AddSubject:'Add Subject',
        SubjectInfo:'Subject Info',
        SubjectsTable:'Subjects Table',
        isAnswer:'Is Answer',
        student:'Student',
        teacher:'Teacher',
        question:'Question',
        questions:'Questions',
        AddQuestion:'Add Question',
        QuestionInfo:'Question Info',
        answer:'Answer',
        firstname:'First Name',
        admin:'Admin',
        studentsTable:'Students Table',
        students:'Students',
        TeachersTable:'Teachers Table',
        teachers:'Teacher',
        users:'Users',
        EventsAndNews:'Events & News',
        CommunityTable:'Community Table',
        AddCommunity:'Add Community',
        CommunityInfo:'Community Info',
        owner:'Owner',
        Communities:'Communities',
        lectureNumber:'Lecture Number',
        softCopy:'Soft Copy',
        hardCopy:'Hard Copy',
        LecturesTable:'Lectures Table',
        Lectures:'Lectures',
        Lecture:'Lecture',
        LectureInfo:'Lecture Info',
        AddLecture:'Add Lecture',
        files:'Files',
        videos:'Videos',
        arabicDescription:'Arabic Description',
        englishDescription:'English Description',
        arabicTitle:'Arabic Title',
        englishTitle:'English Title',
        Schedules:'Lectures Schedules',
        AddSchedule:'Add Schedule',
        Schedule:'Schedule',
        time:'Time',
        selectTime:'Select Time',
        selectDate:'Select Date',
        ScheduleInfo:'Schedule Info',
        addMoney:'Add Money',
        balance:'Balance',
        addTeacher:'Add Teacher',
        subjectsCount:'Subjects Count',
        studentsCount:'Student Count',
        teachersCount:'Teachers Count',
        questionsCount:'Questions Count',
        messagesCount:'Messages Count',
        lecturesCount:'Lectures Count',
        topLectureBuy:'Top Lecture Buy',
        topLecture:'Top Lecture',
        topStudent:'Top Student Balance',
        topActiveTeacher:'Top Active Teacher',
        topTeacher:'Top Teacher',
        SearchAbout:'Search (ads - users)',
        buyCount:'Buy Count',
        openFile:'Open File',
        activeCode:'Active Code',
        removeDeviceData:'Remove Device Data',
        viewers:'Viewers',
        addViewers:'Add Viewers',
        upload:'Upload',
        Profile:'Profile',
        logout:'Logout',
        addViewer:'Add Viewer',
        country:'Country',
        aboutUs_en:'English About Us ',
        aboutUs_ar:'Arabic About Us ',
        AddTerms:'Add Terms',
        theActivity:'The Activity',
        dayCost:'Day Cost',
        winPoints:'Win Points',
        pointCost:'Point Cost',
        adminsTable:'Admins Table',
        AddUser:'Add User',
        Cities:'Cities',
        Terms:"Terms & Conditions",
        setting:'Setting',
        notifs:'Notifications',
        countryCode:'Country Code',
        numbersCount:'Numbers Count',
        isoCode:'ISO Code',
        CountriesTable:'Countries Table',
        Countries:'Countries',
        countryInfo:'Country Info',
        updateCountry:'Update Country',
        addCountry:'Add Country',
        hint:'Hint',
        activities:'Activities',
        admins:'Admins',
        workTime_en:'Work Time English',
        workTime_ar:'Work Time Arabic',
        whatsApp:'WhatsApp',
        webSite:'Web Site',
        showOn:'Show On',
        offers:'Offers',
        offerInfo:'Offer Info',
        end:'End',
        endDate:'End Date',
        logo:'Logo',
        background:'Background',
        gallery:'Gallery',
        addActivity:'Add Activity',
        latitude:'Latitude',
        longitude:"longitude",
        topActivity:'Top Activity',
        usersCount:'Users Count',
        activityCount:'Activities Count',
        bookingCount:'Booking Count',
        offerCount:'Offer Count',
        problemCount:'Problem Count',
        topActivityRate:'Top Activity Rate',
        rate:'Rate',
        topUser:'Top user Booking',
        favActivity:'Fav Activity',
        favCount:'Fav Count',
        info:"Information",
        offer:'Offer',
        Booking:'Booking',
        bookingCode:'Booking Code',
        services:'Services',
        couponNumber:'Coupon Number',
        AddBrand:'Add Brand',
        AddSize:'Add Size',
        AddCoupon:'Add Coupon',
        theReview:'The Review',
        sizeInfo:'Size Info',
        brandInfo:'Brand Info',
        couponInfo:'Coupon Info',
        sizes:'Sizes',
        brands:'Brands',
        coupons:'Coupons',
        addColor:'Add Color',
        colorInfo:'Color Info',
        anoncementInfo:'Anoncement Info',
        addAnoncement:'Add Anoncement',
        anoncements:'Anoncements',
        Artists:'Artists',
        colors:'Colors',
        arabicQuestion:'Question in arabic',
        englishQuestion:'Question in english',
        englishAnswer:'Answer in english',
        arabicAnswer:'Answer in arabic',
        brand:'Brand',
        color:'Color',
        size:'Size',
        offerType:'Offer Type',
        Orders:'Orders',
        Search:'Search',
        online:'Online',
        finalTotal:'Final Total',
        total:'Total',
        hasPromoCode:'Has Promo Code',
        paymentSystem:'Payment System',
        PendingOrders:'Pending Orders',
        acceptedOrders:'Accepted Orders',
        deliverdOrders:'Deliverd Orders',
        refusedOrders:'Refused Orders',
        store:'Store',
        orderInfo:'Order Info',
        status:'Status',
        pending:'Pending',
        accepted:'Accepted',
        refused:'Refused',
        deliverd:'Deliverd',
        street:'Street',
        placeType:'Place Type',
        floor:'Floor',
        apartment:'Apartment',
        coupon:'Coupon',
        refuse:'Refuse',
        accept:'Accept',
        areas:'Areas',
        reason:'Reason',
        freeShipping:'FreeShipping',
        verifyRequests:'Verify Requests',
        artist:'Artist',
        artistsCount:'Artists Count',
        adminCount:'Admin Count',
        anoncementCount:'Anoncement Count',
        productsCount:'Products Count',
        pendingOrdersCount:'Pending Orders Count',
        topUserRate:'Top User Rate',
        topProductSale:'Top Product Sale',
        topProductRate:'Top Products Rate',
        topProductsRate:'Top Products Rate',
        saleCount:'Sales Count',
        discountType:'Discount Type',
        fixed:'Fixed Money',
        expireDate:'Expire Date',
        ended:'Ended',
        rejected:'Rejected',
        startTime:"StartTime",
        endTime:"EndTime",
        liveInfo:'Live Info',
        lives:'Lives',
        reject:'Reject',
        bookings:'Bookings',
        bookingInfo:'Booking Info',
        completed:'Completed',
        personsCount:'personsCount',
        service:'Service',
        shipping:'Shipping',
        free:'Free',
        notFree:'Not Free',
        notes:'Notes',
        theViewers:'The Viewers',
        durationSec:'Duration(Sec)',
        linkType:'Link Type',
        inside:'Inside',
        outside:'Outside',
        maxCities:'Allowed Cities',
        searchKm:'Search Space (KM)',
        androidAppVersion:'Android Version',
        iosAppVersion:'Ios Version',
        adsCost:'Ads Cost',
        taxRatio:'Tax Ratio',
        expireAfter:'Expiration',
        shareTitle_en:'English Share Title ',
        shareTitle_ar:'Arabic Share Title',
        shareDescription_en:'English Share Description',
        shareDescription_ar:'Arabic Share Description',
        facebook:'Facebook',
        twitter:'Twitter',
        snapChat:'SnapChat',
        youtube:'Youtube',
        instagram:'Instagram',
        androidAppLink:'Android App Link',
        iosAppLink:'Ios App Link',
        privacy_ar:'Arabic Privacy',
        privacy_en:'English Privacy',
        terms_ar:'Arabic Terms',
        terms_en:'English Terms',
        number:'Number',
        string:'String',
        boolean:'Boolean',
        list:'List',
        englishOption:'English Option',
        arabicOption:'Arabic Option',
        FeatureInfo:'Feature Info',
        AddFeature:'Add Feature',
        addRegion:'Add Region',
        regions:'Regions',
        regionInfo:'Region Info',
        Transactions:'Transactions',
        Features:'Features',
        Packages:'Packages',
        archivedAds:'Archived Ads After?',
        adsRememberDays:'Remember User About Ads After?',
        adsManagment:'Ads Management',
        propertyManagement:'Property Managment',
        availableAds:'Available Ads',
        plan:'Plan Ad',
        supervisors:'Supervisors',
        beDefault:'Be Default',
        durationType:'Duration Type',
        monthly:'Monthly',
        yearly:'Yearly',
        AddPackage:'Add Package',
        PackageInfo:'Package Info',
        offices:'Offices',
        accountType:'Account Type',
        notActive:'Not Active',
        Offices:'Offices',
        approved:'Approved',
        disApproved:'Dis-Approved',
        unBlock:'UnBlock',
        office:'Office',
        blocked:'Blocked',
        adsCount:'Ads Count',
        adsRequestCount:'Ads Request Count',
        contactRequestCount:' Contact Request Count',
        isVerified:'Is Verified',
        adsRequests:'Ads Requests',
        approvedOfficesCount:' Approved Offices',
        notApprovedOfficesCount:' Not Approved Offices',
        contractType:'Contact Type',
        notAvailable:'Not Available',
        sale:'Sale',
        rent:'Rent',
        workCity:'Work City',
        workArea:'Work Area',
        workCategory:'Work Category',
        workSubCategory:'Work Sub Category',
        agencyType:'agency Type',
        marketer:'Marketer',
        freeWork:'FreeWork',
        arabicBestFeatures:'Arabic Best Features',
        englishBestFeatures:' English Best Features',
        englishAddress:'English Address',
        arabicAddress:' Arabic Address',
        enableInstallment:'Enable Installment',
        enablePhoneContact:"Enable Phone Contact",
        contraindicationsDesc: "Contraindications Desc",
        undocumentedRightsDesc: "Undocumented Rights Desc",
        influentialInformationDesc: "Influential Information Desc",
        guaranteesDesc: "Guarantees Desc",
        commissionNumber: "Commission Number",
        contraindications: "Contraindications",
        undocumentedRights:"Undocumented Rights",
        influentialInformation: "Influential Information",
        saudiBuildingCode: "SaudiBuilding Code",
        guarantees: "Guarantees",
        advertiserClass: "Advertiser Class",
        deposit:"Deposit",
        disputes:"Disputes",
        disputesDesc:"Disputes Desc",
        commissioner:'Commissioner',
        subFeatures:"Sub Features",
        mainFeatures:"Main Feature",
        notExist:'Not Exist',
        priceTo:"Price To",
        priceFrom:"Price From",
        sizeTo:"Size To",
        sizeFrom:"Size From",
        loading:'Loading...',
        uploadFile:'Upload File',
        more:'More..',
        completedRequest:'Completed Requests',
        failedRequest:'Failed Requests',
        canceledRequest:'Canceled Requests',
        onProgressRequest:'On Progress Requests',
        subAdmin:'SUB-ADMIN',
        img:'Image',
        createdAt:'Created At',
        noProgress:'On Progress',
        new:'New',
        filterSearch:'Search by Name - Phone',
        requiredInput:'Required Input',
        correct:'Correct',
        notApproved:'Not Approved',
        clientCount:'Client Count',
        officeCount:' Office Count',
        reactions:'Reactions',
        reactionType:'Reaction Type',
        signUpProgcess:'Sign Up Progcess',
        permissions:'Permissions',
        commonds:'Commonds',
        permissionInfo:'PermissionInfo',
        addPermission:'Add Permission',
        hasAdsPackage:'Has Ads Package',
        hasPropertyPackage:'Has Property Package',
        notCompletedRequest:'Not Completed Ads Request',
        dashboard:'Dashboard',
        onProgress:'On Progress',
        permission:'Permission',
        lands:'Lands',
        floorNumber:'Floor Number',
        units:'Units',
        topOfficesRate:'Top Offices Rate',
        availableProperty:'Available Property',
        building:'Building',
        buyPackage:' Buy Package',
        transactionNum:'Transaction Number',
        success:'Success',
        faild: 'Failed',
        other:'Other',
        regaKey:' Rega Key',
        adsFilterSearch:'Search by title - id',
        adsNumbers:'Adveristments Number',
        officeNumbers:'Offices Number',
        region:'Region',
        withdrawal:'Withdrawal',
        closed:'Closed',
        contactRequests:'Contact Requests',
        adveristment:'Adveristment',
        adveristmentRequest:'adveristment Request',
        sender:'Sender',
        reciever:'Receiver',
        contactOn:'Contact On',
        theAdveristment:'The Adveristment',
        paidDepositCount:'Paid Deposit Count',
        bookingPaidCount:'Booking Paid Count',
        views:'Views List',
        searchHistory:'Search History',
        archieves:'Archives List',
        business:'Business',
        paymentTerms:'Payment Terms',
        packagePayment:'Package Payment',
        adveristmentPayment:'Adveristment Payment',
        depositPayment:'Deposit Payment',
        rentPayment:'Rent Payment',
        fixingPhase:'Fixing Phase',
        entityNumber:'Entity Number',
        entitySsuingDate:'Entity Ssuing Date',
        entityExpiryDate:' Entity Expiry Date',
        identificationType:'Identification Type',
        identificationIssuingDate:" Identification Issuing Date",
        identificationExpiryDate:" Identification Expiry Date",
        bankIban:'Bank Iban',
        identityCard:' Identity Card',
        passport:'Passport',
        identificationImgs:'Identification imgs',
        entityImgs:'Entity imgs',
        needToFix:'Need To Fix',
        sendToTap:'Send To Tap',
        tax:'Tax',
        totalCost:'Total Cost',
        default:'Default',
        disAvailable:'dis Available',
        availablee:'Available',
        BillUrl:'Bill URL',
        SearchByName:'Search by Name',
        arabicBrandName:'Arabic Brand Name',
        englishBrandName:'English Brand Name',
        removeAdsPackage:'Remove Ads Package',
        removePropertyPackage:'Remove Property Package',
        idNumber:'Id Number',
        adNumber:'Advertiser Number',
        authNumber:'Auth Number',
        bio:'Bio',
        addSeoDesc:'Add Seo Description',
        englishSeoDescription:'English Seo Description',
        arabicSeoDescription:'Arabic Seo Description',
        AddService:'Add Service',
        AddBank:'Add Bank',
        banks:'Banks',
        villa:'Villa',
        villas:'Villas',
        mainPage:'Main Page',
        specialAds:'Special Ads',
        map:'Map',
        enableSpecialAdvertisement:'enable Special Advertisement',
        disableSpecialAdvertisement:'disable Special Advertisement  ',
        enableSpecialAds:'enable Add Special Ads',
        disableSpecialAds:'disable Add Special Ads',
        userType:'User Type',
        notifType:'Notification Type',
        subjectId:'Subject Id',
        adsNotif:'Adveristment Notification',
        adsRequestNotif:'Adveristment Request Notification',
        newsNotif:'News Notification',
        appNotif:'App Notification',
        sendMarketingNotif:'Send Notification',
        marketingNotif:'Marketing Notifications',
        week:'On Week',
        month:'On Month',
        addInMarket:'Add In Market',
        removeFromMarket:'Remove From Market',
        inMarket:'In Market',
        enableAsMarketer:'Enable As Marketer',
        disableAsMarketer:'disable As Marketer',
        globalQuestion:'Global Question',
        marketQuestion:'Market Question',
        MarketTerms:'Merket Terms',
        MarketViews:'Market Views',
        marketAds:'Market Ads',
        marketingComplete:'Marketing Complete',
        marketingFailed:'Marketing Failed',
        waitingCommission:'Waiting Commission',
        marketingOnProgress:'Marketing on progress',
        marketInfo:'Market Info',
        shareLink:'Share link',
        redfCategory:'Redf Category',
        townhouse:'Townhouse',
        redfFeature:'Redf Feature',
        lastMsgDate:'Last Msg Date',
        marketersCount:'Marketers Count',
        respond:'Responded',
        notRespond:'Not Responded',
        respondStatus:'Respond Status',
        deActivate:'De Activate',
        SearchByMarketer:'Search By Marketer',
        sendToRedf:'Send To Redf',
        RedfAds:'Redf Ads',
        seoTags:'Seo Tags',
        marketers:'Marketers',
        dealOnProgress:'Deal On Progress',
        dealDone:'Deal Done',
        dealFailed:'Deal Failed',
        statusReason:'Status Reason',
        clientName:'Client Name',
        clientPhone:'Client Phone',
        addedBy:'Added By',
        viewInfo:'View Info',
        contactDone:'Contact Done',
        interests:'Interests',
        fullname:'Full Name',
        addContentDesc:'Add Content Desc',
        enableAdsRequests:'Enable Ads Requests',
        requests:'Requests',
        failed:'Failed',
        actionUser:'Action User',
        requestsCount:'Requests Count'
        
    },
    ar: {
        requestsCount:'عدد الطلبات العقاريه',
        actionUser:'الموظف',
        failed:'لم تتم',
        requests:'طلبات العملاء',
        enableAdsRequests:'تفعيل الطلبات العقاريه ',
        addContentDesc:'اضافه محتوى',
        fullname:'الاسم',
        interests:'سجل الاهتمامات',
        contactDone:'تم التواصل ',
        viewInfo:'عرض التفاصيل',
        addedBy:'اضيف بواسطه',
        clientPhone:'رقم العميل',
        clientName:'اسم العميل',
        statusReason:' سبب الاغلاق ',
        dealOnProgress:'جارى التواصل',
        dealDone:'تمت الصفقه',
        dealFailed:'لم تتم الصفقه',
        marketers:'مشتركى سوق',
        seoTags:'الاختصارات',
        RedfAds:'اعلانات الصندوق',
        sendToRedf:'اضافه الى صندوق التنميه',
        SearchByMarketer:'بحث برقم المسوق',
        deActivate:' جمد بسبب الباقه',
        respondStatus:'حاله الاستجابه',
        respond:'تم الاستجابه',
        notRespond:'بانتظار الاستجابه',
        marketersCount:'عدد المسوقين',
        lastMsgDate:'اخر رساله',
        redfFeature:'الميزه بصندوق التنميه العقاريه',
        townhouse:'تاون هاوس',
        redfCategory:'القسم صندوق التنميه العقاريه',
        shareLink:'رابط التسويق',
        marketInfo:'بيانات التسويق',
        marketingComplete:'تم التسويق',
        marketingFailed:'فشل التسويق',
        waitingCommission:'فى انتظار العموله',
        marketingOnProgress:'جارى التسويق',
        marketAds:'اعلانات سوق',
        MarketViews:'مشاهدات سوق',
        MarketTerms:'شروط سوق',
        marketQuestion:'سؤال خاص بسوق',
        globalQuestion:'سؤال عام',
        marketer:'المسوق',
        enableAsMarketer:'تفعيل كمسوق',
        disableAsMarketer:'ايقاف تفعيله كمسوق',
        commission:'العموله',
        addInMarket:'اضف الى سوق',
        removeFromMarket:'ازاله من سوق',
        inMarket:'مضاف الى سوق',
        week:'خلال اسبوع',
        month:'خلال شهر',
        marketingNotif:'اشعارات تسويقيه',
        sendMarketingNotif:'ارسال اشعار',
        subjectId:'الرقم المرجعى',
        adsNotif:'اشعار اعلان عقارى',
        adsRequestNotif:'اشعار طلب عقارى',
        newsNotif:'اشعار خبر عقارى',
        appNotif:'اشعار تطبيق',
        notifType:'نوع الاشعار',
        disableSpecialAds:'ايقاف اضافه اعلانات خاصه',
        enableSpecialAds:'تفعيل اضافه اعلانات خاصه',
        enableSpecialAdvertisement:'جعل الاعلان خاص',
        disableSpecialAdvertisement:'جعل الاعلان افتراضى',
        map:'الخريطه',
        specialAds:'الاعلانات الخاصه',
        mainPage:'الصفحه الرئيسيه',
        villas:'مجمع فيلات',
        villa:'فيلا',
        banks:'البنوك',
        AddBank:'اضافه بنك',
        AddService:'اضافه خدمه',
        englishSeoDescription:'الوصف الانجليزى فى محرك البحث',
        arabicSeoDescription:'الوصف العربى فى محرك البحث',
        addSeoDesc:'اضافه وصف لمحركات البحث',
        bio:'نبذه',
        authNumber:'رقم التسجل',
        adNumber:'رقم المعلن',
        idNumber:'رقم الهويه',
        removePropertyPackage:'ازاله باقه الاملاك',
        removeAdsPackage:'ازاله باقه الاعلانات',
        englishBrandName:'اسم العلامه التجاريه بالانجليزيه',
        arabicBrandName:'اسم العلامه التجاريه بالعربيه',
        SearchByName:'بحث بالاسم',
        BillUrl:'رابط الفاتوره',
        availablee:'متاحه',
        disAvailable:'غير متاحه',
        default:'افتراضيه',
        totalCost:'التكلفه الاجماليه',
        tax:'الضريبه',
        sendToTap:'ارسال للبوابه',
        needToFix:'يحتاج الى التعديل',
        entityImgs:'صور الترخيص',
        identificationImgs:'صور الهويه',
        passport:'باسبورت',
        identityCard:'هويه وطنيه',
        bankIban:'رقم البنك الدولى',
        identificationExpiryDate:'تاريخ انتهاء الهويه',
        identificationIssuingDate:'تاريخ اصدار الهويه',
        identificationType:'نوع الهويه',
        entityExpiryDate:'تاريخ انتهاء الترخيص',
        entitySsuingDate:'تاريخ اصدار الترخيص',
        entityNumber:'رقم الترخيص',
        fixingPhase:'مرحله التعديل',
        rentPayment:'دفع ايجار',
        depositPayment:'دفع عربون',
        adveristmentPayment:'دفع اعلان',
        packagePayment:'دفع باقه',
        paymentTerms:'الشروط والاحكام الماليه',
        business:'المتاجر',
        archieves:'السجلات',
        searchHistory:'سجل البحث',
        views:'سجل الزيارات',
        bookingPaidCount:'عدد الحجوزات المدفوعه',
        paidDepositCount:'عدد العرابين المدفوعه',
        contactOn:'تم التواصل على ',
        reciever:'المرسل اليه',
        sender:'المرسل',
        adveristmentRequest:'طلب عقارى',
        adveristment:'اعلان',
        theAdveristment:"الاعلان",
        contactRequests:'طلبات التواصل',
        closed:'تم الاغلاق',
        withdrawal:'انسحاب',
        region:'المنطقه',
        officeNumbers:'عدد المكاتب',
        adsNumbers:'عدد الاعلانات',
        adsFilterSearch:'بحث بواسطه العنوان - الرقم',
        regaKey:'رمز تعريف الهيئه',
        other:'غير ذلك',
        faild:'فشلت',
        success:'نجحت',
        transactionNum:"رقم التحويل",
        buyPackage:"شراء باقه",
        building:'المبانى',
        availableProperty:'الاملاك المتاحه',
        topOfficesRate:'اعلى المكاتب تقييم',
        units:'الوحدات',
        floorNumber:'الدور رقم',
        lands:'الاراضى',
        permission:'الصلاحيه',
        onProgress:'تحت الاجراء',
        dashboard:'الصفحه الرئيسيه',
        notCompletedRequest:'طلبات غير مكتمله',
        hasPropertyPackage:'لديه باقه اداره املاك',
        hasAdsPackage:'لديه باقه اعلانات',
        addPermission:'اضافه صلاحيه',
        permissionInfo:'بيانات الصلاحيه',
        commonds:'الاوامر',
        permissions:'الصلاحيات',
        signUpProgcess:'لم يكمل التسجيل',
        reactionType:'نوع التفاعل',
        reactions:'التفاعلات',
        officeCount:'عدد المكاتب',
        clientCount:'عدد العملاء',
        notApproved:'تحت المراجعه',
        correct:'صحيح',
        requiredInput:'الحقل مطلوب',
        filterSearch:'بحث بواسطه الاسم - رقم العميل',
        new:'جديد',
        noProgress:'جارى التنفيذ',
        createdAt:'تاريخ الاضافه',
        img:'صوره',
        subAdmin:'مشرف',
        onProgressRequest:'طلبات تحت الاجراء',
        failedRequest:'طلبات ملغاه',
        canceledRequest:'طلبات ملغاه',
        completedRequest:'طلبات مكتمله',
        more:'.. المزيد',
        uploadFile:'رفع ملف',
        loading:'تحميل ..',
        priceTo:"اعلى سعر",
        priceFrom:"اقل سعر",
        sizeTo:"اكبر مساحه",
        sizeFrom:"اقل مساحه",
        notExist:'لا يوجد',
        subFeatures:'المميزات الفرعيه',
        mainFeatures:'المميزات الرئيسيه',
        commissioner:'مفوض',
        enablePhoneContact:"اتاحه رقم الهاتف",
        contraindicationsDesc: "تفاصيل القيود ",
        undocumentedRightsDesc: "الحقوق الغير موثقه",
        influentialInformationDesc: "التفاصيل المؤثره ",
        guaranteesDesc: "الضمانات",
        commissionNumber: "رقم التفويض",
        contraindications: "هل يوجد قيود على الاعلان؟",
        undocumentedRights:"هل يوجد حقوق غير مؤثقه؟",
        influentialInformation:"هل يوجد مايؤثر على الاعلان؟",
        saudiBuildingCode: "متوافق مع كود البناء السعودى",
        guarantees: "هل يوجد ضمانات؟",
        advertiserClass: "صفه المعلن",
        deposit:"العربون",
        disputes:"هل يوجد نزاعات قائمه؟",
        disputesDesc:"تفاصيل النزاعات",
        enableInstallment:'متاح للتقسيط',
        arabicAddress:'العنوان باللغه العربيه',
        englishAddress:'العنوان باللغه الانجليزيه',
        englishBestFeatures:'افضل المميزات باللغه الانجليزيه',
        arabicBestFeatures:'افضل المميزات باللغه العربيه',
        freeWork:'حاصل على وثيقه عمل حر',
        agencyType:'جهة المعلن',
        workSubCategory:'اقسام العمل الفرعيه',
        workCategory:'اقسام العمل',
        workArea:'مناطق العمل',
        workCity:'مدن العمل',
        sale:'بيع',
        rent:'ايجار',
        notAvailable:'غير متاح',
        contractType:'نوع التعاقد',
        notApprovedOfficesCount:'مكاتب فى انتظار التنشيط',
        approvedOfficesCount:'المكاتب النشطه',
        adsRequests:'الطلبات العقاريه',
        isVerified:'مؤكد',
        contactRequestCount:'عدد طلبات التواصل',
        adsRequestCount:'عدد الطلبات العقاريه',
        adsCount:'عدد الاعلانات',
        blocked:'محظور',
        office:'مكتب',
        unBlock:'ازاله الحظر',
        disApproved:'الرفض',
        approved:'الموافقه',
        Offices:'المكاتب',
        notActive:'غير مفعل',
        accountType:'حاله الحساب',
        offices:'المكاتب',
        AddPackage:'اضافه باقه',
        PackageInfo:'بيانات الباقه',
        monthly:'شهريا',
        yearly:'سنويا',
        durationType:'نوع المده',
        beDefault:' تعيين كافتراضى',
        plan:'مخطط',
        supervisors:'مشرفين',
        availableAds:'الاعلانات المتاحه',
        propertyManagement:'اداره املاك',
        adsManagment:'اداره اعلانات',
        archivedAds:'ارشفه الاعلانات بعد؟',
        adsRememberDays:'تذكير المستخدم بالاعلان بعد؟',
        Packages:'الباقات',
        Features:'المميزات',
        Transactions:'التحويلات الماليه',
        addRegion:'اضافه منطقه',
        regions:'المناطق',
        regionInfo:'تفاصيل المنطقه',
        AddFeature:'اضافه ميزه',
        FeatureInfo:'تفاصيل الميزه',
        englishOption:'الاختيار بالانجليزيه',
        arabicOption:'الاختيار بالعربيه',
        number:'رقميه',
        string:'نصيه',
        boolean:'اختر',
        list:'قائمه',
        privacy_ar:'سياسه التطبيق باللغه العربيه',
        privacy_en:'سياسه التطبيق باللغه الانجليزيه',
        terms_en:'الشروط والاحكام باللغه الانجليزيه',
        terms_ar:'الشروط والاحكام باللغه العربيه',
        shareTitle_en:'عنوان المشاركه بالانجليزيه',
        shareTitle_ar:'عنوان المشاركه بالعربيه',
        shareDescription_en:'تفاصيل المشاركه بالانجليزيه',
        shareDescription_ar:'تفاصيل المشاركه بالعربيه',
        facebook:'فيسبوك',
        twitter:'تويتر',
        snapChat:'سناب شات',
        youtube:'يوتيوب',
        instagram:'انستجرام',
        androidAppLink:'رابط تطبيق الاندرويد',
        iosAppLink:'رابط تطبيق الايفون',
        maxCities:'عدد المدن المسموحه',
        searchKm:'مساحه البحث (كم)',
        androidAppVersion:'اصدار الاندرويد',
        iosAppVersion:'اصدار الايفون',
        adsCost:'تكلفه الاعلان',
        taxRatio:'نسبه الضريبه',
        expireAfter:'الانتهاء بعد',
        linkType:'نوع الرابط',
        inside:'داخلى',
        outside:'خارجى',
        durationSec:'المده (ثانيه)',
        theViewers:'المشاهدين',
        notes:'الملحوظات',
        notFree:'غير مجانى',
        free:'مجانى',
        shipping:'الشحن',
        service:'الخدمه',
        personsCount:'عدد الافراد',
        completed:'مكتمل',
        bookingInfo:'تفاصيل الحجز',
        bookings:'الحجوزات',
        reject:'رفض',
        lives:'البث',
        liveInfo:'تفاصيل البث',
        endTime:'وقت النهايه',
        startTime:'وقت البدايه',
        rejected:'مرفوض',
        ended:'انتهى',
        discountType:' نوع الخصم',
        fixed:'مبلغ ثابت',
        expireDate:' تاريخ الانتهاء',
        saleCount:'عدد المبيعات',
        artistsCount:'عدد خبراء التجميل',
        adminCount:'عدد الادمنز',
        anoncementCount:'عدد الاخبار',
        productsCount:'عدد المنتجات',
        pendingOrdersCount:'الطلبات المنتظره',
        topUserRate:' اعلى مستخدم تقييما',
        topProductSale:'الاكثر مبيعا',
        topProductRate:'اعلى منتج تقييما',
        topProductsRate:'المنتجات الاعلى تقييم',
        artist:'خبير تجميل',
        verifyRequests:'طلبات التأكيد',
        freeShipping:'توصيل مجانى',
        reason:'السبب',
        areas:'الاحياء',
        accept:'قبول',
        refuse:'رفض',
        coupon:'كود الخصم',
        apartment:'الشقه',
        floor:'الطابق',
        placeType:'نوع المكان',
        street:'الشارع',
        pending:'بالانتظار',
        accepted:' تم الموافقه',
        refused:'مرفوض',
        deliverd:'تم التوصيل',
        status:'الحاله',
        orderInfo:'تفاصيل الطلب',
        store:'المتجر',
        refusedOrders:'طلبات مرفوضه',
        deliverdOrders:'طلبات تم توصيلها',
        acceptedOrders:' جارى تسليمها',
        PendingOrders:'طلبات معلقه',
        hasPromoCode:'لديه كوبون',
        paymentSystem:'نوع الدفع',
        finalTotal:'التكلفه النهائيه',
        total:"التكلفه",
        online:'اون لاين',
        Search:'بــحــث',
        Orders:'الطلبات',
        offerType:'نوع العرض',
        size:'الحجم',
        color:'اللون',
        brand:'العلامه التجاريه',
        englishAnswer:'الاجابه باللغه الانجليزيه',
        arabicAnswer:'الاجابه باللغه العربيه',
        englishQuestion:'السؤال بالانجليزيه',
        arabicQuestion:'السؤال بالعربيه',
        colors:'الالوان',
        Artists:'خبراء التجميل',
        anoncements:'الاعلانات المصوره',
        addAnoncement:'اضافه اعلان',
        anoncementInfo:'تفاصيل الاعلان',
        colorInfo:'بيانات اللون',
        addColor:'اضافه لون',
        sizes:'الاحجام',
        coupons:'الكوبونات',
        brands:'العلامات التجاريه',
        sizeInfo:'بيانات الحجم',
        brandInfo:'بيانات العلامه التجاريه',
        couponInfo:'بيانات الكوبون',
        theReview:'التعليق',
        AddCoupon:'اضافه كوبون',
        AddSize:'اضافه حجم',
        AddBrand:'اضافه علامه تجاريه',
        couponNumber:'رقم الكوبون',
        services:'خدمات',
        bookingCode:'كود الحجز',
        Booking:'الحجوزات',
        offer:'العرض',
        info:'البيانات',
        favCount:'عدد التفضيلات',
        favActivity:'النشاط المفضل',
        topUser:'اكثر العملاء حجزا',
        rate:'التقييم',
        topActivityRate:'اعلى الانشطه تقييما',
        problemCount:'عدد البلاغات',
        offerCount:'عدد العروض',
        bookingCount:'عدد الحجوزات',
        activityCount:'عدد النشاطات',
        usersCount:'عدد المستخدميين',
        topActivity:'افضل النشاطات',
        longitude:'خط الطول',
        latitude:'خط العرض',
        addActivity:'اضافه نشاط',
        gallery:'الصور',
        logo:'لوجو',
        background:'بالك جراوند',
        endDate:'تاريخ النهايه',
        end:'انتهى',
        addOffer:'اضافه عرض',
        offerInfo:'تفاصيل العرض',
        offers:'العروض',
        showOn:'مكان العرض',
        webSite:'الموقع الالكترونى',
        whatsApp:'واتساب',
        workTime_ar:'اوقات العمل باللغه العربيه',
        workTime_en:'اوقات العمل بالانجليزيه',
        admins:'الادمنز',
        activities:'الانشطه',
        hint:'تفاصيل',
        addCountry:'اضافه بلد',
        updateCountry:'تعديل البلد',
        countryInfo:'تفاصيل البلد',
        Countries:'البلاد',
        CountriesTable:'جدول البلاد',
        isoCode:'كود ايزو',
        countryCode:'كود الدوله',
        numbersCount:'عدد الارقام',
        notifs:'الاشعارات',
        setting:'الاعدادات',
        Terms:'الشروط والاحكام',
        Cities:'المدن',
        AddUser:'اضافه مستخدم',
        usersTable:'جدول المستخدميين',
        adminsTable:'جدول الادمنز',
        pointCost:'تكلفه النقطه',
        winPoints:'نقاط الربح',
        dayCost:'تكلفه اليوم',
        theActivity:'النشاط',
        AddTerms:'اضافه الشروط والاحكام',
        aboutUs_en:'من نحن باللغه الانجليزيه',
        aboutUs_ar:'من نحن باللغه العربيه',
        country:'البلد',
        addViewer:'اضافه مشاهد',
        logout:'الخروج',
        Profile:'الملف الشخصى',
        upload:'رفع الصوره',
        viewers:'المسموح لهم بالمشاهده',
        addViewers:'اضافه مشاهدين',
        removeDeviceData:'مسح بيانات الجهاز',
        activeCode:'كود التفعيل',
        openFile:'عرض الملف',
        buyCount:'عدد مرات الشراء',
        SearchAbout:' بحث عن اعلانات - مستخدمين',
        topTeacher:'انشط المعلمين',
        topActiveTeacher:'الاكثر نشاطا',
        topLectureBuy:'الاكثر مبيعا',
        topLecture:'الاكثر مبيعا',
        topStudent:'اعلى الطلاب رصيد',
        subjectsCount:'عدد المواد',
        studentsCount:'عدد الطلاب',
        teachersCount:'عدد المعلمين',
        questionsCount:'عدد الاسأله',
        lecturesCount:'عدد المحاضرات',
        messagesCount:'عدد الرسائل',
        addTeacher:'اضافه معلم',
        balance:'الرصيد',
        addMoney:'اضافه مبلغ',
        ScheduleInfo:'بيانات الجدول',
        selectDate:'اختر التاريخ',
        selectTime:'اختر الوقت',
        time:'الوقت',
        Schedule:'الجدول',
        AddSchedule:'اضافه جدول محاضره',
        Schedules:'جداول المحاضرات',
        arabicDescription:'الوصف بالعربيه',
        englishDescription:'الوصف بالانجليزيه',
        arabicTitle:'العنوان بالعربي',
        englishTitle:'العنوان بالانجليزيه',
        videos:'مقاطع الفيديو',
        files:'الملفات',
        lectureNumber:'رقم المحاضره',
        softCopy:'Pdf نسخه ',
        hardCopy:'نسخه مطبوعه',
        LecturesTable:'جدول المحاضرات',
        Lectures:'المحاضرات',
        Lecture:'المحاضره',
        LectureInfo:'بيانات المحاضره',
        AddLecture:'اضافه محاضره',
        Communities:'المنشوارات',
        CommunityTable:'جدول المنشورات',
        AddCommunity:'اضافه منشور',
        CommunityInfo:'بيانات المنشور',
        owner:'المالك',
        EventsAndNews:'اخبار واحداث',
        users:'المستخدمين',
        teachers:'المعلمين',
        TeachersTable:'جدول المعلمين',
        students:'الطلاب',
        studentsTable:'جدول الطلاب',
        admin:'أدمن',
        firstname:'الاسم الاول',
        lastname:'الاسم الاخير',
        answer:'الاجابه',
        QuestionInfo:'بيانات السؤال',
        AddQuestion:'اضافه سؤال',
        questions:'الاسأله',
        question:'السؤال',
        student:'الطالب',
        teacher:'المعلم',
        isAnswer:'تمت الاجابه؟',
        Subjects:'المواد الدراسيه',
        Subject:'الماده الدراسيه',
        AddSubject:'اضافه ماده دراسيه',
        SubjectInfo:'بيانات الماده الدراسيه',
        SubjectsTable:'جدول المواد الدراسيه',
        AddDepartment:'اضافه قسم',
        DepartmentInfo:'بيانات القسم',
        Departments:'الاقسام',
        DepartmentsTable:'جدول الاقسام',
        Divisions:'الشعب',
        faculty:'الكليه',
        year:'السنه ',
        department:'القسم',
        division:'الشعبه',
        DivisionsTable:'جدول الشعب',
        AddDivision:'اضافه شعبه',
        DivisionInfo:' بيانات الشعبه', 
        contactUs:'تواصل معنا',
        FaculitesTable:'جدول الكليات',
        FaculityInfo:'بيانات الكليه',
        addFaculty:'اضافه كليه',
        yearsNum:'عدد السنوات',
        hasDivision:'لديها شعب',
        YearInfo:'بيانات السنه',
        YearsTable:'جدول السنه',
        AddYear:'اضافه سنه',
        years:'السنوات',
        faculites:'الكليات',
        loadMore:'المزيد',
        eventDate:'تاريخ الحدث',
        error:'حدث خطأ',
        EventsTable:'جدول الاحداث',
        EventInfo:'بيانات الحدث',
        addEvent:'اضافه حدث',
        Events:'الاحداث',
        update:'تعديل',
        news:'الاخبار',
        addNews:'اضافه خبر',
        NewsInfo:'بيانات الخبر',
        NewsTable:'جدول الاخبار',
        about:'من نحن',
        userType:'نوع المستخدم',
        SendNotif:'ارسال اشعار',
        reply:'الرد',
        ProblemInfo:'بيانات المشكله',
        isReply:'تم الرد',
        problems:'المشاكل',
        reports:'البلاغات',
        problemType:' نوع المشكله',
        problem:'المشكله',
        reNew:'اعاده تعيين',
        billPassword:"باسورد الفواتير",
        facebookLink:"صفحه الفيسبوك",
        updateRule:'تعديل البند',
        RuleInfo:'بيانات بند الخصم',
        rules:'بنود الخصم',
        addDiscountRule:'اضافه بند خصم',
        canceled:'ملغاه',
        BillInfo:'بيانات الفاتوره',
        rule:'بند الخصم',
        moga:'موجا', 
        Clients:'العملاء',
        home:'الصفحه الرئيسيه',
        bills:'الفواتير',
        Stores:'الاماكن',
        NoRule:'لايوجد بند خصم',
        endPrice:'السعر النهائى',
        casher:'كاشير',
        branchName_en:'اسم الفرع بالانجليزيه',
        self:'ذاتى',
        salesCodeType:"نوع كود البيع",
        chooseStoreType:'اختر نوع الفرع',
        chooseMainStore:'اختر الفرع الرئيسى',
        mainStore:'الفرع الرئيسى',
        storeType:'نوع الفرع',
        branch:'فرع',
        main:'رئيسى',
        branchName:' اسم الفرع بالعربيه',
        branches:'الفروع',
        addCity:'اضافه مدينه',
        addArea:'اضافه حى',
        childUsers:'المدعوون',
        storePhone:' هاتف المحل',
        storeName_ar:'اسم المحل بالعربيه',
        addStoreUser:'اضافه مستخدم للمحل',
        priority:'الاولويه',
        storeUsers:' مستخدمين المحل ',
        addSubCategory:'اضافه قسم فرعى',
        AddCategory:'اضافه قسم',
        updateStore:'تعديل المحل',
        storeProfile:'بيانات المحل',
        chooseArea:'اختر الحى',
        secondPackage:'الباقه الثانيه',
        firstPackage:'الباقه الاولى',
        package:'الباقه ',
        discount:'الخصم',
        chooseSubCategory:'اختر القسم الفرعى',
        salesCode:'كود البيع',
        workTime:'مواعيد العمل',
        storeAbout:'معلومات عن المحل',
        subCategory:'قسم فرعى',
        email:'الايميل',
        storeName:'اسم المحل',
        StoresTable:'جدول المحلات',

        monthNum:'ترتيب القسط',
        paid:'المدفوع',
        onlyLastMonth:'اخر شهر فقط',
        chooseSalesMan:'اختر المندوب',
        chooseReceiptType:'اختر شكل الايصال',
        addCost:'اضافه مبلغ',
        cost:'المبلغ',
        give:'تسليم',
        receive:'استلام',
        theNaming:'المسمى',
        addTransaction:'اضافه معامله ماليه',
        addSupplier:'اضافه مورد',
        from:'مـن',
        to:'الـى',
        date:'التاريخ',
        money:'المبلغ',
        recieveReceivables:'المبالغ المستلمه',
        Dues:'المستحقات',
        pendingPremiums:'التحصيلات المتبقيه',
        finishedPremiums:'التحصيلات المكتمله',
        additionCollect:'التحصيل الاضافى',
        allPremiums:'التحصيل الكلى',
        daily:'اجمالى اليوم',
        receivedProducts:'المنتجات المستلمه',
        changeSalesMan:'تغيير المندوب',
        receiveMoney:'استلام مبلغ',
        TransferCollections:'نقل التحصيلات',
        Receivables:'المستحقات',
        collectedClients:'عملاء التحصيل',
        lastSeen:'اخر تواجد',
        addSalesMan:'اضافه مندوب',
        theAdd:'الاعلان',
        title:'العنوان',
        viewsCount:'عدد المشاهدات',
        ordersCount:'عدد المبيعات',
        statistic:'احصائيات',
        overView:'نظره عامه',
        dalyViews:'المشاهدات اليوميه',
        duration:'المده ',
        allViews:'المشاهدات الكليه',
        showAll:'عرض الكل',
        ads:'الاعلانات العقاريه',
        topProducts:'المنتجات الاكثر مبيعا',
        topSalesMan:'المندوب الاكثر مبيعات',
        topCompany:' الشركه الاكثر مبيعا',
        topProduct:'المنتج الاكثر مبيعا',
        newUsers:'المستخدمين الجدد',
        allUsersCount:'عدد المستخدمين الكلى',
        companiesInApp:'عدد الشركات فالتطبيق',
        TotalSalesOfAllCompanies:'اجمالى المبيعات لجميع الشركات',
        allSoldProducts:'عدد المنتجات المباعه',
        adsViewsCount:'اجمالى مشاهدات الاعلانات',
        product:'المنتج',
        chooseProduct:'اختر المنتج',
        count:'الكميه',
        cash:'كاش',
        payPremuims:'الاقساط المدفوعه',
        premuimCost:'قيمه القسط',
        premuimsCount:'عددد الاقساط',
        delayPremuims:'الاقساط المرحله',
        remainPremuims:'الاقساط المتبقيه',
        payCost:'المبلغ المدفوع',
        firstPay:'المقدم',
        remainCost:'المبلغ المتبقى',
        buySalesMan:'مندوب البيع',
        collectSalesMan:'مندوب التحصيل',
        DliveryNewProduct:'تسليم سلعه جديده',
        recievedProducts:'المنتجات المستلمه',
        showLocation:'اظهار الموقع',
        sendMessage:'رساله بالتطبيق',
        printReceitp:'طباعه ايصال',
        sendNotif:'ارسال اشعار',
        delayPremuim:'ترحيل قسط',
        payCash:'دفع كاش',
        collectPremuims:'تحصيل اقساط',
        enterPassword:'ادخل الرقم السرى',
        guarantor:'الضامن',
        client:'العميل',
        receipts:'ايصالات الامانه',
        cardImg:'صوره البطاقه',
        chooseCity:'اختر المحافظه',
        city:'المحافظه',
        note:'الملاحظات',
        anotherPhone:'رقم موبايل اخر',
        EnterPhone:'ادخل رقم الموبايل',
        EnterAnotherPhone:'ادخل رقم موبايل اخر',
        enterNote:'ادخل الملاحظات',
        enterAddress:'ادخل العنوان',
        enterCardNum:'ادخل رقم البطاقه',
        cardNum:'رقم البطاقه',
        profileImg:'صوره البروفايل',
        addClient:'اضافه عميل',
        enterPhone:'ادخل رقم الهاتف (اختيارى)  ',
        enterLink:' ادخل الرابط (اختيارى) ',
        link:'الرابط',
        visable:'عرض',
        chooseDate:'اختر التاريخ',
        products:'المنتجات',
        categories:'الاقسام',
        enterName:'ادخل الاسم',
        enterDuration:'ادخل المده',
        chooseCategory:' اختر القسم',
        chooseCompanys:'(اختيارى) اختر الشركات',
        chooseProducts:'(اختيارى) اختر المنتجات',
        optional:'اختيارى',
        startDate:'تاريخ البدايه',
        companies:'الشركات',
        imgOrVideo:'صوره/فيديو',
        show:'عرض',
        notShow:'غير معروض',
        day:'يوم ',
        addAds:'اضافه اعلان عقارى',
        theMonths:'الشهور',
        Properties:'خصائص المنتج',
        installmentPackages:'باقات التقسيط',
        firstPaid:'المقدم',
        months:'الشهور',
        installment:'قسط',
        theInstallment:'القسط',
        pound:'جنيه',
        available:'متاح ',
        onlyInstallment:'تقسيط فقط',
        ratio:'نسبه',
        property:'ميزه',
        description:'وصف',
        properties:'',
        cashPrice:'سعر الكاش',
        imgsGroup:'مجموعه صور',
        writeArabicName:'ادخل الاسم بالعربيه',
        writeEnglishName:'ادخل الاسم بالانجليزيه',
        quantity:'الكميه',
        EnterQuantity:'ادخل الكميه',
        purchasePrice:'سعر الشراء',
        EnterPrice:'ادخل المبلغ',
        mainImg:'الصوره الرئيسيه',
        arabicProductName:'اسم المنتج بالعربيه',
        englishProductName:'اسم المنتج بالانجليزيه', 
        print:'طباعه',
        all:'الكل',
        idFilter:' ( فلتر ( الكود ',
        nameFilter:'( فلتر ( الاسم',
        cardFilter:'( فلتر ( البطاقه',
        salesMan:'المندوب',
        salesManFilter:'فلتر (المندوب )',
        addProduct:'اضافه منتج',
        edit:'تعديل',
        add:'اضافه',
        dropHere:'قم بالسحب واسقط هنا',
        arabicCategoryName:'اسم القسم باللغه العربيه',
        englishCategoryName:'اسم القسم باللغه الانجليزيه',
        categoryImg:'صوره القسم',
        chooseFile:'اختر الملف',
        completeData:'املأ البيانات المطلوبه',
        noData:'لا يوجد بيانات',
        areYouSure:"هل انت متأكد؟",
        ok:'موافق',
        cancel:'غير موافق',
        addCategory:'اضافه قسم',
        enterCorrectPhone:'رقم هاتف غير صحيح',
        enterCorrectEmail:'بريد الكترونى غير صحيح',
        correctCode:'رمز خاطئ',
        passwordMustEqualConfirmPassword:'يجب ان يكون الرقم السرى يشابه الرقم المؤكد',
        done:'تم',
        newPassword:'الباسورد الجديد',
        newTime:'مره اخرى',
        code:'الرمز',
        enter:'ادخال',
        sendCode:'ارسال الرمز',
        forgetPassword:'نسيت كلمه السر؟',
        username:"اسم المستخدم",
        areaInfo:'معلومات الحى',
        updateArea:'تعديل الحى',
        updateCity:'تعديل المدينة',
        cityInfo:'معلومات المدينة',
        citiesTable:'المدن',
        delivaryCost:'سعر التوصيل',
        cities:'المدن',
        categoryInfo:'معلومات القسم',
        updateCategory:'تعديل القسم',
        categoriesTable:'الاقسام',
        arabicName:'الاسم بالعربية',
        englishName:'الاسم بالانجليزيه',
        normal:'طبيعى',
        disActive:'ايقاف التنشيط',
        removeOffer:'حذف العرض',
        userProfile:'بيانات المستخدم',
        sallCount:'الخصم',
        offerPrice:'السعر بعد الخصم',
        offerRatio:'نسبة الخصم',
        top:'توب',
        category:'القسم',
        name:'الاسم',
        company:'الشركة',
        price:'السعر',
        hasOffer:'لدية عرض ',
        productsTable:'المنتجات',
        remove:'حذف',
        activeButton:'تنشيط',
        disActiveButton:'ايقاف التنشيط',
        blockButton:'الحظر',
        unblockButton:'فك الحظر',
        area:'الحى',
        updateProfile:'تعديل الملف الشخصى',
        address:'العنوان',
        signUpFrom:'التسجيل من خلال',
        active:'نشط',
        type:'النوع',
        thisUserIsnotAdmin:'هذا المستخدم ليس مسئولا',
        userDataIncorrect:'بيانات المستخدم غير صحيحة',
        firstName:'الاسم الاول',
        lastName:'باقى الاسم',
        phone:'رقم الموبايل',
        block:'الحظر',
        action:'الاجرائات',
        yes:'نعم',
        no:'لا',
        areYouSureDelete:'هل انت متاكد من الحذف ؟',
        addNewRecored:'اضافة عنصر جديد',
        first:'البداية',
        last:'الاخير',
        next:'التالى',
        prev:'السابق',
        login:"الدخول",
        signin:'تسجيل الدخول',
        password:'كلمة المرور',
    },
       
});
