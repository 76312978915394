import React from 'react';
import './business info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon,MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { message } from "antd";
import moment from "moment";
class BusinessInfo extends React.Component {
    state = {
        actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
        id:this.props.location.state.data.id,
        status:this.props.location.state.data.status,
        brand_name_en:this.props.location.state.data.brand_name_en?this.props.location.state.data.brand_name_en:"Saaie Office",
        brand_name_ar:this.props.location.state.data.brand_name_ar?this.props.location.state.data.brand_name_ar:"مكتب ساعى",
        owner_firstname:this.props.location.state.data.owner_firstname?this.props.location.state.data.owner_firstname:'',
        owner_lastName:this.props.location.state.data.owner_lastName?this.props.location.state.data.owner_lastName:'',
        owner_email:this.props.location.state.data.owner_email?this.props.location.state.data.owner_email:'',
        owner_phone:this.props.location.state.data.owner_phone?this.props.location.state.data.owner_phone:'',
        identification_type:this.props.location.state.data.identification_type?this.props.location.state.data.identification_type:null,
        identification_issuing_date:this.props.location.state.data.identification_issuing_date?this.props.location.state.data.identification_issuing_date:null,
        identification_expiry_date:this.props.location.state.data.identification_expiry_date?this.props.location.state.data.identification_expiry_date:null,
        reason:this.props.location.state.data.reason?this.props.location.state.data.reason:'',
        entity_number:this.props.location.state.data.entity_number?this.props.location.state.data.entity_number:'',
        entity_ssuing_date:this.props.location.state.data.entity_ssuing_date?this.props.location.state.data.entity_ssuing_date:null,
        entity_expiry_date:this.props.location.state.data.entity_expiry_date?this.props.location.state.data.entity_expiry_date:null,
        settlement_by:this.props.location.state.data.settlement_by?this.props.location.state.data.settlement_by:'',
        bank_iban:this.props.location.state.data.bank_iban?this.props.location.state.data.bank_iban:'',
        brand_sector:this.props.location.state.data.brand_sector?this.props.location.state.data.brand_sector:'',
        office:this.props.location.state.data.office?this.props.location.state.data.office:null,
        entity_imgs:this.props.location.state.data.entity_imgs?this.props.location.state.data.entity_imgs:[],
        identification_imgs:this.props.location.state.data.identification_imgs?this.props.location.state.data.identification_imgs:[],
        modal:false,
    };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    
    delete = () => {
        let l = message.loading('Wait..', 2.5)
        axios.delete(`${BASE_END_POINT}marketPlace/${this.props.location.state.data.id}`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization':`Bearer ${this.props.currentUser.token}`,
           'Accept-Language':this.props.isRTL?'ar':'en'
         },
        })
        .then(response=>{
          console.log("Transaction DELETED")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
   

    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.updateTransaction()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    approved = (approved) => {
        let uri ='';
        let data={}
        if(approved){
         uri = `${BASE_END_POINT}${this.state.id}/approved`
        }else{
          data.reason = this.state.notApprovedReason
         uri = `${BASE_END_POINT}${this.state.id}/dis-approved`
        }
       let l = message
        .loading('Wait..', 2.5)
        axios.put(uri,data,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization': `Bearer ${this.props.currentUser.token}`,
           'Accept-Language':this.props.isRTL?'ar':'en'
         },
       })
        .then(response=>{
            l.then(() => message.success(allStrings.done, 2.5))
            this.props.history.goBack()
        })
        .catch(error=>{
         console.log('Error   ',error.response)
         l.then(() => message.error(allStrings.error, 2.5))
        })
    }
    accept = () => {
        let l = message.loading('Wait..', 2.5)
        axios.put(`${BASE_END_POINT}marketPlace/${this.state.id}/accepted`,{},{
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
        })
        .then(response=>{
            l.then(() => message.success(allStrings.done, 2.5))
            this.props.history.goBack()
        })
        .catch(error=>{
         console.log('Error   ',error.response)
         l.then(() => message.error(allStrings.error, 2.5))
        })
    } 
    sendToTap = () => {
        let l = message.loading('Wait..', 2.5)
        let data = {
            "name": {
                "en": this.state.brand_name_en,
                "ar": this.state.brand_name_ar
            },
            "type": "corp",
            "entity": {
                "legal_name": {
                "en": this.state.brand_name_en,
                "ar": this.state.brand_name_ar
                },
                "is_licensed": true,
                "license_number": this.state.entity_number,
                "not_for_profit": false,
                "country": "SA",
                "settlement_by": "Acquirer",
                "documents": [
                {
                    "type": "Commercial Registration",
                    "number": this.state.entity_number,
                    "issuing_country": "SA",
                    "issuing_date": this.state.entity_ssuing_date,
                    "expiry_date": this.state.entity_expiry_date,
                    "images": []
                }
                ],
                "bank_account": {
                "iban": this.state.bank_iban
                }
            },
            "contact_person": {
                "name": {
                "title": "Mr",
                "first": this.state.owner_firstname,
                "last": this.state.owner_lastName
                },
                "contact_info": {
                "primary": {
                    "email": this.state.owner_email,
                    "phone": {
                    "country_code": "965",
                    "number": this.state.owner_phone
                    }
                }
                },
                "is_authorized": true,
                "identification": [
                {
                    "type": "Identity Card",
                    "issuing_country": "SA",
                    "issuing_date": this.state.identification_issuing_date,
                    "expiry_date": this.state.identification_expiry_date,
                    "images": []
                }
                ]
            },
            "brands": [
                {
                "name": {
                    "en": this.state.brand_name_en,
                    "ar": this.state.brand_name_ar
                },
                "sector": this.state.brand_sector
                
                }
            ],
            "post": {
                "url": "https://api.saaei.co/api/v1/marketPlace/tapCallBack"
            },
            "metadata": {
                "mtd": `${this.state.id}`
            }
        }
        console.log(data)
        axios.post(`${BASE_END_POINT}marketPlace/${this.state.id}/sendToTap`,{data:data},{
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.props.currentUser.token}`,
            },
        })
        .then(response=>{
            l.then(() => message.success(allStrings.done, 2.5))
            this.props.history.goBack()
        })
        .catch(error=>{
         console.log('Error   ',error.response)
         l.then(() => message.error(allStrings.error, 2.5))
        })
    } 
    refuse = () => {
        let l = message.loading('Wait..', 2.5)
        axios.put(`${BASE_END_POINT}marketPlace/${this.state.id}/refused`,{},{
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
        })
        .then(response=>{
            l.then(() => message.success(allStrings.done, 2.5))
            this.props.history.goBack()
        })
        .catch(error=>{
         console.log('Error   ',error.response)
         l.then(() => message.error(allStrings.error, 2.5))
        })
    } 
    needToFix = () => {
        let data={reason:this.state.reason}
        let l = message.loading('Wait..', 2.5)
        axios.put(`${BASE_END_POINT}marketPlace/${this.state.id}/needToFixed`,data,{
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
        })
        .then(response=>{
            l.then(() => message.success(allStrings.done, 2.5))
            this.setState({reason:this.state.reason})
            this.toggle()
            this.props.history.goBack()
        })
        .catch(error=>{
         console.log('Error   ',error.response)
         l.then(() => message.error(allStrings.error, 2.5))
        })
    }
    submitHandlerNeedToFix = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";
        this.needToFix()
    };
    changeHandlerNeedToFix = (event) => {
        console.log(event.target.value);
        this.setState({ [event.target.name]: event.target.value });
    };
    toggle = () => {
        console.log(this.state.modal)
        this.setState({
          modal: !this.state.modal,
        });
    }
    modal = () =>(
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
        <MDBModalHeader toggle={this.toggle} >{allStrings.completeData}</MDBModalHeader>
        <MDBModalBody>
            <MDBContainer>
            <MDBRow>
                <MDBCol md="12">
                <form style={{paddingTop:'1rem'}}
                    className="needs-validation"
                    onSubmit={this.submitHandlerNeedToFix}
                    noValidate
                >
                    <div className="grey-text">
                    <MDBRow>
                        <MDBCol md="12">
                        <div className="md-form">
                            <label htmlFor="form92" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.reason}</label>
                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form92" className="form-control" onChange={this.changeHandlerNeedToFix} required validate  name="reason"/>
                            <div class="valid-feedback">{allStrings.correct}</div>
                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                        </div>
                        </MDBCol>
                    </MDBRow>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="text-center">
                    <MDBBtn type="submit" style={{ background: '#00B483' }} rounded className="btn-block z-depth-1a" >
                        <MDBIcon className="mr-2" />
                        {allStrings.needToFix}
                    </MDBBtn>
                    </div>
                </form>
                </MDBCol>
            </MDBRow>
            </MDBContainer>
        </MDBModalBody>
    </MDBModal>
    );
    
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('CONTACT-REQUEST')) return <Redirect to='/Home' />

  return (
    <div className="App">
        <MDBContainer> 
            {this.modal()}
            <MDBRow>  
                <MDBCol md="12" >
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#00B483'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>business</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.edit}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                    {this.state.actionType==="VIEW"&&
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form8" className="form-control" onChange={this.changeHandler}  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form602" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicBrandName}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.brand_name_ar} type="text" id="form602" name="brand_name_ar" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form502" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishBrandName}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.brand_name_en} type="text" id="form502" name="brand_name_en" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form601" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.firstname}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.owner_firstname} type="text" id="form601" name="owner_firstname" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form501" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.lastname}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.owner_lastName} type="text" id="form501" name="owner_lastName" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form610" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.email}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.owner_email} type="text" id="form610" name="owner_email" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form510" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.phone}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.owner_phone} type="text" id="form510" name="owner_phone" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form61" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.entityNumber}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.entity_number} type="text" id="form61" name="entity_number" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form51" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.entitySsuingDate}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={moment(this.state.entity_ssuing_date).format('lll')} type="text" id="form51" name="entity_ssuing_date" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form69" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.entityExpiryDate}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={moment(this.state.entity_expiry_date).format('lll')} type="text" id="form69" name="entity_expiry_date" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form59" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.identificationType}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.identification_type==="IDENTITY-CARD"?allStrings.identityCard:allStrings.passport} type="text" id="form59" name="identification_type" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form6" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.identificationIssuingDate}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={moment(this.state.identification_issuing_date).format('lll')} type="text" id="form6" name="identification_issuing_date" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form5" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.identificationExpiryDate}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={moment(this.state.identification_expiry_date).format('lll')} type="text" id="form5" name="identification_expiry_date" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form615" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.bankIban}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false}   style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.bank_iban} type="text" id="form6145" name="bank_iban" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form516" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.status}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.status==="PENDING"?allStrings.pending:this.state.status==="ACCEPTED"?allStrings.accepted:this.state.status==="REFUSED"?allStrings.refused:allStrings.fixingPhase} type="text" id="form516" name="status" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    {this.state.status ==="FIXING-PHASE"&&
                                    <MDBRow>
                                      <MDBCol md="12">
                                        <div className="md-form">
                                          <label htmlFor="form092" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.reason}</label>
                                          <input disabled value={this.state.reason}  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form092" className="form-control"  name="reason"/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                        </div>
                                      </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.office&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="10" xs="10">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.office}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.office?this.state.office.fullname:''} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="2" xs="2">
                                    
                                        <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                            <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.office}) }}>
                                            remove_red_eye
                                            </span>
                                            <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.office}) }}>
                                            {allStrings.show}
                                            </span> 
                                        </p>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    <MDBRow>
                                        <label for="" style={{color:'#333',padding:'15px',textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.identificationImgs}</label>
                                        <MDBCol md="12">
                                            <div style={{textAlign: 'center'}}>
                                                {this.state.identification_imgs.map(v=>(
                                                    <img  style={{
                                                        border: '1px solid #00B483',
                                                        borderRadius: '8px',
                                                        padding: '4px'
                                                    
                                                    }}width="150px" height="150px" src={v} alt="identification_imgs"></img>
                                                ))}
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <label for="" style={{color:'#333',padding:'15px',textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.entityImgs}</label>
                                        <MDBCol md="12">
                                            <div style={{textAlign: 'center'}}>
                                                {this.state.entity_imgs.map(v=>(
                                                    <img  style={{
                                                        border: '1px solid #00B483',
                                                        borderRadius: '8px',
                                                        padding: '4px'
                                                    
                                                    }}width="150px" height="150px" src={v} alt="entity_imgs"></img>
                                                ))}
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                    
                                    {this.state.actionType==="VIEW"&&
                                        <MDBRow>
                                            <MDBCol md="12">
                                                <MDBBtn onClick={()=>{this.delete()}} rounded color="danger" className="btn-block z-depth-1a">
                                                    <MDBIcon icon="trash-alt" className="mr-2" />
                                                    {allStrings.remove}
                                                </MDBBtn>
                                            </MDBCol>
                                            {/* <MDBCol md="12">
                                                <MDBBtn rounded color="success"
                                                    style={{ borderRadius: "2px" }} className="btn-block z-depth-1a"
                                                    onClick={()=>{this.accept(true)}}
                                                >
                                                    <MDBIcon icon="check-circle" className="mr-2" />
                                                    {allStrings.accept}
                                                </MDBBtn>
                                            </MDBCol> */}
                                            
                                            {this.state.status ==="PENDING"&&
                                            <MDBCol md="12">
                                                <MDBBtn rounded color="warning" style={{ borderRadius: "2px" }}
                                                    className="btn-block z-depth-1a"
                                                    onClick={this.toggle} 
                                                >
                                                    <MDBIcon icon="check-circle" className="mr-2" />
                                                    {allStrings.needToFix}
                                                </MDBBtn>
                                                <MDBBtn rounded color="success" style={{ borderRadius: "2px" }}
                                                    className="btn-block z-depth-1a"
                                                    onClick={()=>this.sendToTap()} 
                                                >
                                                    <MDBIcon icon="check-circle" className="mr-2" />
                                                    {allStrings.sendToTap}
                                                </MDBBtn>    
                                                <MDBCol md="12">
                                                    <MDBBtn rounded color="red" style={{ borderRadius: "2px" }}
                                                        className="btn-block z-depth-1a"
                                                        onClick={()=>{
                                                        this.refuse(false)
                                                        }}
                                                    >
                                                        <MDBIcon icon="ban" className="mr-2" />
                                                        {allStrings.refuse}
                                                    </MDBBtn>
                                                </MDBCol> 
                                            </MDBCol>
                                            }
                                        </MDBRow>
                                    }       
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol> 
            </MDBRow>
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(BusinessInfo)
  );