import React from 'react';
import './request info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon,MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { message,Spin} from "antd";
class requestInfo extends React.Component {
  state = {
      actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
      page:1,
      pages:1,
      id:this.props.location.state.data.id?this.props.location.state.data.id:null,
      status:this.props.location.state.data.status?this.props.location.state.data.status:"",
      category:this.props.location.state.data.category?this.props.location.state.data.category.categoryName:"",
      subCategory:this.props.location.state.data.subCategory?this.props.location.state.data.subCategory.categoryName:"",
      area:this.props.location.state.data.area?this.props.location.state.data.area.areaName:"",
      city:this.props.location.state.data.city?this.props.location.state.data.city.cityName:"",
      notes:this.props.location.state.data.notes?this.props.location.state.data.notes:"",
      user:this.props.location.state.data.user?this.props.location.state.data.user:[],
      actionUser:this.props.location.state.data.actionUser?this.props.location.state.data.actionUser:null,
      loading:false,
      requestType:'COMPLETED',
      modal:false
  };

  constructor(props){
    super(props)
    window.scrollTo(0,0)
    if(this.props.isRTL){
        allStrings.setLanguage('ar')
    }else{
        allStrings.setLanguage('en')
    } 
  }
  componentDidMount(){
      if(this.props.isRTL){
          allStrings.setLanguage('ar')
      }else{
          allStrings.setLanguage('en')
      } 
  }
  delete = () => {
      let l = message.loading('Wait..', 2.5)
      axios.delete(`${BASE_END_POINT}requests/${this.props.location.state.data.id}`,{
       headers: {
         'Content-Type': 'application/json',
         'Authorization':`Bearer ${this.props.currentUser.token}`,
         'Accept-Language':this.props.isRTL?'ar':'en'
       },
      })
      .then(response=>{
        console.log("ads DELETED")
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack() 
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.error, 2.5))
      })
  }
  completed = () => {
    let data={notes:this.state.notes}
    let l = message.loading('Wait..', 2.5)
    axios.put(`${BASE_END_POINT}requests/${this.state.id}/completed`,data,{
        headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
        },
    })
    .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5))
        this.props.history.goBack()
    })
    .catch(error=>{
        console.log('Error   ',error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
  }
  failed = () => {
    let data={notes:this.state.notes}
    let l = message.loading('Wait..', 2.5)
    axios.put(`${BASE_END_POINT}requests/${this.state.id}/failed`,data,{
        headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
        },
    })
    .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5))
        this.props.history.goBack()
    })
    .catch(error=>{
        console.log('Error   ',error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
  }
  onProgress = () => {
    let data={notes:this.state.notes}
    let l = message.loading('Wait..', 2.5)
    axios.put(`${BASE_END_POINT}requests/${this.state.id}/onProgress`,data,{
        headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
        },
    })
    .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5))
        this.props.history.goBack()
    })
    .catch(error=>{
        console.log('Error   ',error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
  }
  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    console.log(this.state.requestType)
    if(this.state.requestType == "COMPLETED"){
      this.completed()
    }else{
      this.failed()
    }
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  }
  modal = () =>(
  <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
      <MDBModalHeader toggle={this.toggle} >{allStrings.completeData}</MDBModalHeader>
      <MDBModalBody>
          <MDBContainer>
          <MDBRow>
              <MDBCol md="12">
              <form style={{paddingTop:'1rem'}}
                  className="needs-validation"
                  onSubmit={this.submitHandler}
                  noValidate
              >
                  <div className="grey-text">
                  <MDBRow>
                      <MDBCol md="12">
                      <div className="md-form">
                          <label htmlFor="form92" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.notes}</label>
                          <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form92" className="form-control" onChange={this.changeHandler} required validate  name="notes"/>
                          <div class="valid-feedback">{allStrings.correct}</div>
                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                      </div>
                      </MDBCol>
                  </MDBRow>
                  </div>
                  <br></br>
                  <br></br>
                  <div className="text-center">
                  <MDBBtn type="submit" style={{ background: '#00B483' }} rounded className="btn-block z-depth-1a" >
                      <MDBIcon className="mr-2" />
                      {allStrings.done}
                  </MDBBtn>
                  </div>
              </form>
              </MDBCol>
          </MDBRow>
          </MDBContainer>
      </MDBModalBody>
  </MDBModal>
  );
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('REQUESTS')) return <Redirect to='/Home' />
  return (
    <div className="App">
      <Spin spinning={this.state.loading} tip={allStrings.loading} size="large" style={{color:'#00B483'}}>
        <MDBContainer> 
        {this.modal()}
            <MDBRow>  
                <MDBCol xl={this.state.actionType==="VIEW"?"12":"12"} lg = {this.state.actionType==="VIEW"?"12":"12"} md="12" sm="12">

                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                          <CardMedia
                          style={{height:"0px"}}
                          />
                          <CardHeader></CardHeader>
                          <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                              <div className="topIcon" style={{background:'#00B483'}}>
                                  <span class="material-icons" style={{color:'white',fontSize:23}}>featured_video</span>
                              </div>
                              <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',marginLeft:'0',marginRight:'0'}}>
                              <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.edit}</p>
                              </div>
                          </div>
                          <CardContent style={{minHeight:"390px",outline:'none'}}>
                          <MDBContainer>
                              <MDBRow>
                                  <MDBCol md="12">
                                  <form
                                  className="needs-validation"
                                  //onSubmit={}
                                  noValidate
                                  >
                                    <div className="grey-text">
                                      {this.state.actionType==="VIEW"&&
                                      <MDBRow>
                                          <MDBCol md="6">
                                              <div class="md-form">
                                              <label htmlFor="form635" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.status}</label>
                                              <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.status=="NEW"?allStrings.new:this.state.status=="ON-PROGRESS"?allStrings.onProgress:this.state.status == "COMPLETED"?allStrings.completed:allStrings.failed} type="text" id="form635"  class="form-control" />
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                              <div class="md-form">
                                              <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                              <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form605"  class="form-control" />
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                      </MDBRow>
                                      }
                                      <MDBRow>
                                          <MDBCol md="6">
                                              <div class="md-form">
                                              <label htmlFor="form402" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.subCategory}</label>
                                              <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.subCategory}  type="text" id="form402" name="subCategory" class="form-control" required validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                              <div class="md-form">
                                              <label htmlFor="form409" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.category}</label>
                                              <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text"  value={this.state.category}  id="form409" name="category" class="form-control" required validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                      </MDBRow>
                                      <MDBRow>
                                          <MDBCol md="6">
                                              <div class="md-form">
                                              <label htmlFor="form402" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.area}</label>
                                              <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text"  value={this.state.area}  id="form409" name="area" class="form-control" required validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                              <div class="md-form">
                                              <label htmlFor="form409" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.city}</label>
                                              <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text"  value={this.state.city}  id="form409" name="city" class="form-control" required validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                      </MDBRow>
                                      {this.state.user&&
                                      <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                          <MDBCol md="5" xs="5">
                                              <div class="md-form">
                                              <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.owner}</label>
                                              <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.user?this.state.user.fullname:''} type="text" id="form80" class="form-control"  />
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                          <MDBCol md="5" xs="5">
                                              <div class="md-form">
                                              <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.phone}</label>
                                              <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.user?this.state.user.phone:''} type="text" id="form80" class="form-control"  />
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                          <MDBCol md="2" xs="2">
                                            <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                              <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                              onClick={()=>{this.props.history.push('userInfo',{data:this.state.user}) }}>
                                              remove_red_eye
                                              </span>
                                              <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                              onClick={()=>{this.props.history.push('userInfo',{data:this.state.user}) }}>
                                              {allStrings.show}
                                              </span> 
                                            </p>
                                          </MDBCol>
                                      </MDBRow>
                                      }
                                      {this.state.notes &&
                                      <MDBRow>
                                        <MDBCol md="12">
                                            <div class="md-form">
                                              <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.notes}</label>
                                              <br></br>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              <textarea disabled style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} value={this.state.notes} type="text" id="form645" name="notes" class="form-control" required  validate/>
                                            </div>
                                        </MDBCol>
                                      </MDBRow>
                                      }
                                      {this.state.actionUser &&
                                      <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                          <MDBCol md="10" xs="10">
                                              <div class="md-form">
                                              <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.actionUser}</label>
                                              <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.actionUser?this.state.actionUser.fullname:''} type="text" id="form80" class="form-control"  />
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                          <MDBCol md="2" xs="2">
                                            <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD',pointerEvents:this.state.actionUser ===null?'none':'auto'}}>
                                              <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                              onClick={()=>{this.props.history.push('userInfo',{data:this.state.actionUser}) }}>
                                                remove_red_eye
                                              </span>
                                              <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                                onClick={()=>{this.props.history.push('userInfo',{data:this.state.actionUser}) }}>
                                                {allStrings.show}
                                              </span> 
                                            </p>
                                          </MDBCol>
                                      </MDBRow>
                                      }
                                      </div>
                                      <br></br>
                                      <br></br>
                                      <div className="text-center">
                                        <MDBBtn
                                          color="red"
                                          rounded
                                          style={{ borderRadius: "2px",background:'red' }}
                                          className="btn-block z-depth-1a"
                                          onClick={()=>{
                                            this.delete()
                                          }}
                                          >
                                          <MDBIcon icon="trash-alt" className="mr-2" />{allStrings.remove}
                                        </MDBBtn>
                                        {this.state.status == "PENDING"&&
                                            <div>
                                              <MDBBtn rounded color="success" style={{ borderRadius: "2px" }}
                                                className="btn-block z-depth-1a"
                                                onClick={()=>{this.toggle();this.setState({requestType:'COMPLETED'})}}
                                              >
                                                    <MDBIcon icon="check-circle" className="mr-2" />
                                                    {allStrings.completed}
                                              </MDBBtn>  
                                              <MDBBtn rounded color="red" style={{ borderRadius: "2px" }}
                                                className="btn-block z-depth-1a"
                                                onClick={()=>{this.toggle();this.setState({requestType:'FAILED'})}}
                                              >
                                                  <MDBIcon icon="ban" className="mr-2" />
                                                  {allStrings.failed}
                                              </MDBBtn>
                                              <MDBBtn
                                                color="red"
                                                rounded
                                                style={{ borderRadius: "2px",background:'red' }}
                                                className="btn-block z-depth-1a"
                                                onClick={()=>{
                                                  this.onProgress()
                                                }}
                                                >
                                                <MDBIcon icon="trash-alt" className="mr-2" />{allStrings.remove}
                                              </MDBBtn>
                                            </div>
                                        }
                                      </div>
                                  </form>
                                  </MDBCol>
                              </MDBRow>
                          </MDBContainer>
                          </CardContent>
                      </Card>
                  </div>
              </MDBCol>
            </MDBRow>
      </MDBContainer>
      </Spin>
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(requestInfo)
  );
