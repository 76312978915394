/* eslint-disable eqeqeq */
import React from 'react';
import Table from '../../component/table/table'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import './business.css'
import {message} from 'antd';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {NavLink, withRouter,Redirect} from 'react-router-dom'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { CSVLink } from "react-csv";

let headers = [
  { label: "#", key: "id" },
  { label: "الاسم الاول", key: "firstname" },
  { label: "الاسم الاخير", key: "lastname" },
  { label: "رقم هاتف المالك", key: "phone" },
  { label: "المكتب العقارى", key: "office" },
  { label: "اسم المؤسسه", key: "entityName" },
  { label: "الحاله", key: "status" },
];
 
let csvData = [
  
];

class business extends React.Component {
  
  state={
    business:[],
    business2:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    count:0,
    csvData:[],
    isReady:false,
    buildCsv:false
  }

  constructor(props){
    super(props)
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  componentDidMount(){
    this.getBusiness(1,false)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }


  getBusiness = (page,refresh) =>{
    this.setState({loading:true})
    axios.get(`${BASE_END_POINT}marketPlace?page=${page}`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log("business   ",response.data)
      let data = response.data.data.map(item=>[
        item.id,
        item.owner_firstname?item.owner_firstname.substr(0,20) + " ...":"",
        item.owner_lastName?item.owner_lastName.substr(0,20) + " ...":"",
        item.owner_phone?item.owner_phone.substr(0,20) + " ...":"",
        item.office?item.office.fullname.substr(0,20) + " ...":"",
        item.entity_number?item.entity_number:'',
        item.status==="PENDING"?{type:'tag',value:allStrings.pending}:item.status=="ACCEPTED"?{type:'tag',value:allStrings.accepted}:item.status=="FIXING-PHASE"?{type:'tag',value:allStrings.fixingPhase}:{type:'tag',value:allStrings.refused},
        "action"])
      console.log("DATA   ",data)
      this.setState({
        loading:false,
        refresh:false,
        business:response.data.data,
        business2:data,
        page:response.data.page,
        pages:response.data.pageCount,
        count:response.data.totalCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }

  getBusinessWithoutPagenation = () =>{
    let url = `${BASE_END_POINT}marketPlace/withoutPagenation/get`
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en',
      }
    })
    .then(response=>{
      
        csvData= response.data.MarketPlaces.map(item => {
          return {
            ...{id:item.id},
            ...{firstname:item.owner_firstname?item.owner_firstname.substr(0,20) + " ...":""},
            ...{lastname:item.owner_lastName?item.owner_lastName.substr(0,20) + " ...":""},
            ...{phone:item.owner_phone?item.owner_phone.substr(0,20) + " ...":""},
            ...{office:item.office?item.office.fullname?item.office.fullname.substr(0,20):"":""},
            ...{entityName:item.entity_number?item.entity_number:''},
            ...{status:item.status==="PENDING"?allStrings.pending:item.status==="ACCEPTED"?allStrings.accepted:item.status==="FIXING-PHASE"?allStrings.fixingPhase:allStrings.refused}
          };
        });
        this.setState({csvData:csvData,isReady:true})
      
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
    })
  }
  delete = (id) => {
    console.log("ID   ",id)
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}marketPlace/${id}`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
     },
    })
    .then(response=>{
      l.then(() => message.success(allStrings.done, 2.5) )
      this.getBusiness(this.state.page)
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
 }

  addNewRecordButton = () =>(
    <div style={{width:'100%'}}>
      <NavLink to="addNews">
        <Fab
          style={{background:'#00B483',float:'left',margin:'50px 30px'}}
          letiant="extended"
          size="medium"
          color="primary"
          aria-label="add"
        >
          <AddIcon />{allStrings.addNewRecored}
        </Fab>
      </NavLink>
       
    </div>
  )

  paginationButtons = () =>(
    <CardActions style={{display: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getBusiness(1)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
         if(this.state.page>1){
            this.getBusiness(this.state.page-1)
            this.setState({page:this.state.page-1})
         }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getBusiness(page)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`#00B483 !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getBusiness(this.state.page+1)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
         onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getBusiness(this.state.pages)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )

  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('BUSINESS')) return <Redirect to='/Home' />

  return (
    <div className="App">
        
      <MDBContainer> 
      <MDBRow>
        <MDBCol md="12">
          <div className="screenTable">
          <Table
            settings = {
              <div>
                {/* <i class="fab fa-searchengin searchIcon" onClick={()=>this.setState({showFilter:!this.state.showFilter})}></i> */}
                <span 
                style={{zIndex: '1',position: 'relative',padding: '4px',color: '#659FF1',fontSize: '25px',borderRadius:'50%',top:'4px',cursor: 'pointer'}}
                onClick={() => {this.getBusiness(1,false);this.setState({buildCsv:false})}}
                class="material-icons">
                  replay
                </span>
                {!this.state.buildCsv && 
                  <img crossorigin="anonymous"alt="" onClick={() => {this.setState({buildCsv:true});this.getBusinessWithoutPagenation()}}
                  style={{padding: '0px',position: 'relative',top: '-6px',marginLeft:'5px',cursor: 'pointer'}} 
                  src={require('../../assets/images/buildCsv.png')} width="26"></img>
                }{!this.state.isReady && this.state.buildCsv&&
                  <img crossorigin="anonymous" alt="" 
                  style={{padding: '0px',position: 'relative',top: '-5px',marginLeft:'-15px'}} 
                  src={require('../../assets/images/loading.gif')} width="70"></img>
                }{this.state.isReady && this.state.buildCsv&&
                <CSVLink 
                  data={this.state.csvData} 
                  headers={headers} 
                  filename={"business.csv"}
                  style={{padding: '10px',position: 'relative',top: '-5px'}}
                  >
                  <img alt="" src={require('../../assets/images/export-csv.png')} width="28"></img>
                </CSVLink>
                }
              </div>
              
            }
            title={allStrings.business}
            icon='business'
            data={this.state.business}
            data2={this.state.business2}
            tableColumns={["#",allStrings.firstName,allStrings.lastName,allStrings.phone,allStrings.office,allStrings.entityNumber,allStrings.status,allStrings.action]}
            loading={this.state.loading}
            deleteUser={(id)=>{this.delete(id)}}
            path='/businessInfo'
            actionIndex={3}
            noDelete={true}
            enableEdit={false}
            dataCount={this.state.count}
            />
            {this.paginationButtons()}
            </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer >
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(business)
);

