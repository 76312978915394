import React from 'react';
import './city info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon,MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Tooltip from '@material-ui/core/Tooltip';
import { Info,Edit } from '@material-ui/icons';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,Image,Spin,Empty,TreeSelect } from "antd";
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from 'react-loading-skeleton';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { UploadOutlined } from '@ant-design/icons';

const TreeNode = TreeSelect
const { SHOW_PARENT } = TreeSelect;
class CityInfo extends React.Component {
    state = {
        actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
        id:this.props.location.state.data.id,
        country:this.props.location.state.country?this.props.location.state.country:1,
        region:this.props.location.state.region?this.props.location.state.region.id:1,
        priority:this.props.location.state.priority?this.props.location.state.priority:0,
        cityName_en:this.props.location.state.data.cityName_en,
        cityName_ar:this.props.location.state.data.cityName_ar,
        latitude:this.props.location.state.data.location.coordinates[1],
        longitude:this.props.location.state.data.location.coordinates[0],
        clientCount:this.props.location.state.clientCount?this.props.location.state.clientCount:0,
        officeCount:this.props.location.state.officeCount?this.props.location.state.officeCount:0,
        adsRequestCount:this.props.location.state.adsRequestCount?this.props.location.state.adsRequestCount:0,
        adsCount:this.props.location.state.adsCount?this.props.location.state.adsCount:0,
        longitudeArea:'0',
        latitudeArea:'0',
        areas:[],
        regions:[],
        areaName_en:'',
        areaName_ar:'',
        modal:false,
        img:null,
        imgName:'',
        loading:true,
        page:1,
        pages:1
    };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        this.getAreas(1)
        this.getregions()
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    getregions = (page) => {
      axios.get(`${BASE_END_POINT}regions/1/withoutPagenation/get`)
      .then(response=>{
          console.log('regions  ',response.data)
          this.setState({
            regions:response.data.regions,
          })
      })
      .catch(error=>{
          console.log('regions ERROR  ',error)
          console.log('regions ERROR  ',error.respose)
      })
  }

    getAreas = (page) => {
        axios.get(`${BASE_END_POINT}areas/${this.props.location.state.data.id}/cities?page=${page}`)
        .then(response=>{
            console.log('AREAS  ',response.data)
            this.setState({
              areas:response.data.data,
              pages:response.data.pageCount,
              page:response.data.page,
              loading:false,
            })
        })
        .catch(error=>{
            console.log('AREAS ERROR  ',error)
            console.log('AREAS ERROR  ',error.respose)
        })
    }

    updateCity = () => {
        const {cityName_en,cityName_ar,longitude,latitude,country,region,img,priority} = this.state
        if(hasValue(cityName_en)&&hasValue(cityName_ar)&&img!=null){
            let l = message.loading('Wait..', 2.5)
            let data = new FormData()
            data.append('cityName_ar',cityName_ar)
            data.append('cityName_en',cityName_en)
            data.append('country',country)
            data.append('region',region)
            data.append('priority',priority)
            data.append('location',JSON.stringify([longitude,latitude]))
            if(img){
                data.append('img',img); 
            }
            console.log(Array.from(data))
            axios.put(`${BASE_END_POINT}countries/cities/${this.props.location.state.data.id}`,data,{
            headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            console.log("CITY UPDATED  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
    }

    deleteCity = () => {
        let l = message.loading('Wait..', 2.5)
        axios.delete(`${BASE_END_POINT}countries/cities/${this.props.location.state.data.id}`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization':`Bearer ${this.props.currentUser.token}`,
           'Accept-Language':this.props.isRTL?'ar':'en'
         },
        })
        .then(response=>{
          console.log("city DELETED")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
    addArea = () => {
        const {areaName_en,areaName_ar,longitudeArea,latitudeArea} = this.state
        if(hasValue(areaName_en)&&hasValue(areaName_ar)){
            let l = message.loading('Wait..', 2.5)
            const data={
                'areaName_en':areaName_en,
                'areaName_ar':areaName_ar,
                'location':[longitudeArea,latitudeArea]
            }
            axios.post(`${BASE_END_POINT}areas/${this.props.location.state.data.id}/cities`,JSON.stringify(data),{
                headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${this.props.currentUser.token}`,
                'Accept-Language':this.props.isRTL?'ar':'en'
                },
            })
            .then(response=>{
                console.log("CITY UPDATED  ",response.data)
                l.then(() => message.success(allStrings.done, 2.5) )
                this.getAreas()
                this.toggle()
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
    }
    fileChangedHandler = event => {
      this.setState({
        img: event.target.files[0],
        imgName: event.target.files[0].name,
        imgType:'data'
      })
    }
    removeFile = () => {
      this.setState({
          img:null,
          imgName:'',
          imgType:'url'
      });
    }
    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.updateCity()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
      
    submitHandlerAddArea = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";
        this.addArea()
    };
    changeHandlerAddArea = (event) => {
        console.log(event.target.value);
        this.setState({ [event.target.name]: event.target.value });
    };

    areasTable = () =>{
        return(
        <MDBRow>
        <MDBCol md="12">
            <div className="infoTable">
                <div className="topInfoTable" style={{ background: '#00B483' }}>
                    <h4>
                    <MDBIcon icon="map" /> {allStrings.areas}
                    </h4>
                </div>
                <div className="tableDate">
                    <div className="table-responsive">
                        <table className="table table-striped">
                        <thead>
                          {this.props.isRTL?
                            <tr style={{textAlign:'center'}}>
                                <th>{allStrings.more}</th>
                                <th>{allStrings.englishName}</th>
                                <th>{allStrings.arabicName}</th>
                                <th scope="col">#</th>
                            </tr>
                                :
                            <tr style={{textAlign:'center'}}>
                                <th scope="col">#</th>
                                <th>{allStrings.englishName}</th>
                                <th>{allStrings.arabicName}</th>
                                <th>{allStrings.more}</th>
                            </tr>
                          }
                              
                        </thead>
                        <tbody>
                        {this.state.loading?
                            <tr style={{height: '200px'}}>
                                <th colSpan="6" className="noData">
                                <Skeleton height={400}/> 
                                </th>
                            </tr>
                            :
                            this.state.areas.length > 0 ?
                            this.state.areas.map(area =>(
                              this.props.isRTL?
                              <tr style={{textAlign:'center'}}>
                                  <td>
                                  <span style={{margin: "4px",cursor:'pointer'}} onClick={()=>{this.props.history.push("areaInfo",{data:area}) }} >
                                      <Tooltip title={allStrings.info} placement="top">
                                      <Info style={{color:'#33d633'}}/>
                                      </Tooltip>
                                  </span>
                                  <span onClick={()=>{this.props.history.push('areaInfo',{data:area,actionType:'EDIT'}) }} style={{margin: "4px",cursor:'pointer'}}>
                                    <Tooltip title={allStrings.edit} placement="top" arrow>
                                        <Edit style={{color:'cornflowerblue',fontSize: '20px'}}/>
                                    </Tooltip>
                                  </span> 
                                  </td>
                                  <td>{area.areaName_en}</td>
                                  <td>{area.areaName_ar}</td>
                                  <td>{area.id}</td>
                              </tr>
                              :
                              <tr style={{textAlign:'center'}}>
                                  <td>{area.id}</td>
                                  <td>{area.areaName_en}</td>
                                  <td>{area.areaName_ar}</td>
                                  <td>
                                  <span style={{margin: "4px",cursor:'pointer'}} onClick={()=>{this.props.history.push("areaInfo",{data:area}) }} >
                                      <Tooltip title={allStrings.info} placement="top">
                                      <Info style={{color:'#33d633'}}/>
                                      </Tooltip>
                                  </span>
                                  <span onClick={()=>{this.props.history.push('areaInfo',{data:area,actionType:'EDIT'}) }} style={{margin: "4px",cursor:'pointer'}}>
                                    <Tooltip title={allStrings.edit} placement="top" arrow>
                                        <Edit style={{color:'cornflowerblue',fontSize: '20px'}}/>
                                    </Tooltip>
                                  </span> 
                                  </td>
                              </tr>
                                ))
            
                                :
                                <tr style={{height: '200px'}}>
                                    <th colSpan="6" className="noData">
                                    <Empty description={allStrings.noData} style={{margin:'0rem'}}/>
                                    </th>
                                </tr>
                            }
                        </tbody>
                        </table>
                        {this.state.areas.length > 0 &&
                            <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
                                {this.paginationButtons()}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </MDBCol>
    </MDBRow>
        )
    }
    imageSection = () =>{
      return(
        <MDBCol  xl ="4" lg = "5"  md="12" sm="12">
          <div className="infoCard">
              <div style={{width:"100%" ,padding:'10px'}}>
                <Image
                  width="100%"
                  placeholder={true}
                  style={{borderRadius:'6px',width:"100%",height:'280px'}} 
                  src={this.state.imgType==="data"?URL.createObjectURL(this.state.img):this.props.location.state.data.img}
                />
              </div>
          </div>
        </MDBCol>
      )
    }
    paginationButtons = () =>(
      <CardActions style={{display: 'inline-flex'}}>
          <Button 
          onClick={()=>{
            if(this.state.page!==1){
              this.getAreas(1)
              this.setState({page:1})
            }
          }}
          className="pagenation" >{allStrings.first}</Button>
  
          <Button 
          onClick={()=>{
            if(this.state.page>1){
              this.getAreas(this.state.page-1)
              this.setState({page:this.state.page-1})
            }
          }}
          className="pagenation">{allStrings.prev}</Button>
          
          <Pagination
          onChange={(event,page)=>{
          console.log("page   ",page)
          if(page!==this.state.page){
            this.getAreas(page)
            this.setState({page:page})
          }
          
          }} 
          defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`${'#00B483'} !important`}} />
          
          <Button 
          onClick={()=>{
            if(this.state.page<this.state.pages){
              this.getAreas(this.state.page+1)
              this.setState({page:this.state.page+1})
            }
          }}
          className="pagenation">{allStrings.next}</Button>
  
          <Button 
            onClick={()=>{
            if(this.state.page!==this.state.pages){
              this.getAreas(this.state.pages)
              this.setState({page:this.state.pages})
            }
          }}
          className="pagenation">{allStrings.last}</Button>
  
      </CardActions>
    )
    addNewRecordButton = () =>(
      <div style={{width:'100%'}}>
          <Fab
            onClick={this.toggle} 
            style={{background:'#00B483',float:'left',margin:'50px 30px'}}
            letiant="extended"
            size="medium"
            color="primary"
            aria-label="add"
          >
            <AddIcon />{allStrings.addArea}
          </Fab>
      </div>
    )
    toggle = () => {
      this.setState({
        modal: !this.state.modal,
      });
    }
    modal = () =>(
      <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
        <MDBModalHeader toggle={this.toggle} >{allStrings.completeData}</MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <form style={{paddingTop:'1rem'}}
                    className="needs-validation"
                    onSubmit={this.submitHandlerAddArea}
                    noValidate
                  >
                    <div className="grey-text">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="md-form">
                            <label htmlFor="form92" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form92" className="form-control" onChange={this.changeHandlerAddArea} required validate  name="areaName_en"/>
                          
                            <div class="valid-feedback">{allStrings.correct}</div>
                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <div className="md-form">
                            <label htmlFor="form89" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form89" className="form-control" onChange={this.changeHandlerAddArea} required validate name="areaName_ar"/>
                            <div class="valid-feedback">{allStrings.correct}</div>
                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol md="6">
                            <div className="md-form">
                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.latitude}</label>
                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="number" step="0.01" id="form65" name="latitudeArea" className="form-control" onChange={this.changeHandlerAddArea} required  validate/>
                            <div class="valid-feedback">{allStrings.correct}</div>
                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                            </div>
                        </MDBCol>
                        <MDBCol md="6">
                            <div className="md-form">
                            <label htmlFor="form64" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.longitude}</label>
                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="number" step="0.01"  id="form64" name="longitudeArea" className="form-control" onChange={this.changeHandlerAddArea} required  validate/>
                            <div class="valid-feedback">{allStrings.correct}</div>
                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                            </div>
                        </MDBCol>
                      </MDBRow>
                    
                    </div>
                    <br></br>
                    <br></br>
                    <div className="text-center">
                      <MDBBtn type="submit" style={{ background: '#00B483' }} rounded className="btn-block z-depth-1a" >
                        <MDBIcon icon="plus" className="mr-2" />
                        {allStrings.add}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    );

     
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('COUNTRIES')) return <Redirect to='/Home' />
  return (
    <div className="App">
      <Spin spinning={this.state.loading} tip={allStrings.loading} size="large" style={{color:'#00B483'}}>
        {this.modal()}
        <MDBContainer> 
            <MDBRow>  
                <MDBCol xl={this.state.actionType==="VIEW"?"8":"12"} lg = {this.state.actionType==="VIEW"?"7":"12"} md="12" sm="12">
                  <div className="infoCard">
                      <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}> 
                            <div className="topIcon" style={{background:'#00B483'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>'location_city'</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{this.state.actionType==="VIEW"?allStrings.cityInfo:allStrings.edit}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                    {this.state.actionType==="VIEW"&&
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form8" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                          <div className="md-form">
                                          <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.priority}</label>
                                          <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.priority} type="number"   id="form65" name="priority" className="form-control" onChange={this.changeHandler}  validate/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                        </MDBCol>
                                    </MDBRow>
                                    }{this.state.actionType==="EDIT"&&
                                    <MDBRow>
                                      <MDBCol md="12">
                                          <div className="md-form">
                                          <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.priority}</label>
                                          <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.priority} type="number"   id="form65" name="priority" className="form-control" onChange={this.changeHandler}  validate/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.cityName_en} type="text" id="form645" name="cityName_en" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.cityName_ar} type="text" id="form56" name="cityName_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                      <MDBCol md="6">
                                          <div className="md-form">
                                          <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.latitude}</label>
                                          <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.latitude} type="number" step="0.01"  id="form65" name="latitude" className="form-control" onChange={this.changeHandler} required  validate/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                      </MDBCol>
                                      <MDBCol md="6">
                                          <div className="md-form">
                                          <label htmlFor="form64" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.longitude}</label>
                                          <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.longitude} type="number" step="0.01"  id="form64" name="longitude" className="form-control" onChange={this.changeHandler} required  validate/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                      </MDBCol>
                                    </MDBRow>
                                    {this.state.actionType==="VIEW"&&
                                    <MDBRow>
                                      <MDBCol md="6">
                                          <div className="md-form">
                                          <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.clientCount}</label>
                                          <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.clientCount} type="number"   id="form695" name="clientCount" className="form-control" />
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                      </MDBCol>
                                      <MDBCol md="6">
                                          <div className="md-form">
                                          <label htmlFor="form604" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.officeCount}</label>
                                          <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.officeCount} type="number"  id="form694" name="officeCount" className="form-control" />
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                      </MDBCol>
                                    </MDBRow>
                                    }{this.state.actionType==="VIEW"&&
                                    <MDBRow>
                                      <MDBCol md="6">
                                          <div className="md-form">
                                          <label htmlFor="form695" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.adsCount}</label>
                                          <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.adsCount} type="number"   id="form695" name="adsCount" className="form-control" />
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                      </MDBCol>
                                      <MDBCol md="6">
                                          <div className="md-form">
                                          <label htmlFor="form694" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.adsRequestCount}</label>
                                          <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.adsRequestCount} type="number"  id="form694" name="adsRequestCount" className="form-control" />
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                      </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.actionType==="EDIT"&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                    <MDBCol md="6">
                                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.region}</label>
                                        <TreeSelect virtual={false}
                                            value={this.state.region}
                                            showSearch={true} 
                                            disabled={this.state.actionType==="VIEW"?true:false}
                                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                            treeCheckable = {false}         
                                            treeNodeFilterProp="title"                      
                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            placeholder={allStrings.region}
                                            
                                            showCheckedStrategy= {SHOW_PARENT}
                                            onChange={(value) => {
                                            console.log(value)
                                            this.setState({region:value});
                                            
                                            }} 
                                        
                                        >
                                            {this.state.regions.map(val=>(
                                                <TreeNode value={val.id} title={this.props.isRTL?val.regionName_ar:val.regionName_en} key={val.id} />
                                            ))}
                                        </TreeSelect>
                                    </MDBCol>
                                    <MDBCol md="6"></MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.actionType==="EDIT"&&
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="fileUpload2"> 
                                                <input  key={this.state.img} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                            </div>
                                            <div className="preview">
                                            {this.state.img&&
                                            <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                <img alt="" src={this.state.imgType==="data"?URL.createObjectURL(this.state.img):this.state.img}  className="previewImg2"/>
                                                <span className="imgName">{this.state.imgName}</span>
                                                <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                onClick={() => this.removeFile()}  ></MDBIcon>
                                            </div>
                                            }
                                            </div>
                                            
                                        </MDBCol>
                                        
                                    </MDBRow>
                                    }
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                      {this.state.actionType==="EDIT"&&
                                        <MDBBtn
                                        type="submit" style={{background:'#00B483'}}
                                        rounded
                                        className="btn-block z-depth-1a"
                                        >
                                            <MDBIcon icon="edit" className="mr-2" />
                                            {allStrings.updateCity}          
                                        </MDBBtn>
                                      }{this.state.actionType==="VIEW"&&
                                        <MDBBtn onClick={()=>{this.deleteCity()}} rounded color="danger" className="btn-block z-depth-1a">
                                            <MDBIcon icon="trash-alt" className="mr-2" />
                                            {allStrings.remove}
                                        </MDBBtn>
                                      }
                                        
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                      </Card>
                  </div>
              </MDBCol>
              {this.state.actionType==="VIEW"&&this.imageSection()} 
            </MDBRow>
            {this.state.actionType==="VIEW"&&this.areasTable()}
            {this.state.actionType==="VIEW"&&this.addNewRecordButton()}
      </MDBContainer>
      </Spin>
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(CityInfo)
  );