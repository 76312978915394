import LanguageReducer from './LanguageReducer';
import MenuReducer from './MenuReducer';

import AuthReducer from './AuthReducer';
import { combineReducers } from "redux";
import ChatReducer from './ChatReducer'
import NotificationReducer from './NotificationReducer'

export default combineReducers({
    lang: LanguageReducer,
    menu: MenuReducer,
    auth: AuthReducer,
    chat: ChatReducer,
    notif: NotificationReducer,
});