import reducers from '../reducers';
import { createStore,applyMiddleware } from 'redux'
import thunk from "redux-thunk" ;
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from  'redux-persist/lib/stateReconciler/autoMergeLevel2';


const persistConfig = {
	key: 'root',
	storage: storage,
	stateReconciler: autoMergeLevel2
}

const persistReducer2 = persistReducer(persistConfig, reducers)

export default () => {
	let store = createStore(persistReducer2);
	let persistor = persistStore(store);
	return {store, persistor}
}


export const store = createStore(persistReducer2,applyMiddleware(thunk))
export const persistor = persistStore(store)

