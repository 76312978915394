import React from 'react';
import './category info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,Spin ,Empty,TreeSelect} from "antd";

import Tooltip from '@material-ui/core/Tooltip';
import { Info ,Edit} from '@material-ui/icons';
import Skeleton from 'react-loading-skeleton';
import Pagination from '@material-ui/lab/Pagination';
const TreeNode = TreeSelect

class CategoryInfo extends React.Component {
    state = {
      actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
        id:this.props.location.state.data.id,
        categoryName_en:this.props.location.state.data.categoryName_en,
        categoryName_ar:this.props.location.state.data.categoryName_ar,
        description_en:this.props.location.state.data.description_en,
        description_ar:this.props.location.state.data.description_ar,
        priority:this.props.location.state.data.priority,
        type:this.props.location.state.data.type,
        categories:[],
        loading:true,
        refresh:false,
        page:1,
        pages:1,
        modal: false,
      };

      constructor(props){
        super(props)
        window.scrollTo(0,0)
        this.getCategories(1,false)
        if(this.props.isRTL){
          allStrings.setLanguage('ar')
        }else{
          allStrings.setLanguage('en')
        } 
      }
      componentDidMount(){
        if(this.props.isRTL){
          allStrings.setLanguage('ar')
        }else{
          allStrings.setLanguage('en')
        }
      }
      getCategories = (page,refresh) =>{
        this.setState({loading:true})
        axios.get(`${BASE_END_POINT}categories/${this.state.id}/pagenation-subCategories?page=${page}`,{
          headers:{
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
          }
        })
        .then(response=>{
          console.log("Categories   ",response.data)
          this.setState({
            loading:false,
            refresh:false,
            categories:response.data.data,
            page:response.data.page,
            pages:response.data.pageCount,
          })
        })
        .catch(error=>{
          console.log("error   ",error.response)
          console.log("error2   ",error)
          this.setState({loading:false,})
        })
    
      }

      updateCategory = () => {
        const {type,categoryName_ar,categoryName_en,priority,description_ar,description_en} = this.state
    
        if(hasValue(categoryName_ar)&&hasValue(categoryName_en)){
            let l = message.loading('Wait..', 2.5)
            let data = new FormData()
            data.append('categoryName_ar',categoryName_ar)
            data.append('categoryName_en',categoryName_en)
            data.append('description_ar',description_ar)
            data.append('description_en',description_en)
            data.append('type',type)
            data.append('priority',priority)
            axios.put(`${BASE_END_POINT}categories/${this.props.location.state.data.id}`,data,{
            headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            console.log("USER UPDATED  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
     }

     deleteCategory = () => {
        let l = message.loading('Wait..', 2.5)
        axios.delete(`${BASE_END_POINT}categories/${this.props.location.state.data.id}`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization':`Bearer ${this.props.currentUser.token}`,
           'Accept-Language':this.props.isRTL?'ar':'en'
         },
        })
        .then(response=>{
          console.log("USER DELETED")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
     }
     

    
      submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
        this.updateCategory()
      };
    
      changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
      };

     
     
      
      paginationButtons = () =>(
        <CardActions style={{display: 'inline-flex'}}>
            <Button 
            onClick={()=>{
              if(this.state.page!==1){
                this.getCategories(1)
                this.setState({page:1})
              }
            }}
            className="pagenation" >{allStrings.first}</Button>
    
            <Button 
            onClick={()=>{
             if(this.state.page>1){
                this.getCategories(this.state.page-1)
                this.setState({page:this.state.page-1})
             }
            }}
            className="pagenation">{allStrings.prev}</Button>
            
            <Pagination
            onChange={(event,page)=>{
            console.log("page   ",page)
            if(page!==this.state.page){
              this.getCategories(page)
              this.setState({page:page})
            }
            
            }} 
            defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`#00B483 !important`}} />
            
            <Button 
            onClick={()=>{
              if(this.state.page<this.state.pages){
                this.getCategories(this.state.page+1)
                this.setState({page:this.state.page+1})
              }
            }}
            className="pagenation">{allStrings.next}</Button>
    
            <Button 
             onClick={()=>{
              if(this.state.page!==this.state.pages){
                this.getCategories(this.state.pages)
                this.setState({page:this.state.pages})
              }
            }}
            className="pagenation">{allStrings.last}</Button>
    
        </CardActions>
      )
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('CATEGORIES')) return <Redirect to='/Home' />
  return (
    <div className="App">
      <Spin spinning={this.state.loading} tip={allStrings.loading} size="large" style={{color:'#00B483'}}>
        <MDBContainer>
            <MDBRow>
                <MDBCol xl ="12" lg = "12" md="12" sm="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#00B483'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>category</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{this.state.actionType==="VIEW"?allStrings.categoryInfo:allStrings.edit}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                      {this.state.actionType==="VIEW"&&
                                        <MDBRow>
                                            
                                            <MDBCol md="12">
                                                <div className="md-form">
                                                    <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                                    <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.id} type="text" id="form8" className="form-control" onChange={this.changeHandler} required validate/>
                                                    <div class="valid-feedback">{allStrings.correct}</div>
                                                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        }
                                        {this.state.actionType==="EDIT"&&
                                        <MDBRow>
                                          <MDBCol md="12">
                                              <div className="md-form">
                                              <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.priority}</label>
                                              <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.priority} type="number"   id="form65" name="priority" className="form-control" onChange={this.changeHandler}  validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                            </MDBCol>
                                        </MDBRow>
                                        }
                                        <MDBRow>
                                            <MDBCol md="6">
                                                <div className="md-form">
                                                    <label htmlFor="form3" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                                    <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.categoryName_en} type="text" id="form3" name="categoryName_en" className="form-control" onChange={this.changeHandler} required validate/>
                                                    <div class="valid-feedback">{allStrings.correct}</div>
                                                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>

                                            <MDBCol md="6">
                                                <div className="md-form">
                                                    <label htmlFor="form3" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                                    <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.categoryName_ar} type="text" id="form2" name="categoryName_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                                    <div class="valid-feedback">{allStrings.correct}</div>
                                                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                          <MDBCol md="12">
                                            <div className="md-form">
                                              <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishDescription}</label>
                                              <br></br>
                                              <textarea disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } name="description_en" onChange={this.changeHandler} value={this.state.description_en} style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" className="form-control"  validate   />
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                          </MDBCol>
                                          
                                        </MDBRow>
                                        <MDBRow>
                                          <MDBCol md="12">
                                            <div className="md-form">
                                              <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicDescription}</label>
                                              <br></br>
                                              <textarea disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } name="description_ar" onChange={this.changeHandler} value={this.state.description_ar} style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" className="form-control"  validate   />
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                          </MDBCol>
                                        </MDBRow>
                                        <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                         
                                          <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.type}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false} 
                                                value={this.state.type}
                                                disabled={this.state.actionType==="VIEW"?true:false}
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.type}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({type:value});
                                                }} 
                                            
                                            >
                                                <TreeNode value="BUILDING" title={allStrings.building} key="BUILDING" />
                                                <TreeNode value="LAND" title={allStrings.lands} key="LAND" />
                                                <TreeNode value="VILLA" title={allStrings.villa} key="VILLA" />
                                                <TreeNode value="VILLAS" title={allStrings.villas} key="VILLAS" />
                                                <TreeNode value="PLANNED" title={allStrings.plan} key="PLANNED" />
                                                <TreeNode value="NORMAL" title={allStrings.other} key="NORMAL" />
                                            </TreeSelect>
                                          </MDBCol>
                                          <MDBCol md="6"></MDBCol>
                                        </MDBRow>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                      {this.state.actionType==="EDIT"&&
                                        <MDBBtn
                                        style={{background:'#00B483',color:'#fff'}}
                                        rounded
                                        type="submit"
                                        className="btn-block z-depth-1a"
                                        >
                                            <MDBIcon icon="edit" className="mr-2" />
                                            {allStrings.updateCategory}        
                                        </MDBBtn>
                                      }{this.state.actionType==="VIEW"&&
                                        <MDBBtn
                                         onClick={()=>{this.props.history.push('/addSubCategory',{data:this.state.id}) }} 
                                        color="primary"
                                        rounded
                                        className="btn-block z-depth-1a"
                                        >
                                            <MDBIcon icon="plus" className="mr-2" />
                                            {allStrings.addSubCategory}        
                                        </MDBBtn>
                                      }{this.state.actionType==="VIEW"&&
                                        <MDBBtn onClick={()=>{this.deleteCategory()}} rounded color="danger" className="btn-block z-depth-1a">
                                            <MDBIcon icon="trash-alt" className="mr-2" />
                                          {allStrings.remove}
                                        </MDBBtn>
                                      }
                                    </div>
                                </form>
                                </MDBCol>
                                
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
                </MDBCol>
            </MDBRow>
            {this.state.actionType==="VIEW"&&
            <MDBRow>
                <MDBCol md="12">
                    <div className="infoTable">
                        <div className="topInfoTable" style={{ background: "#00B483" }}>
                            <h4>
                            <span className="material-icons" style={{color:'white',fontSize:23}}>category</span>{ allStrings.subCategory}
                            </h4>
                        </div>
                        <div className="tableDate">
                        <div className="table-responsive">
                            <table className="table table-striped">
                            <thead>
                              {this.props.isRTL?
                              <tr style={{textAlign:'center'}}>
                                  <th>{allStrings.more}</th>
                                  <th>{allStrings.arabicName}</th>
                                  <th>{allStrings.englishName}</th>
                                  <th scope="col">#</th>
                              </tr>
                              :
                              <tr style={{textAlign:'center'}}>
                                    <th scope="col">#</th>
                                    <th>{allStrings.arabicName}</th>
                                    <th>{allStrings.englishName}</th>
                                    <th>{allStrings.more}</th>
                              </tr>
                              }
                                
                            </thead>
                            <tbody>
                            {this.state.loading?
                                <tr style={{height: '200px'}}>
                                    <th colSpan={this.state.tableType === "collected" ?"5":"4"} className="noData">
                                    <Skeleton height={400}/> 
                                    </th>
                                </tr>
                                :
                                this.state.categories.length > 0 ?
                                this.state.categories.map(val =>(
                                  this.props.isRTL?
                                  <tr  style={{textAlign:'center'}}>
                                      <td>
                                      <span style={{margin: "4px",cursor:'pointer'}} onClick={()=>{this.props.history.push('/subCategoryInfo',{data:val}) }} >
                                          <Tooltip title={allStrings.info} placement="top">
                                          <Info style={{color:'#33d633'}}/>
                                          </Tooltip>
                                      </span>
                                      <span onClick={()=>{this.props.history.push('subCategoryInfo',{data:val,actionType:'EDIT'}) }} style={{margin: "4px",cursor:'pointer'}}>
                                          <Tooltip title={allStrings.edit} placement="top" arrow>
                                              <Edit style={{color:'cornflowerblue',fontSize: '20px'}}/>
                                          </Tooltip>
                                      </span> 
                                      </td>
                                      <td>{val.categoryName_ar}</td>
                                      <td>{val.categoryName_en}</td>
                                      
                                      <td >{val.id}</td>
                                  </tr>
                                  :
                                  <tr  style={{textAlign:'center'}}>
                                      <td >{val.id}</td>
                                      <td>{val.categoryName_ar}</td>
                                      <td>{val.categoryName_en}</td>
                                      <td
                                      >
                                      <span style={{margin: "4px",cursor:'pointer'}} onClick={()=>{this.props.history.push('/subCategoryInfo',{data:val}) }} >
                                          <Tooltip title={allStrings.info} placement="top">
                                          <Info style={{color:'#33d633'}}/>
                                          </Tooltip>
                                      </span>
                                      <span onClick={()=>{this.props.history.push('subCategoryInfo',{data:val,actionType:'EDIT'}) }} style={{margin: "4px",cursor:'pointer'}}>
                                          <Tooltip title={allStrings.edit} placement="top" arrow>
                                              <Edit style={{color:'cornflowerblue',fontSize: '20px'}}/>
                                          </Tooltip>
                                      </span> 
                                      </td>
                                  </tr>
                               
                                 ))
               
                                 :
                                 <tr style={{height: '200px'}}>
                                     <th colSpan="12" className="noData">
                                     <Empty description={allStrings.noData} style={{margin:'0rem'}}/>
                                     </th>
                                 </tr>
                               }
                              
                               
                                
                            </tbody>
                            </table>
                            {this.state.categories.length > 0 &&
                                <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
                                    {this.paginationButtons()}
                                </div>
                            }
                        </div>
                        </div>
                    </div>
                </MDBCol>
            </MDBRow>
            }
            
            
        </MDBContainer>
      </Spin>
    </div> 
  )
  }
}



const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(CategoryInfo)
  );