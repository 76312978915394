import React from 'react';
import Table from '../../component/table/table'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import './contactRequest.css'
import {message,TreeSelect,DatePicker,ConfigProvider} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import { withRouter,Redirect} from 'react-router-dom'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { CSVLink } from "react-csv";
import moment from 'moment'
import locale_ar from 'antd/lib/locale/ar_EG';
import locale_en from 'antd/lib/locale/en_GB';
import 'moment/locale/ar';
const { TreeNode } = TreeSelect;
const { RangePicker } = DatePicker;
let headers = [
  { label: "#", key: "id" },
  { label: "المرسل", key: "sender" },
  { label: "المرسل اليه", key: "reciever" },
  { label: "الحاله", key: "status" },
  { label: "التاريخ", key: "createdAt" },
];
 
let csvData = [
  
];
class contactRequest extends React.Component {
  
  state={
    contactRequest:[],
    contactRequest2:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    search:null,
    status:null,
    respondStatus:null,
    showFilter:false,
    startDate:null,
    endDate:null,
    csvData:[],
    count:0,
    isReady:false,
    buildCsv:false
  }

  constructor(props){
    super(props)
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  componentDidMount(){
    this.getContactRequest(1,false)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  delete = (id) => {
    console.log("ID   ",id)
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}contactRequest/${id}`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`,
       'Accept-Language':this.props.isRTL?'ar':'en'
     },
    })
    .then(response=>{
      console.log("contactRequest DELETED")
      l.then(() => message.success(allStrings.done, 2.5) )
      this.getContactRequest(this.state.page)
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
 }
  getContactRequest = (page,refresh) =>{
    this.setState({loading:true})
    let {status,startDate,endDate,search,respondStatus} = this.state
    let url = `${BASE_END_POINT}contactRequest?page=${page}&all=true`
    if(refresh !== true ){
      let keysToFilter = {status,startDate,endDate,search,respondStatus}
      Object.keys(keysToFilter).forEach(key => {
        if (keysToFilter[key] !== null) {
          url += `&${key}=${keysToFilter[key]}`;
        }
      });
    }
    
    axios.get(url,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en',
      },
     })
    .then(response=>{
       console.log("contactRequest   ",response.data)
      let data = response.data.data.map((item,index)=>{
      return  [
        item.id,
        item.sender?item.sender.fullname?item.sender.fullname.substr(0,15):"":"",
        item.reciever?item.reciever.fullname?item.reciever.fullname.substr(0,15):"":"",
        item.status==="NEW"?{type:'tag',value:allStrings.new}:item.status==="CLOSED"?{type:'tag',value:allStrings.closed}:item.status==="COMPLETED"?{type:'tag',value:allStrings.completed}:item.status==="REFUSED"?{type:'tag',value:allStrings.refused}:item.status==="WITHDRAWAL"?{type:'tag',value:allStrings.withdrawal}:{type:'tag',value:allStrings.onProgress},
        item.createdAt?this.props.isRTL?moment(item.createdAt).locale('ar').format('ll'):moment(item.createdAt).locale('en').format('ll'):"",
        item.lastDate?this.props.isRTL?moment(item.lastDate).locale('ar').format('ll'):moment(item.lastDate).locale('en').format('ll'):"",

        "action"
      ]
      })  
      this.setState({
        loading:false,
        refresh:false,
        contactRequest:response.data.data,
        contactRequest2:data,
        page:response.data.page,
        count:response.data.totalCount,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  getContactRequestWithoutPagenation = (refresh) =>{
    let {status,startDate,endDate,respondStatus,search} = this.state
    let url = `${BASE_END_POINT}contactRequest/withoutPagenation/get?all=true`
    if(refresh !== true ){
      let keysToFilter = {status,startDate,endDate,search,respondStatus}
      Object.keys(keysToFilter).forEach(key => {
        if (keysToFilter[key] !== null) {
          url += `&${key}=${keysToFilter[key]}`;
        }
      });
    }
    
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en',
      }
    })
    .then(response=>{
      
        csvData= response.data.data.map(item => {
          return {
            ...{id:item.id},
            ...{sender:item.sender?item.sender.fullname.substr(0,15):""},
            ...{reciever:item.reciever?item.reciever.fullname.substr(0,15):""},
            ...{status:item.status==="NEW"?allStrings.new:item.status==="CLOSED"?allStrings.closed:item.status==="COMPLETED"?allStrings.completed:item.status==="REFUSED"?allStrings.refused:item.status==="WITHDRAWAL"?allStrings.withdrawal:allStrings.onProgress},
            ...{createdAt:item.createdAt?this.props.isRTL?moment(item.createdAt).locale('ar').format('ll'):moment(item.createdAt).locale('en').format('ll'):""}
          };
        });
        this.setState({csvData:csvData,isReady:true})      
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
    })
  }
  paginationButtons = () =>(
    <CardActions style={{display: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getContactRequest(1)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
         if(this.state.page>1){
            this.getContactRequest(this.state.page-1)
            this.setState({page:this.state.page-1})
         }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getContactRequest(page)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`${'#659FF1'} !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getContactRequest(this.state.page+1)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
         onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getContactRequest(this.state.pages)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )
  onChange = (date, dateString) =>{
    if(this.props.isRTL){
      this.setState({
        startDate:moment(dateString[0]).locale('en').format("YYYY-MM-DD"),
        endDate:moment(dateString[1]).locale('en').format("YYYY-MM-DD")
      })
    }else{
      this.setState({to:moment(dateString).locale('en').format("YYYY/MM/DD") })
      this.setState({
        startDate:moment(dateString[0]).locale('en').format("YYYY-MM-DD") ,
        endDate:moment(dateString[1]).locale('en').format("YYYY-MM-DD")
      })
      console.log(moment(dateString[0]).locale('en').format("YYYY-MM-DD") )

    }
    console.log( dateString);
  }

  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('CONTACT-REQUEST')) return <Redirect to='/Home' />

  return (
    <div className="App">
        
      <MDBContainer> 
      <MDBRow>
        <MDBCol md="12">
          <div className="screenTable">
          <Table
            settings = {
              <div>
                <i class="fab fa-searchengin searchIcon" onClick={()=>this.setState({showFilter:!this.state.showFilter})}></i>
                <span 
                style={{zIndex: '1',position: 'relative',padding: '4px',color: '#659FF1',fontSize: '25px',borderRadius:'50%',top:'4px',cursor: 'pointer'}}
                onClick={() => {this.getContactRequest(1,true);this.setState({buildCsv:false})}} 
                class="material-icons">
                  replay
                </span>
                {!this.state.buildCsv && 
                  <img crossorigin="anonymous"alt="" onClick={() => {this.setState({buildCsv:true});this.getContactRequestWithoutPagenation()}}
                  style={{padding: '0px',position: 'relative',top: '-6px',marginLeft:'5px',cursor: 'pointer'}} 
                  src={require('../../assets/images/buildCsv.png')} width="26"></img>
                }{!this.state.isReady && this.state.buildCsv&&
                  <img crossorigin="anonymous" alt="" 
                  style={{padding: '0px',position: 'relative',top: '-5px',marginLeft:'-15px'}} 
                  src={require('../../assets/images/loading.gif')} width="70"></img>
                }{this.state.isReady && this.state.buildCsv&&
                <CSVLink 
                  data={this.state.csvData} 
                  headers={headers} 
                  filename={"contactRequests.csv"}
                  style={{padding: '10px',position: 'relative',top: '-5px'}}
                  >
                  <img alt="" src={require('../../assets/images/export-csv.png')} width="28"></img>
                </CSVLink>
                }
              </div>
            }
            filter ={
              <div>
                <div className={this.state.showFilter?'showClass':'hideClass'}>
                  
                  <MDBRow>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                      <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.date}</label>
                      <ConfigProvider locale={this.props.isRTL?locale_ar:locale_en}>
                        <RangePicker format={"YYYY-MM-DD"} placeholder={[allStrings.startDate,allStrings.endDate]} onChange={this.onChange} />
                      </ConfigProvider>
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.respondStatus}</label>
                        <TreeSelect virtual={false}
                            showSearch={false}
                            value={this.state.respondStatus}
                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                            treeCheckable = {false}         
                            treeNodeFilterProp="title"                      
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={allStrings.respondStatus}
                            onChange={(value) => {
                            console.log(value)
                            this.setState({respondStatus:value});
                            }} 
                        
                        >
                            <TreeNode value="RESPONDED" title={allStrings.respond} key="RESPOND" />
                            <TreeNode value="NOT-RESPONDED" title={allStrings.notRespond} key="NOT-RESPOND" />
                        </TreeSelect>
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.status}</label>
                        <TreeSelect virtual={false}
                            showSearch={false}
                            value={this.state.status}
                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                            treeCheckable = {false}         
                            treeNodeFilterProp="title"                      
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={allStrings.status}
                            onChange={(value) => {
                            console.log(value)
                            this.setState({status:value});
                            }} 
                        
                        >
                            <TreeNode value="NEW" title={allStrings.new} key="NEW" />
                            <TreeNode value="ACCEPTED" title={allStrings.onProgress} key="ACCEPTED" />
                            <TreeNode value="WITHDRAWAL" title={allStrings.withdrawal} key="WITHDRAWAL" />
                            <TreeNode value="COMPLETED" title={allStrings.completed} key="COMPLETED" />
                            <TreeNode value="CLOSED" title={allStrings.closed} key="CLOSED" />
                            <TreeNode value="REFUCED" title={allStrings.refused} key="REFUCED" />
                        </TreeSelect>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol xl="12" lg="12" md="12" sm="12">
                      <div class="md-form">
                      <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.Search}</label>
                      <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',fontWeight:'300'}} type="text" id="form605"  class="form-control"
                        placeholder={allStrings.SearchByName}
                        onChange={(e)=>{
                          this.setState({search:e.target.value})
                        }}  
                      />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <Button style={{background: '#00B483',color: '#fff',width: '100%',margin: '32px auto'}} 
                    onClick={()=>{this.getContactRequest(1);this.setState({buildCsv:false})}}><SearchOutlined /> {allStrings.Search}</Button>

                </div>
              </div>
            }
            title={allStrings.contactRequests}
            enableEdit={false}
            icon='chat'
            data={this.state.contactRequest}
            data2={this.state.contactRequest2}
            tableColumns={["#",allStrings.sender,allStrings.reciever,allStrings.status,allStrings.createdAt,allStrings.lastMsgDate,allStrings.action]}
            loading={this.state.loading}
            deleteUser={(id)=>{this.delete(id)}}
            path='/contactRequestsInfo'
            actionIndex={5}
            dataCount={this.state.count}
            />
            {this.paginationButtons()}
            
            </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer >
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(contactRequest)
);

