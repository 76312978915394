import React from 'react';
import './contactRequest info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { message } from "antd";
class ContactRequestInfo extends React.Component {
    state = {
        actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
        id:this.props.location.state.data.id,
        contactOn:this.props.location.state.data.contactOn,
        status:this.props.location.state.data.status,
        ads:this.props.location.state.data.ads?this.props.location.state.data.ads:null,
        adsRequest:this.props.location.state.data.adsRequest?this.props.location.state.data.adsRequest:null,
        sender:this.props.location.state.data.sender?this.props.location.state.data.sender:null,
        reciever:this.props.location.state.data.reciever?this.props.location.state.data.reciever:null,
        marketer:this.props.location.state.data.marketer?this.props.location.state.data.marketer:null,
       
    };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    
    delete = () => {
        let l = message.loading('Wait..', 2.5)
        axios.delete(`${BASE_END_POINT}contactRequest/${this.props.location.state.data.id}`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization':`Bearer ${this.props.currentUser.token}`,
           'Accept-Language':this.props.isRTL?'ar':'en'
         },
        })
        .then(response=>{
          console.log("Transaction DELETED")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
   

    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.updateTransaction()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('CONTACT-REQUEST')) return <Redirect to='/Home' />

  return (
    <div className="App">
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12" >
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#00B483'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>chat</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.edit}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                    {this.state.actionType==="VIEW"&&
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form8" className="form-control" onChange={this.changeHandler}  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.contactOn}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false}   style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.contactOn==="ADS"?allStrings.adveristment:allStrings.adveristmentRequest} type="text" id="form645" name="contactOn" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.status}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.status==="NEW"?allStrings.new:this.state.status==="CLOSED"?allStrings.closed:this.state.status==="COMPLETED"?allStrings.completed:this.state.status==="REFUSED"?allStrings.refused:this.state.status==="WITHDRAWAL"?allStrings.withdrawal:allStrings.onProgress} type="text" id="form56" name="status" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    {this.state.ads&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="10" xs="10">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.theAdveristment}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.ads?this.state.ads?this.state.ads:'مشاهده الاعلان':''} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="2" xs="2">
                                    
                                        <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                            <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                            onClick={()=>{this.props.history.push('adsInfo',{data:{id:this.state.ads}}) }}>
                                            remove_red_eye
                                            </span>
                                            <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                            onClick={()=>{this.props.history.push('adsInfo',{data:{id:this.state.ads}}) }}>
                                            {allStrings.show}
                                            </span> 
                                        </p>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.adsRequest&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="10" xs="10">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.adveristmentRequest}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.adsRequest?this.state.adsRequest:'مشاهده الطلب'} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="2" xs="2">
                                    
                                        <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                            <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                            onClick={()=>{this.props.history.push('adsRequestInfo',{data:{id:this.state.adsRequest}}) }}>
                                            remove_red_eye
                                            </span>
                                            <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                            onClick={()=>{this.props.history.push('adsRequestInfo',{data:{id:this.state.adsRequest}}) }}>
                                            {allStrings.show}
                                            </span> 
                                        </p>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.sender&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="5" xs="5">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.sender}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.sender?this.state.sender.fullname:''} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="5" xs="5">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.phone}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.sender?this.state.sender.phone:''} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="2" xs="2">
                                    
                                        <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                            <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.sender}) }}>
                                            remove_red_eye
                                            </span>
                                            <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.sender}) }}>
                                            {allStrings.show}
                                            </span> 
                                        </p>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.reciever &&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="5" xs="5">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.reciever}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.reciever?this.state.reciever.fullname:''} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="5" xs="5">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.phone}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.reciever?this.state.reciever.phone:''} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="2" xs="2">
                                    
                                        <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                            <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.reciever}) }}>
                                            remove_red_eye
                                            </span>
                                            <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.reciever}) }}>
                                            {allStrings.show}
                                            </span> 
                                        </p>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.marketer&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="5" xs="5">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.marketer}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.marketer?this.state.marketer.fullname:''} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="5" xs="5">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.phone}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.marketer?this.state.marketer.phone:''} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="2" xs="2">
                                    
                                        <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                            <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.marketer}) }}>
                                            remove_red_eye
                                            </span>
                                            <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.marketer}) }}>
                                            {allStrings.show}
                                            </span> 
                                        </p>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                 
                                    <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}
                                        onClick={()=>{this.props.history.push("chat",{
                                            contactRequest:this.state.id,
                                            sender:this.state.sender.id,
                                            reciever:this.state.reciever.id}) 
                                        }}>
                                        <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons">
                                            remove_red_eye
                                        </span>
                                        <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}>
                                            عرض الشات
                                        </span> 
                                    </p>
                                 
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                    
                                    {this.state.actionType==="VIEW"&&
                                        <MDBBtn onClick={()=>{this.delete()}} rounded color="danger" className="btn-block z-depth-1a">
                                            <MDBIcon icon="trash-alt" className="mr-2" />
                                            {allStrings.remove}
                                        </MDBBtn>
                                    }
                                        
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol> 
            </MDBRow>
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(ContactRequestInfo)
  );
