import * as types from "../actions/types"

const initialState = {
    currentUser: null,
    
    loading: false,
    errorText: null,
    userType:"CLIENT",
    userToken: null,
    currentLocation: [0,0],

}

const AuthReducer = (state = initialState, action) => {

    switch (action.type) {   
        case types.LOGIN_REQUEST:
            return { ...state, errorText: null, loading: true }
        case types.CURRENT_USER:
            return { ...state, currentUser: action.payload }
        case types.LOGIN_SUCCESS:
            return { ...state, currentUser: action.payload,  loading: false };
        case types.USER_TOKEN:
            return {...state,userToken:action.payload}    
        case types.LOGOUT:
             return state

        default:
            return state;
    }

}

export default AuthReducer;

