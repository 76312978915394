/* eslint-disable eqeqeq */
import React from 'react';
import './send marketing notifs.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import Button from '@material-ui/core/Button';
import Skeleton from 'react-loading-skeleton';
import Pagination from '@material-ui/lab/Pagination';
import { message ,Empty,TreeSelect} from "antd";
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
const { TreeNode } = TreeSelect;
const { SHOW_PARENT } = TreeSelect;
class SendMarketingNotif extends React.Component {
    state = {
        description:'',
        title:'',
        subjectId:null,
        adveristment:null,
        duration:'MONTH',
        category:null,
        categories:[],
        subCategory:null,
        subCategories:[],
        countries:[],
        cities:[],
        city:null,
        country:null,
        contractType:'',
        notifs:[],
        page:1,
        pages:1,
    };

    constructor(props){
        super(props)
        this.getCategories()
        this.getCountries()
        this.getNotifs()
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    getCountries = () => {
        axios.get(`${BASE_END_POINT}countries/withoutPagenation/get`)
        .then(response=>{
          console.log(response.data)
          this.setState({countries:response.data.countries})
        })
        .catch(error=>{
          console.log("ALL countries ERROR")
          console.log(error.response)
        })
    }
    getCities = (country) => {
        axios.get(`${BASE_END_POINT}countries/${country}/cities/withoutPagenation/get?country=${country}`)
          .then(response=>{
            console.log(response.data)
            this.setState({cities:response.data.cities})
          })
          .catch(error=>{
            console.log("ALL cities ERROR")
            console.log(error.response)
          })
    }
    getCategories = () => {
        axios.get(`${BASE_END_POINT}categories`)
        .then(response=>{
          console.log(response.data)
          this.setState({categories:response.data.categories})
        })
        .catch(error=>{
          console.log("ALL categories ERROR")
          console.log(error.response)
        })
    }
    getSubCategories = (category) => {
        axios.get(`${BASE_END_POINT}categories/${category}/sub-categories`)
        .then(response=>{
            console.log(response.data)
            this.setState({subCategories:response.data.categories})
        })
        .catch(error=>{
            console.log("ALL categories ERROR")
            console.log(error.response)
        })
    }
    SendMarketingNotif = () => {
        const {description,title,category,subCategory,adveristment,subjectId,duration,city,country,contractType} = this.state
        if(hasValue(subjectId)){
            let l = message.loading('Wait..', 2.5)
            const data={
                'description':description,
                'title':title,
                'subjectId':subjectId,
                'category':category,
                'subCategory':subCategory,
                'duration':duration
            }
            if(adveristment !== null && adveristment != ""){
                data.adveristment = adveristment
            }
            if(country !== null){
                data.country = country
            }
            if(city !== null){
                data.city = city
            }
            if(contractType !== null){
                data.contractType = contractType
            }
            console.log(data)
            axios.post(`${BASE_END_POINT}views/sendNotifs`,JSON.stringify(data),{
            headers: {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
                console.log("send notif ",response.data)
                l.then(() => message.success(allStrings.done, 2.5) )
                this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
    }
    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.SendMarketingNotif()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    getNotifs = (page,refresh) =>{
        this.setState({loading:true})
        axios.get(`${BASE_END_POINT}notif/marketingNotif/get?page=${page}`,{
          headers:{
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
          }
        })
        .then(response=>{
          console.log("Notifs   ",response.data)
          this.setState({
            loading:false,
            refresh:false,
            notifs:response.data.data,
            page:response.data.page,
            pages:response.data.pageCount,
          })
          console.log('nor',response.data.data[0])
          console.log('rev',Object.keys(response.data.data[0]).reverse())
        })
        .catch(error=>{
          console.log("error   ",error.response)
          console.log("error2   ",error)
          this.setState({loading:false,})
        })
    
    }
    paginationButtons = () =>(
        <CardActions style={{display: 'inline-flex'}}>
            <Button 
            onClick={()=>{
              if(this.state.page!==1){
                this.getNotifs(1)
                this.setState({page:1})
              }
            }}
            className="pagenation" >{allStrings.first}</Button>
    
            <Button 
            onClick={()=>{
             if(this.state.page>1){
                this.getNotifs(this.state.page-1)
                this.setState({page:this.state.page-1})
             }
            }}
            className="pagenation">{allStrings.prev}</Button>
            
            <Pagination
            onChange={(event,page)=>{
            console.log("page   ",page)
            if(page!==this.state.page){
              this.getNotifs(page)
              this.setState({page:page})
            }
            
            }} 
            defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`#00B483 !important`}} />
            
            <Button 
            onClick={()=>{
              if(this.state.page<this.state.pages){
                this.getNotifs(this.state.page+1)
                this.setState({page:this.state.page+1})
              }
            }}
            className="pagenation">{allStrings.next}</Button>
    
            <Button 
             onClick={()=>{
              if(this.state.page!==this.state.pages){
                this.getNotifs(this.state.pages)
                this.setState({page:this.state.pages})
              }
            }}
            className="pagenation">{allStrings.last}</Button>
    
        </CardActions>
    )
   
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('FEATURES')) return <Redirect to='/Home' />

  return (
    <div className="App">
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#00B483'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>circle_notifications</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{allStrings.sendMarketingNotif}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.duration}</label>
                                            <TreeSelect 
                                                virtual={false}
                                                showSearch={false} 
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.duration}
                                                onChange={(value) => {
                                                    console.log(value)
                                                    this.setState({duration:value});
                                                }} 
                                            
                                            >
                                                <TreeNode value="WEEK" title={allStrings.week} key="WEEK" />
                                                <TreeNode value="MONTH" title={allStrings.month} key="MONTH" />
                                                
                                            </TreeSelect>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form645" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.subjectId}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.subjectId} type="text" id="form645" name="subjectId" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6" >
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.category}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false}
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.category}
                                                value={this.state.category}
                                                showCheckedStrategy= {SHOW_PARENT}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({category:value});
                                                this.getSubCategories(value)
                                                }} 
                                            
                                            >
                                                {this.state.categories.map(val=>(
                                                    <TreeNode value={val.id} title={this.props.isRTL?val.categoryName_ar:val.categoryName_en} key={val.id} />
                                                ))}
                                            </TreeSelect>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.subCategory}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={true}
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.subCategory}
                                                value={this.state.subCategory}
                                                showCheckedStrategy= {SHOW_PARENT}
                                                onChange={(value) => {
                                                    console.log(value)
                                                    this.setState({subCategory:value});
                                                }} 
                                            
                                            >
                                                {this.state.subCategories.map(val=>(
                                                    <TreeNode value={val.id} title={this.props.isRTL?val.categoryName_ar:val.categoryName_en} key={val.id} />
                                                ))}
                                            </TreeSelect>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6" sm="12">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.country}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false}
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.country}
                                                value={this.state.country}
                                                showCheckedStrategy= {SHOW_PARENT}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({country:value});
                                                this.getCities(value)
                                                }} 
                                            
                                            >
                                                {this.state.countries.map(val=>(
                                                    <TreeNode value={val.id} title={this.props.isRTL?val.countryName_ar:val.countryName_en} key={val.id} />
                                                ))}
                                            </TreeSelect>
                                        </MDBCol>
                                        <MDBCol md="6" sm="12">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.city}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={true}
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.city}
                                                value={this.state.city}
                                                showCheckedStrategy= {SHOW_PARENT}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({city:value});
                                                this.getAreas(value)
                                                }} 
                                            
                                            >
                                                {this.state.cities.map(val=>(
                                                    <TreeNode value={val.id} title={this.props.isRTL?val.cityName_ar:val.cityName_en} key={val.id} />
                                                ))}
                                            </TreeSelect>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.contractType}</label>
                                            <TreeSelect 
                                                virtual={false}
                                                showSearch={false} 
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.contractType}
                                                onChange={(value) => {
                                                    console.log(value)
                                                    this.setState({contractType:value});
                                                }} 
                                            
                                            >
                                                <TreeNode value="SALE" title={allStrings.sale} key="SALE" />
                                                <TreeNode value="RENT" title={allStrings.rent} key="RENT" />
                                                
                                            </TreeSelect>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form645" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.theAdveristment}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.adveristment} type="text" id="form645" name="adveristment" className="form-control" onChange={this.changeHandler} />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.title}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.title} type="text" id="form64" name="title" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form645" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.description}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.description} type="text" id="form645" name="description" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                       <MDBBtn
                                        type="submit" style={{background:'#00B483',color:'#fff'}}
                                        rounded
                                        className="btn-block z-depth-1a"
                                        >
                                        <MDBIcon icon="plus" className="mr-2" />
                                            {allStrings.sendMarketingNotif}          
                                        </MDBBtn>
                                       
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol md="12">
                    <div className="infoTable">
                        <div className="topInfoTable" style={{ background: "#00B483" }}>
                            <h4>
                            <span className="material-icons" style={{color:'white',fontSize:23}}>circle_notifications</span>{ allStrings.notifs}
                            </h4>
                        </div>
                        <div className="tableDate">
                        <div className="table-responsive">
                            <table className="table table-striped">
                            <thead>
                                {this.props.isRTL?
                                <tr style={{textAlign:'center'}}>
                                    <th>{allStrings.more}</th>
                                    <th>{allStrings.usersCount}</th>
                                    <th>{allStrings.description}</th>
                                    <th>{allStrings.title}</th>
                                    <th scope="col">#</th>
                                </tr>
                                :
                                <tr style={{textAlign:'center'}}>
                                    <th scope="col">#</th>
                                    <th>{allStrings.title}</th>
                                    <th>{allStrings.description}</th>
                                    <th>{allStrings.usersCount}</th>
                                    <th>{allStrings.more}</th>
                                </tr>
                                    
                                }
                                
                            </thead>
                            <tbody>
                            {this.state.loading?
                                <tr style={{height: '200px'}}>
                                    <th colSpan={this.state.tableType === "collected" ?"5":"4"} className="noData">
                                    <Skeleton height={400}/> 
                                    </th>
                                </tr>
                                :
                                this.state.notifs.length > 0 ?
                                this.state.notifs.map(val =>(
                                    this.props.isRTL?
                                    <tr  style={{textAlign:'center'}}>
                                        <td onClick={()=>{this.props.history.push('/marketingNotifInfo',{data:val}) }} >
                                            <span style={{margin: "4px",cursor:'pointer'}}>
                                                <Tooltip title={allStrings.info} placement="top">
                                                    <Info style={{color:'#33d633'}}/>
                                                </Tooltip>
                                            </span>
                                        </td>
                                        <td>{val.usersCount?val.usersCount:'0'}</td>
                                        <td>{val.description}</td>
                                        <td>{val.title}</td>
                                        <td >{val.id}</td>
                                    </tr>
                                    :
                                    <tr  style={{textAlign:'center'}}>
                                        
                                        <td >{val.id}</td>
                                        <td>{val.title}</td>
                                        <td>{val.description}</td>
                                        <td>{val.usersCount?val.usersCount:'0'}</td>
                                        <td onClick={()=>{this.props.history.push('/marketingNotifInfo',{data:val}) }} >
                                            <span style={{margin: "4px",cursor:'pointer'}}>
                                                <Tooltip title={allStrings.info} placement="top">
                                                    <Info style={{color:'#33d633'}}/>
                                                </Tooltip>
                                            </span>
                                        </td>
                                    </tr>
                               
                                 ))
               
                                 :
                                 <tr style={{height: '200px'}}>
                                     <th colSpan="4" className="noData">
                                     <Empty description={allStrings.noData} style={{margin:'0rem'}}/>
                                     </th>
                                 </tr>
                               }
                              
                               
                                
                            </tbody>
                            </table>
                            {this.state.notifs.length > 0 &&
                                <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
                                    {this.paginationButtons()}
                                </div>
                            }
                        </div>
                        </div>
                    </div>
                </MDBCol>
            </MDBRow>
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(SendMarketingNotif)
  );