import React from 'react';
import './about.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,Spin } from "antd";
import { UploadOutlined } from '@ant-design/icons';

class About extends React.Component {
    state = {
        aboutUs_en:'',
        aboutUs_ar:'',
        email:'',
        address:'',
        phone:'',
        shareTitle_en:'',
        shareTitle_ar:'',
        shareDescription_en:'',
        shareDescription_ar:'',
        latitude:'',
        longitude:'',
        facebook:'',
        twitter:'',
        snapChat:'',
        youtube:'',
        instagram:'',
        androidAppLink:'',
        iosAppLink:'',
        about:[],
        imgName:'',
        loading:true,
        img:null,
        
    };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        this.getAbout()
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    getAbout = () =>{
        this.setState({loading:true})
        axios.get(`${BASE_END_POINT}about`,{
          headers:{
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
          }
        })
        .then(response=>{
          console.log("about   ",response.data.about)
          this.setState({
            loading:false,
            about:response.data.about,
            img:response.data.logo,
            aboutUs_ar:response.data.about.aboutUs_ar,
            aboutUs_en:response.data.about.aboutUs_en,
            email:response.data.about.email,
            phone:response.data.about.phone,
            address:response.data.about.address,
            shareTitle_en:response.data.about.shareTitle_en,
            shareTitle_ar:response.data.about.shareTitle_ar,
            shareDescription_en:response.data.about.shareDescription_en,
            shareDescription_ar:response.data.about.shareDescription_ar,
            latitude:response.data.about.location.coordinates[1],
            longitude:response.data.about.location.coordinates[0],
            facebook:response.data.about.facebook,
            twitter:response.data.about.twitter,
            snapChat:response.data.about.snapChat,
            youtube:response.data.about.youtube,
            instagram:response.data.about.instagram,
            androidAppLink:response.data.about.androidAppLink,
            iosAppLink:response.data.about.iosAppLink,
          })
        })
        .catch(error=>{
          console.log("error   ",error.response)
          console.log("error2   ",error)
          this.setState({loading:false,})
        })
      }
    update = () => {
        const {aboutUs_en,aboutUs_ar,email,address,phone,shareDescription_ar,shareDescription_en,
        shareTitle_ar,shareTitle_en,latitude,longitude,facebook,twitter,snapChat,youtube,
        instagram,androidAppLink,iosAppLink,img} = this.state
        if(hasValue(aboutUs_ar)&&hasValue(aboutUs_en)&&hasValue(email)
            &&hasValue(address)&&hasValue(phone) &&hasValue(shareTitle_ar)&&hasValue(shareTitle_en)
            &&hasValue(shareDescription_en)&&hasValue(shareDescription_ar) 
            &&hasValue(facebook)&&hasValue(twitter)
            &&hasValue(snapChat)&&hasValue(youtube) &&hasValue(instagram)&&hasValue(androidAppLink)
            &&hasValue(iosAppLink)){
            let l = message.loading('Wait..', 2.5)
            let data = new FormData()
            console.log([longitude,latitude])
            data.append('aboutUs_en',aboutUs_en)
            data.append('aboutUs_ar',aboutUs_ar)
            data.append('address',address)
            data.append('phone',phone)
            data.append('email',email)
            data.append('shareTitle_en',shareTitle_en)
            data.append('shareTitle_ar',shareTitle_ar)
            data.append('shareDescription_en',shareDescription_en)
            data.append('shareDescription_ar',shareDescription_ar)
            data.append('facebook',facebook)
            data.append('location',JSON.stringify([longitude,latitude]))
            data.append('twitter',twitter)
            data.append('snapChat',snapChat)
            data.append('youtube',youtube)
            data.append('instagram',instagram)
            data.append('androidAppLink',androidAppLink)
            data.append('iosAppLink',iosAppLink)
           
            if(img != null){
              data.append('logo',img); 
            }
            console.log(Array.from(data))
            axios.put(`${BASE_END_POINT}about/${this.state.about.id}`,data,{
            headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            console.log("about UPDATED  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
    }
    fileChangedHandler = event => {
      this.setState({
        img: event.target.files[0],
        imgName: event.target.files[0].name,
        imgType:"data"
      })
    }
    removeFile = () => {
      this.setState({
          img:null,
          imgName:'',
          imgType:"url"
      });
    }
   

    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.update()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
   
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('ABOUT')) return <Redirect to='/Home' />

  return (
    <div className="App">
      <Spin spinning={this.state.loading} tip={allStrings.loading} size="large" style={{color:'#00B483'}}>
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#00B483'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>info</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{allStrings.about}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                              <MDBCol md="12">
                              <form
                              className="needs-validation"
                              onSubmit={this.submitHandler}
                              noValidate
                              >
                                  
                                  <div className="grey-text">
                                  <MDBRow>
                                      <MDBCol md="6">
                                          <div className="md-form">
                                          <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.phone}</label>
                                          <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.about.phone?this.state.about.phone:""} type="text" id="form645" name="phone" className="form-control" onChange={this.changeHandler} required  validate/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                      </MDBCol>
                                      <MDBCol md="6">
                                          <div className="md-form">
                                          <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.email}</label>
                                          <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^a-zA-Z0-9$@$!%*?/&#^-_. +]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.about.email?this.state.about.email:""} type="text" id="form56" name="email" className="form-control" onChange={this.changeHandler} required validate/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                      </MDBCol>
                                  </MDBRow>
                                  <MDBRow>
                                    <MDBCol md="12">
                                      <div className="md-form">
                                        <label htmlFor="form20" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.address}</label>
                                        <br></br>
                                        <textarea name="address"  style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form20" className="form-control" required validate onChange={this.changeHandler} defaultValue={this.state.about.address?this.state.about.address:""} />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                  <MDBRow>
                                    <MDBCol md="12">
                                      <div className="md-form">
                                        <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.aboutUs_en}</label>
                                        <br></br>
                                        <textarea onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') }  name="aboutUs_en"  style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" className="form-control" required validate  onChange={this.changeHandler} defaultValue={this.state.about.aboutUs_en?this.state.about.aboutUs_en:""} />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                  <MDBRow>
                                    <MDBCol md="12">
                                      <div className="md-form">
                                        <label htmlFor="form90" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.aboutUs_ar}</label>
                                        <br></br>
                                        <textarea onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } name="aboutUs_ar"  style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form90" className="form-control" required validate onChange={this.changeHandler}  defaultValue={this.state.about.aboutUs_ar?this.state.about.aboutUs_ar:""} />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                 
                                  <MDBRow>
                                    <MDBCol md="12">
                                      <div className="md-form">
                                        <label htmlFor="form3" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.shareDescription_en}</label>
                                        <br></br>
                                        <textarea onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') }  name="shareDescription_en"  style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form3" className="form-control" required validate  onChange={this.changeHandler} defaultValue={this.state.about.shareDescription_en?this.state.about.shareDescription_en:""} />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                  <MDBRow>
                                    <MDBCol md="12">
                                      <div className="md-form">
                                        <label htmlFor="form4" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.shareDescription_ar}</label>
                                        <br></br>
                                        <textarea onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } name="shareDescription_ar"  style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form4" className="form-control" required validate onChange={this.changeHandler}  defaultValue={this.state.about.shareDescription_ar?this.state.about.shareDescription_ar:""} />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                  <MDBRow>
                                    <MDBCol md="6">
                                      <div className="md-form">
                                        <label htmlFor="form1" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.shareTitle_en}</label>
                                        <br></br>
                                        <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } name="shareTitle_en"  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form1" className="form-control" required validate onChange={this.changeHandler}  defaultValue={this.state.about.shareTitle_en?this.state.about.shareTitle_en:""} />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                    <MDBCol md="6">
                                      <div className="md-form">
                                        <label htmlFor="form2" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.shareTitle_ar}</label>
                                        <br></br>
                                        <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } name="shareTitle_ar"  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form2" className="form-control" required validate onChange={this.changeHandler}  defaultValue={this.state.about.shareTitle_ar?this.state.about.shareTitle_ar:""} />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                  <MDBRow>
                                      <MDBCol md="6">
                                          <div className="md-form">
                                          <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.latitude}</label>
                                          <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.about.latitude?this.state.about.latitude:0}  type="number" step="0.01"  id="form65" name="latitude" className="form-control" onChange={this.changeHandler} required  validate/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                      </MDBCol>
                                      <MDBCol md="6">
                                          <div className="md-form">
                                          <label htmlFor="form64" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.longitude}</label>
                                          <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.about.longitude?this.state.about.longitude:0} type="number" step="0.01"  id="form64" name="longitude" className="form-control" onChange={this.changeHandler} required  validate/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                      </MDBCol>
                                  </MDBRow>
                                 
                                  <MDBRow>
                                    
                                    <MDBCol md="6">
                                      <div className="md-form">
                                        <label htmlFor="form5" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.facebook}</label>
                                        <br></br>
                                        <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^a-zA-Z0-9$@$!%*?&/:#^-_. +]/g,'') } name="facebook"  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form5" className="form-control" required validate onChange={this.changeHandler}  defaultValue={this.state.about.facebook?this.state.about.facebook:""} />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                    <MDBCol md="6">
                                      <div className="md-form">
                                        <label htmlFor="form6" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.twitter}</label>
                                        <br></br>
                                        <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^a-zA-Z0-9$@$!%*?&/:#^-_. +]/g,'') } name="twitter"  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form6" className="form-control" required validate onChange={this.changeHandler}  defaultValue={this.state.about.twitter?this.state.about.twitter:""} />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                  <MDBRow>
                                    <MDBCol md="6">
                                      <div className="md-form">
                                        <label htmlFor="form7" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.snapChat}</label>
                                        <br></br>
                                        <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^a-zA-Z0-9$@$!%*?&/:#^-_. +]/g,'') } name="snapChat"  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form7" className="form-control" required validate onChange={this.changeHandler}  defaultValue={this.state.about.snapChat?this.state.about.snapChat:""} />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                    <MDBCol md="6">
                                      <div className="md-form">
                                        <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.youtube}</label>
                                        <br></br>
                                        <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^a-zA-Z0-9$@$!%*?&/:#^-_. +]/g,'') } name="youtube"  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form8" className="form-control" required validate onChange={this.changeHandler}  defaultValue={this.state.about.youtube?this.state.about.youtube:""} />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                  <MDBRow>
                                    <MDBCol md="6">
                                      <div className="md-form">
                                        <label htmlFor="form9" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.instagram}</label>
                                        <br></br>
                                        <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^a-zA-Z0-9$@$!%*?&/:=#^-_. +]/g,'') } name="instagram"  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form9" className="form-control" required validate onChange={this.changeHandler}  defaultValue={this.state.about.instagram?this.state.about.instagram:""} />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                    <MDBCol md="6">
                                      <div className="md-form">
                                        <label htmlFor="form10" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.androidAppLink}</label>
                                        <br></br>
                                        <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^a-zA-Z0-9$@$!%*?&/:=#^-_. +]/g,'') } name="androidAppLink"  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form10" className="form-control" required validate onChange={this.changeHandler}  defaultValue={this.state.about.androidAppLink?this.state.about.androidAppLink:""} />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                  <MDBRow>
                                    <MDBCol md="12">
                                      <div className="md-form">
                                        <label htmlFor="form11" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.iosAppLink}</label>
                                        <br></br>
                                        <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^a-zA-Z0-9$@$!%*?&/=:#^-_. +]/g,'') } name="iosAppLink"  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form11" className="form-control" required validate onChange={this.changeHandler}  defaultValue={this.state.about.iosAppLink?this.state.about.iosAppLink:""} />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                  <MDBRow>
                                        <MDBCol md="12">
                                        <label htmlFor="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.logo}</label>

                                            <div className="fileUpload2"> 
                                                <input  key={this.state.img} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                            </div>
                                            <div className="preview">
                                            {this.state.img&&
                                            <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                <img alt="" src={this.state.imgType==="data"?URL.createObjectURL(this.state.img):this.state.img} className="previewImg2"/>
                                                <span className="imgName">{this.state.imgName}</span>
                                                <MDBIcon icon="trash" className="mr-2 removeImg3"
                                                onClick={() => this.removeFile()}  ></MDBIcon>
                                            </div>
                                            }
                                            </div>
                                            
                                        </MDBCol>
                                      
                                  </MDBRow>
                                  </div>
                                  <br></br>
                                  <br></br>
                                  <div className="text-center">
                                      <MDBBtn
                                      type="submit" style={{background:'#00B483'}}
                                      rounded
                                      className="btn-block z-depth-1a"
                                      >
                                      <MDBIcon icon="edit" className="mr-2" />
                                          {allStrings.edit}          
                                      </MDBBtn>
                                      
                                  </div>
                              </form>
                              </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
      </MDBContainer>
      </Spin>
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(About)
  );