
import React from 'react';

import { Route } from 'react-router-dom';
import Layout from './Layout/layout';

import { connect } from 'react-redux';
import {withRouter,Redirect} from 'react-router-dom'
import {setUser} from './redux/actions/AuthActions'

const App = ({ component: Component, rest,currentUser,isRTL, menu}) => {
  console.log("app",isRTL)
  console.log("app collapsed",menu)
  let marginLeft
  let marginRight
  if(menu === true){
    marginLeft=80
    /*if(isRTL){
      marginLeft='auto'
      marginRight=80
    }else{
      marginLeft=80
      marginRight='auto'
    }*/
    
  }else{
    marginLeft=200
    /*if(isRTL){
      marginLeft='auto'
      marginRight=200
    }else{
      marginLeft=200
      marginRight='auto'
    }*/
  }
  if (currentUser == null) return <Redirect to='/Login' /> 
  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <Layout marginLeft={marginLeft} marginRight={marginRight} collapsed={menu}>
          
          <Component {...routeProps} />
        </Layout>
      )}
    />
  );
};

const mapToStateProps = state => ({
  currentUser: state.auth.currentUser,
  isRTL: state.lang.isRTL,
  menu:state.menu.menu
})

const mapDispatchToProps = {
  setUser,
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(App)
);

