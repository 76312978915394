import React from 'react';
import './subCategory info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,TreeSelect } from "antd";
const { TreeNode } = TreeSelect;
class SubCategoryInfo extends React.Component {
    state = {
      actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
        id:this.props.location.state.data.id,
        category:this.props.location.state.data?this.props.location.state.data:[],
        categoryName_en:this.props.location.state.data.categoryName_en,
        categoryName_ar:this.props.location.state.data.categoryName_ar,
        priority:this.props.location.state.data.priority,
        redfKey:this.props.location.state.data.redfKey,
        categories:[],
        loading:true,
        refresh:false,
        page:1,
        pages:1,
        modal: false,
      };

      constructor(props){
        super(props)
        window.scrollTo(0,0)
        if(this.props.isRTL){
          allStrings.setLanguage('ar')
        }else{
          allStrings.setLanguage('en')
        } 
      }
      componentDidMount(){
        if(this.props.isRTL){
          allStrings.setLanguage('ar')
        }else{
          allStrings.setLanguage('en')
        }
      }
      updateCategory = () => {
        const {categoryName_ar,categoryName_en,priority,redfKey} = this.state
    
        if(hasValue(categoryName_ar)&&hasValue(categoryName_en)){
            let l = message.loading('Wait..', 2.5)
            let data = new FormData()
            data.append('categoryName_ar',categoryName_ar)
            data.append('categoryName_en',categoryName_en)
            data.append('priority',priority)
            data.append('redfKey',redfKey)
            axios.put(`${BASE_END_POINT}categories/${this.props.location.state.data.id}`,data,{
            headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            console.log("USER UPDATED  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
     }

     deleteCategory = () => {
        let l = message.loading('Wait..', 2.5)
        axios.delete(`${BASE_END_POINT}categories/${this.props.location.state.data.id}`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization':`Bearer ${this.props.currentUser.token}`,
           'Accept-Language':this.props.isRTL?'ar':'en'
         },
        })
        .then(response=>{
          console.log("USER DELETED")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
     }
     

    
      submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
        this.updateCategory()
      };
    
      changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
      };

     
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('CATEGORIES')) return <Redirect to='/Home' />

  return (
    <div className="App">
        
        <MDBContainer>
            <MDBRow>
                <MDBCol xl ="12" lg = "12" md="12" sm="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#00B483'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>category</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{this.state.actionType==="VIEW"?allStrings.subCategory:allStrings.edit}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                      {this.state.actionType==="VIEW"&&
                                        <MDBRow>
                                            <MDBCol md="12">
                                                <div className="md-form">
                                                    <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                                    <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.id} type="text" id="form8" className="form-control" onChange={this.changeHandler} required validate/>
                                                    <div class="valid-feedback">{allStrings.correct}</div>
                                                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                      }
                                        <MDBRow>
                                            <MDBCol md="6">
                                              <div className="md-form">
                                              <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.priority}</label>
                                              <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.priority} type="number"   id="form65" name="priority" className="form-control" onChange={this.changeHandler}  validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                            </MDBCol>
                                            <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.redfCategory}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false} 
                                                value= {this.state.redfKey}
                                                disabled={this.state.actionType==="VIEW"?true:false} 
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.redfCategory}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({redfKey:value});
                                                }} 
                                            
                                            >
                                              
                                                <TreeNode value="Villa" title={allStrings.villa} key="Villa" />
                                                <TreeNode value="Apartment" title={allStrings.apartment} key="Apartment" />
                                                <TreeNode value="Townhouse" title={allStrings.townhouse} key="Townhouse" />
                                                <TreeNode value="Land" title={allStrings.lands} key="Land" /> 
                                                
                                            </TreeSelect>
                                          </MDBCol>
                                        </MDBRow>
                                       
                                        <MDBRow>
                                            <MDBCol md="6">
                                                <div className="md-form">
                                                    <label htmlFor="form3" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                                    <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.categoryName_en} type="text" id="form3" name="categoryName_en" className="form-control" onChange={this.changeHandler} required validate/>
                                                    <div class="valid-feedback">{allStrings.correct}</div>
                                                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>

                                            <MDBCol md="6">
                                                <div className="md-form">
                                                    <label htmlFor="form3" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                                    <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.categoryName_ar} type="text" id="form2" name="categoryName_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                                    <div class="valid-feedback">{allStrings.correct}</div>
                                                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                      
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                      {this.state.actionType==="EDIT"&&
                                      <MDBBtn
                                        type="submit" style={{background:'#00B483',color:'#fff'}}
                                        rounded
                                        className="btn-block z-depth-1a"
                                      >
                                            <MDBIcon icon="edit" className="mr-2" />
                                            {allStrings.updateCategory}        
                                      </MDBBtn>
                                      }{this.state.actionType==="VIEW"&&
                                      <MDBBtn onClick={()=>{this.deleteCategory()}} rounded color="danger" className="btn-block z-depth-1a">
                                          <MDBIcon icon="trash-alt" className="mr-2" />
                                        {allStrings.remove}
                                      </MDBBtn>
                                      }
                                       
                                        
                                    </div>
                                </form>
                                </MDBCol>
                                
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol> 
            </MDBRow>
            
        </MDBContainer>
      
    </div> 
  )
  }
}



const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(SubCategoryInfo)
  );