import React from "react";
import "./login.css";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
} from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import {hasValue} from '../../validations/validations'
import {message} from 'antd';
import {NavLink} from 'react-router-dom';

class Login extends React.Component {
  state = {
    email: "",
    password: "",
  };

  constructor(props){
    super(props)
    console.log("isr   ",this.props.history)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    window.scrollTo(0,0)
  }

  login = () =>{
      const {email,password} = this.state
      if(hasValue(email)&&hasValue(password)){

        let l = message.loading('Wait..', 2.5)
        console.log("HAS VAL")
        const data={
            email:email,
            password:password
        }
        axios.post(`${BASE_END_POINT}loginByMail`,JSON.stringify(data),{
            headers:{
                "Content-Type":"application/json",
                'Accept-Language':this.props.isRTL?'ar':'en'
            }
        })
        .then(response=>{ 
          console.log("type",response.data.user.type)
            if(response.data.user.type ==='ADMIN' || response.data.user.type ==="SUB-ADMIN"){
                l.then(() => message.success('Welcome', 2.5) )
                this.props.setUser(response.data)
                localStorage.setItem('@USERSAAEI',JSON.stringify(response.data))
                localStorage.setItem("@LANG",'ar')
                if(response.data.user.type !=="ADMIN"){
                  if(response.data.user.permission.pages.includes('DASHBOARD')){
                    this.props.history.replace('/Home') 
                  }else{
                    if(response.data.user.permission.pages.includes('CATEGORIES')){
                      this.props.history.replace('/Categories') 
                    }
                    if(response.data.user.permission.pages.includes('Ads')){
                      this.props.history.replace('/Ads') 
                    }
                    if(response.data.user.permission.pages.includes('REQUESTS')){
                      this.props.history.replace('/requests') 
                    }
                    if(response.data.user.permission.pages.includes('ANONCEMENTS')){
                      this.props.history.replace('/anoncements') 
                    }
                    if(response.data.user.permission.pages.includes('FEATURES')){
                      this.props.history.replace('/Features') 
                    }
                    if(response.data.user.permission.pages.includes('PACKAGES')){
                      this.props.history.replace('/Packages') 
                    }
                    if(response.data.user.permission.pages.includes('TRANSACTIONS')){
                      this.props.history.replace('/Transactions') 
                    }
                    if(response.data.user.permission.pages.includes('COUNTRIES')){
                      this.props.history.replace('/Countries') 
                    }
                    if(response.data.user.permission.pages.includes('ABOUT')){
                      this.props.history.replace('/about') 
                    }
                    if(response.data.user.permission.pages.includes('SETTINGS')){
                      this.props.history.replace('/Setting') 
                    }
                    if(response.data.user.permission.pages.includes('PERMISSIONS')){
                      this.props.history.replace('/Permissions') 
                    }
                    if(response.data.user.permission.pages.includes('FAQ')){
                      this.props.history.replace('/Questions') 
                    }
                    if(response.data.user.permission.pages.includes('NOTIFS')){
                      this.props.history.replace('/SendNotifs') 
                    }
                    if(response.data.user.permission.pages.includes('CONTACT-US')){
                      this.props.history.replace('/ContactUs') 
                    }
                    if(response.data.user.permission.pages.includes('CLIENTS')){
                      this.props.history.replace('/Users') 
                    }
                    if(response.data.user.permission.pages.includes('OFFICES')){
                      this.props.history.replace('/Offices') 
                    }
                    if(response.data.user.permission.pages.includes('SUB-ADMIN')){
                      this.props.history.replace('/Supervisors') 
                    }
                  }
                }else{
                  this.props.history.replace('/Home') 
                }
                
            }else{
               l.then(() => message.error(allStrings.thisUserIsnotAdmin, 2.5))
            }
        })
        .catch(error=>{
            console.log("ERROR   ",error.response)
            
                console.log("auth")
                l.then(() => message.error(allStrings.userDataIncorrect, 2.5))
           
        })
      }
  }

  submitHandler = (event) => {
    event.preventDefault();
    console.log(event.target);
    event.target.className += " was-validated";
    this.login()
  };

  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <div className="login">
        <MDBContainer className="loginContainer">
          <MDBRow>
            <MDBCol  xl="6" lg="6" md="4" sm="12" className="noPadding">
              <div className="loginLeft">

              </div>
            </MDBCol>
            <MDBCol  xl="6" lg="6" md="8" sm="12">
              <div className="loginRight">
                <div className="logoDiv">
                  <img alt="" style={{
                      margin: 'auto',
                      display: 'block',
                      marginTop: '4rem'
                  }} width="120px" src={require('../../assets/images/logo.png')}></img>
                </div>
                <br></br>
                <div className="formContainer">
                <MDBContainer>
                      <MDBRow>
                        <MDBCol md="12">
                          <form
                            className="needs-validation"
                            onSubmit={this.submitHandler}
                            noValidate
                          >
                            
                            <div className="grey-text">
                              <MDBInput
                                name="email"
                                hint={allStrings.email}
                                style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}}
                                group
                                type="email"
                                onChange={this.changeHandler}
                                required
                                validate
                                error="wrong"
                                success="right"
                              />
                              <div class="valid-feedback">{allStrings.correct}</div>
                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                              <MDBInput
                                onChange={this.changeHandler}
                                name="password"
                                required
                                hint={allStrings.password}
                                style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}}
                                group
                                type="password"
                                validate
                              />
                              <div class="valid-feedback">{allStrings.correct}</div>
                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                            </div>
                            <NavLink to="/forgetPassword">
                              <p className="forgetP">{allStrings.forgetPassword}</p>
                            </NavLink>
                           
                            <div className="text-center">
                              <MDBBtn
                                type="submit"
                                style={{ backgroundColor: '#00B483 ',width:'170px' }}
                                rounded
                                className="btn z-depth-1a"
                              >
                                {allStrings.signin}
                              </MDBBtn>
                            </div>
                          </form>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = state => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  })
  
  const mapDispatchToProps = {
    setUser,
  }
  
  export default  withRouter(
    connect(mapToStateProps,mapDispatchToProps)(Login)
  );
  
