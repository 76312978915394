import React from "react";
import "./add user.css";
import {MDBContainer,MDBRow,MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import "react-rater/lib/react-rater.css";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter ,Redirect} from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import { hasValue } from "../../validations/validations";
import { message } from "antd";
import { TreeSelect ,Spin} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { TreeNode } = TreeSelect;
const { SHOW_PARENT } = TreeSelect;
class AddUser extends React.Component {
  
  state = {
    fullname: '',
    email:'',
    phone:'',
    password:'',
    country: null,
    countryCode:'',
    city: null,
    area:null,
    type:'USER',
    img: null,
    imgType:'data',
    countries:[],
    commercialFile:null,
    fileType:"data",
    filePreviewName:[],
    cities:[],
    categories:[],
    subCategories:[],
    areas:[],
    workArea:null,
    workCity:null,
    workCategory:null,
    workSubCategory:null,
    agencyType:'',
    imgName:'',
    permissions:[],
    permission:null,
    allCities:[],
    allAreas:[],
    allCategory:[],
    allSubCategory:[],
    fullDataAreas:[],
    fullDataSubCategory:[]
  };
 
  constructor(props){
    super(props)
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  }
  componentDidMount(){
    this.getcountries()
       
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }
  getCountry = (id) => {
    axios.get(`${BASE_END_POINT}countries/${id}`)
    .then(response=>{
      console.log(response.data)
      this.setState({countryCode:response.data.data.countryCode,loading:false})
    })
    .catch(error=>{
      console.log(" country ERROR")
      console.log(error.response)
    })
  }
  getPermissions = () => {
    axios.get(`${BASE_END_POINT}permission/withoutPagenation/get`)
    .then(response=>{
      console.log(response.data)
      this.setState({permissions:response.data.Permissions,loading:false})
    })
    .catch(error=>{
      console.log("ALL permissions ERROR")
      console.log(error.response)
    })
  }
  getcountries = () => {
    axios.get(`${BASE_END_POINT}countries/withoutPagenation/get`)
    .then(response=>{
      console.log(response.data)
      this.setState({countries:response.data.countries,loading:false})
    })
    .catch(error=>{
      console.log("ALL countries ERROR")
      console.log(error.response)
    })
  }
  getCities = (country) => {
    console.log(country)
    axios.get(`${BASE_END_POINT}countries/1/cities/withoutPagenation/get?country=${country}`)
    .then(response=>{
        console.log('cities  ',response.data)
        this.setState({cities:response.data.cities})
    })
    .catch(error=>{
        console.log('cities ERROR  ',error)
        console.log('cities ERROR  ',error.respose)
    })
  }
  getAreas = (city) => {
    let arr = city
    console.log(arr)  
    let data =[]
    let allAreas =[]
      for(let i=0 ; i<= arr.length-1 ; i++){
        axios.get(`${BASE_END_POINT}areas/${arr[i]}/withoutPagenation/get`)
        .then(response=>{
          
          let results = response.data.areas.filter(({ id: id1 }) => !data.some(({ id: id2 }) => id2 === id1));
          console.log("result",results[1])
          data.push(...results)
          response.data.areas.forEach(element => {
            allAreas.push(element.id)
          });
          this.setState({
            fullDataAreas:[...results,...this.state.fullDataAreas]
          })
        })
        .catch(error=>{
          console.log(' areas ERROR  ',error)
          console.log('areas ERROR  ',error.respose)
        })
        this.setState({areas:data,fullDataAreas:[],allAreas:allAreas})
      }
  }
  getCategory = () => {
    axios.get(`${BASE_END_POINT}categories`)
    .then(response=>{
        console.log('categories  ',response.data)
        this.setState({categories:response.data.categories})
    })
    .catch(error=>{
        console.log('categories ERROR  ',error)
        console.log('categories ERROR  ',error.respose)
    })
  }
  getSubCategories = (category) => {
    let arr = category
    console.log(arr)  
    let data =[]
      for(let i=0 ; i<= arr.length-1 ; i++){
        axios.get(`${BASE_END_POINT}categories/${arr[i]}/sub-categories`)
        .then(response=>{
          
          let results = response.data.categories.filter(({ id: id1 }) => !data.some(({ id: id2 }) => id2 === id1));
          console.log(results)
          data.push(...results)
          this.setState({fullDataSubCategory:[...results,...this.state.fullDataSubCategory]})
        })
        .catch(error=>{
          console.log(' sub category ERROR  ',error)
          console.log('sub category  ',error.respose)
        })
        this.setState({subCategories:data,fullDataSubCategory:[]})
      }
  }
  add = () => {
    const {img,city,fullname,phone,email,password,country,countryCode,permission,
      workArea,workCity,workCategory,workSubCategory,agencyType,commercialFile,fileType,type} = this.state
    if(hasValue(email)&&country != null
      &&hasValue(fullname)&&hasValue(password)&&hasValue(phone)){
        let l = message.loading('Wait..', 2.5)
        let data = new FormData()
        data.append('country',country)
        data.append('email',email)
        if(type ==="USER"){
          data.append('agencyType','INDIVIDUAL')
        }
        if(type ==="OFFICE"){
          data.append('agencyType',agencyType)
        }
        if(type ==="SUB-ADMIN"){
          data.append('permission',permission)
        }
        if(countryCode ==="+20"){
          console.log("EG")
          let thePhone = countryCode+phone
          data.append('phone',thePhone.replace('+200','+20'))
        }
        if(countryCode ==="+966"){
          let thePhone = countryCode+phone
          data.append('phone',thePhone.replace('+9660','+966'))
        }else{
          data.append('phone',countryCode+phone)
        }
        
        data.append('fullname',fullname)
        data.append('password',password)
        data.append('type',type)
        if(city != null){
          data.append('city',city)
        }
        if(commercialFile != null && fileType){
          for(let i=0 ; i<= commercialFile.length-1 ; i++){
            data.append(`commercialFile`,commercialFile[i] )
          } 
        }
        if(workCity !=null)
          data.append('workCity',JSON.stringify(workCity))
        if(workArea !=null)
          data.append('workArea',JSON.stringify(workArea))
        if(workCategory !=null)
          data.append('workCategory',JSON.stringify(workCategory))
        if(workSubCategory !=null)
          data.append('workSubCategory',JSON.stringify(workSubCategory))
        if(img != null){
          data.append('img',img)
        }
        
        console.log(Array.from(data))
        axios.post(`${BASE_END_POINT}addUser`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Accept-Language': this.props.isRTL?'ar':'en',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.push('/users')
        })
        .catch(error=>{
            console.log(error.response)
            console.log(error.response.data.errors[0].msg)
            l.then(() => message.error(error.response.data.errors[0].msg, 2.5))
        })
    }
  }
  submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.add()
  };

  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };

  fileChangedHandler = event => {
    this.setState({
      img: event.target.files[0],
      imgName: event.target.files[0].name,
      imgType:"data"
    })
  }
  removeFile = () => {
    this.setState({ 
        img:null,
        imgName:'',
        imgType:"url"
    });
  }
  fileChangedHandler2 = event => {
    if(event.target.files[0]){
      let filePreviewName = []
      filePreviewName.push(event.target.files[0].name)
      let imgs = [];
      imgs.push(event.target.files[0])
      this.setState({
        commercialFile: imgs,
        fileType:"data",
        filePreviewName:filePreviewName
      })
    }
  }
  removeFile2 = () => {
    this.setState({
      commercialFile:null,
      fileType:"url",
      filePreviewName:[]
    });
  }

 

  render() {
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('ADD-USER')) return <Redirect to='/Home' />

    return (
      <div className="UserInfo">
        {/*<SideMenu />*/}
        <Spin spinning={this.state.loading} tip={allStrings.loading} size="large" style={{color:'#00B483'}}>

        <MDBContainer>
          <MDBRow>
            <MDBCol xl="12" lg="12" md="12" sm="12">
              <div className="infoCard">
                <Card className="editCard" style={{ overflow: "visible", outline: "none",marginBottom: "0px"}} >
                  <CardMedia style={{ height: "0px" }} />
                  <CardHeader></CardHeader>
                  
                  <div  style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                      <div className="topIcon" style={{background:'#00B483'}}>
                          <span className="material-icons" style={{color:'white',fontSize:23}}>group</span>
                      </div>
                      <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                      <p>{allStrings.AddUser}</p>
                      </div>
                  </div>
                  
                            
                  <CardContent style={{ minHeight: "390px", outline: "none" }}>
                    <MDBContainer>
                      <MDBRow>
                        <MDBCol md="12">
                        <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    <div className="grey-text">
                                    <MDBRow>
                                        
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.name}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.fullname} type="text" id="form645" name="fullname" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.email}</label>
                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^a-zA-Z0-9$@$!%*?/&#^-_. +]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.email} type="email" id="form65" name="email" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form615" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.phone}</label>
                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^0-9+]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.phone} type="text" id="form615" name="phone" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        
                                    </MDBRow>
                                    
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.userType}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false} 
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.type}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({type:value});
                                                if(value ==="OFFICE"){
                                                  this.getCategory()
                                                }
                                                if(value ==="SUB-ADMIN"){
                                                  this.getPermissions()
                                                }

                                                }} 
                                            
                                            >
                                                <TreeNode value="ADMIN" title={allStrings.admin} key="ADMIN" />
                                                <TreeNode value="SUB-ADMIN" title={allStrings.subAdmin} key="SUB-ADMIN" />
                                                <TreeNode value="USER" title={allStrings.client} key="USER" />
                                                <TreeNode value="OFFICE" title={allStrings.office} key="OFFICE" />
                                                
                                            </TreeSelect>
                                        </MDBCol>
                                        
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.password}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr'}} type="text" id="form605" name="password" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                      <MDBCol md="6">
                                          <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.country}</label>
                                          <TreeSelect virtual={false}
                                              showSearch={true} 
                                              style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                              treeCheckable = {false}         
                                              treeNodeFilterProp="title"                      
                                              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                              placeholder={allStrings.country}
                                              onChange={(value) => {
                                              console.log(value)
                                              this.setState({country:value});
                                              this.getCities(value)
                                              this.getCountry(value)
                                              }} 
                                          
                                          >
                                              {this.state.countries.map(val=>(
                                                  <TreeNode value={val.id} title={this.props.isRTL?val.countryName_ar:val.countryName_en} key={val.id} />
                                              ))}
                                          </TreeSelect>
                                      </MDBCol>
                                      <MDBCol md="6">
                                          <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.city}</label>
                                          <TreeSelect virtual={false}
                                              value={this.state.city?this.state.city:[]}
                                              showSearch={true} 
                                              style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                              treeCheckable = {false}         
                                              treeNodeFilterProp="title"                      
                                              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                              placeholder={allStrings.city}
                                              onChange={(value) => {
                                              console.log(value)
                                              this.setState({city:value});
                                              }} 
                                          
                                          >
                                              {this.state.cities.map(val=>(
                                                  <TreeNode value={val.id} title={this.props.isRTL?val.cityName_ar:val.cityName_en} key={val.id} />
                                              ))}
                                          </TreeSelect>
                                      </MDBCol>
                                    </MDBRow>
                                    {this.state.type ==="SUB-ADMIN"&&
                                      <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                         <MDBCol md="6">
                                          <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.permission}</label>
                                          <TreeSelect virtual={false}
                                              value={this.state.permission?this.state.permission:[]}
                                              showSearch={true} 
                                              style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                              treeCheckable = {false}         
                                              treeNodeFilterProp="title"                      
                                              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                              placeholder={allStrings.permission}
                                              onChange={(value) => {
                                              console.log(value)
                                              this.setState({permission:value});
                                              }} 
                                          
                                          >
                                              {this.state.permissions.map(val=>(
                                                  <TreeNode value={val.id} title={this.props.isRTL?val.permission_ar:val.permission_en} key={val.id} />
                                              ))}
                                          </TreeSelect>
                                         </MDBCol>
                                         <MDBCol md="6">
                                         </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.type ==="OFFICE"&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                      <MDBCol md="6">
                                          <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.workCity}</label>
                                          <TreeSelect virtual={false}
                                              value={this.state.workCity?this.state.workCity:[]}
                                              showSearch={true} 
                                              style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                              treeCheckable = {true}        
                                              showCheckedStrategy= {SHOW_PARENT}  
                                              treeNodeFilterProp="title"                      
                                              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                              placeholder={allStrings.workCity}
                                              onChange={(value) => {
                                              console.log(value)
                                              this.setState({workCity:value,workArea:[]});
                                              this.getAreas(value)
                                              }} 
                                          
                                          >
                                              <TreeNode value="ALL" title={allStrings.all} key="ALL" />
                                              {this.state.cities.map(val=>(
                                                  <TreeNode value={val.id} title={this.props.isRTL?val.cityName_ar:val.cityName_en} key={val.id} />
                                              ))}
                                          </TreeSelect>
                                      </MDBCol>
                                      <MDBCol md="6">
                                          <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.workArea}</label>
                                          <TreeSelect virtual={false}
                                              value={this.state.workArea?this.state.workArea:[]}
                                              showSearch={true} 
                                              style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                              treeCheckable = {true}    
                                              showCheckedStrategy= {SHOW_PARENT}       
                                              treeNodeFilterProp="title"                      
                                              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                              placeholder={allStrings.workArea}
                                              onChange={(value) => {
                                              console.log(value)
                                                if(value.find((e) => e ==='ALL')){
                                                  let arr = this.state.allAreas;
                                                  arr.push(...value);
                                                  this.setState({workArea:arr});
                                                }else{
                                                  if(this.state.workArea.find((e) => e ==='ALL')){
                                                    this.setState({workArea:[]});
                                                  }else{
                                                    this.setState({workArea:value});
                                                  }
                                                  
                                                }
                                              }} 
                                          
                                          >
                                              <TreeNode value="ALL" title={allStrings.all} key="ALL" />
                                              {this.state.areas.map(val=>(
                                                  <TreeNode value={val.id} title={this.props.isRTL?val.areaName_ar:val.areaName_en} key={val.id} />
                                              ))}
                                          </TreeSelect>
                                      </MDBCol>
                                    </MDBRow>
                                    }{this.state.type ==="OFFICE"&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                      <MDBCol md="6">
                                          <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.workCategory}</label>
                                          <TreeSelect virtual={false}
                                              value={this.state.workCategory?this.state.workCategory:[]}
                                              showSearch={true} 
                                              style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                              treeCheckable = {true}        
                                              showCheckedStrategy= {SHOW_PARENT}  
                                              treeNodeFilterProp="title"                      
                                              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                              placeholder={allStrings.workCategory}
                                              onChange={(value) => {
                                              console.log(value)
                                              this.setState({workCategory:value,workSubCategory:[]});
                                              this.getSubCategories(value)
                                              }} 
                                          
                                          >
                                              <TreeNode value="ALL" title={allStrings.all} key="ALL" />
                                              {this.state.categories.map(val=>(
                                                  <TreeNode value={val.id} title={this.props.isRTL?val.categoryName_ar:val.categoryName_en} key={val.id} />
                                              ))}
                                          </TreeSelect>
                                      </MDBCol>
                                      <MDBCol md="6">
                                          <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.workSubCategory}</label>
                                          <TreeSelect virtual={false}
                                              value={this.state.workSubCategory?this.state.workSubCategory:[]}
                                              showSearch={true} 
                                              style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                              treeCheckable = {true}   
                                              showCheckedStrategy= {SHOW_PARENT}        
                                              treeNodeFilterProp="title"                      
                                              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                              placeholder={allStrings.workSubCategory}
                                              onChange={(value) => {
                                              console.log(value)
                                              this.setState({workSubCategory:value});
                                              }} 
                                          
                                          >
                                             <TreeNode value="ALL" title={allStrings.all} key="ALL" />
                                              {this.state.subCategories.map(val=>(
                                                  <TreeNode value={val.id} title={this.props.isRTL?val.categoryName_ar:val.categoryName_en} key={val.id} />
                                              ))}
                                          </TreeSelect>
                                      </MDBCol>
                                    </MDBRow>
                                    }{this.state.type ==="OFFICE"&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                      <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.agencyType}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false} 
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.agencyType}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({agencyType:value});
                                                }} 
                                            
                                            >
                                              
                                                <TreeNode value="COMPANY" title={allStrings.company} key="COMPANY" />
                                                <TreeNode value="OFFICE" title={allStrings.office} key="OFFICE" />
                                                <TreeNode value="MARKETER" title={allStrings.marketer} key="MARKETER" />
                                                <TreeNode value="FREEWORK" title={allStrings.freeWork} key="FREEWORK" /> 
                                                
                                            </TreeSelect>
                                      </MDBCol>
                                      <MDBCol md="6">
                                      </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.type ==="OFFICE"&&
                                    <MDBRow>
                                        <MDBCol md="12">
                                        <label htmlFor="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.files}</label>

                                            <div className="fileUpload2"> 
                                                <input mu  key={this.state.commercialFile} type='file' className="upImgs2" onChange={this.fileChangedHandler2} ></input>
                                                <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.uploadFile}</span></span>
                                            </div>
                                            <div className="preview">
                                                {this.state.filePreviewName.map((val) => (
                                                <div style={{ display: "inline-block" }}>
                                                <p className="previewPdf">{val}
                                                <MDBIcon icon="times" className="mr-2 removePdf"
                                                    onClick={() => this.removeFile2()}  ></MDBIcon>
                                                </p>
                                                </div>
                                                ))}
                                            
                                            </div>
                                        </MDBCol>
                                        {this.state.commercialFile != null &&
                                            <div>
                                                {this.state.commercialFile.map((val) => (
                                                <a href={val} target="blank" style={{background:'#00B483',
                                                color: '#fff',
                                                padding: '4px 40px 6px',
                                                marginLeft: '15px',
                                                fontFamily: 'fantasy',
                                                fontSize: '13px'}}
                                                >
                                                    {allStrings.openFile}          
                                                </a>
                                                ))}
                                            </div>
                                        }
                                        
                                        
                                    </MDBRow>
                                    }
                                    <MDBRow>
                                        <MDBCol md="12">
                                        <label htmlFor="form8930" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.profileImg}</label>

                                            <div className="fileUpload2"> 
                                                <input  key={this.state.img} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                            </div>
                                            <div className="preview">
                                            {this.state.img&&
                                            <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                <img alt="" src={this.state.imgType==="data"?URL.createObjectURL(this.state.img):this.state.img} className="previewImg2"/>
                                                
                                                <span className="imgName">{this.state.imgName}</span>
                                                <MDBIcon icon="trash" className="mr-2 removeImg3"
                                                onClick={() => this.removeFile()}  ></MDBIcon>
                                            </div>
                                            }
                                            </div>
                                            
                                        </MDBCol>
                                    </MDBRow>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                      <MDBBtn
                                      type="submit" style={{background:'#00B483'}}
                                      rounded
                                      className="btn-block z-depth-1a"
                                      >
                                          <MDBIcon icon="plus" className="mr-2" />
                                          {allStrings.add}        
                                      </MDBBtn>
                                      
                                  </div>
                                </form>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  </CardContent>
                </Card>
              </div>
            </MDBCol>
          </MDBRow>
         
        </MDBContainer>
        </Spin>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchusernamerops = {
    setUser,
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchusernamerops)(AddUser)
  );