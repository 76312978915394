import React from 'react';
import './interest info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { message} from "antd";
import moment from 'moment';
class interestInfo extends React.Component {
    state = {
      actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
      page:1,
      pages:1,
      id:this.props.location.state.data.id,
      fullname:this.props.location.state.data.fullname?this.props.location.state.data.fullname:'',
      phone:this.props.location.state.data.phone?this.props.location.state.data.phone:'',
      country:this.props.location.state.data.country?this.props.location.state.data.country.countryName:null,
      createdAt:this.props.location.state.data.createdAt,
      status:this.props.location.state.data.status,
      };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        this.getCountry(this.props.location.state.data.country.id?this.props.location.state.data.country.id:1)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    getCountry = (id) => {
      axios.get(`${BASE_END_POINT}countries/${id}`)
      .then(response=>{
        console.log("country",response.data.data)
        this.setState({
          phone:this.state.phone.replace(response.data.data.countryCode, "")
        })
      })
      .catch(error=>{
        console.log(" country ERROR")
        console.log(error.response)
      })
    }
    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    delete = () => {
        let l = message.loading('Wait..', 2.5)
        axios.delete(`${BASE_END_POINT}interests/${this.props.location.state.data.id}`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization':`Bearer ${this.props.currentUser.token}`,
           'Accept-Language':this.props.isRTL?'ar':'en',
         },
        })
        .then(response=>{
          console.log("interest DELETED")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
    contactDone = () => {
        let l = message.loading('Wait..', 2.5)
        axios.put(`${BASE_END_POINT}interests/${this.props.location.state.data.id}/done`,{},{
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Bearer ${this.props.currentUser.token}`,
          'Accept-Language':this.props.isRTL?'ar':'en',
        },
        })
        .then(response=>{
          console.log("interest done")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('INTERESTS')) return <Redirect to='/Home' />
  return (
    <div className="App">
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                          <CardMedia
                            style={{height:"0px"}}
                          />
                            <CardHeader></CardHeader>
                            <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                                <div className="topIcon" style={{background:'#00B483'}}>
                                    <span class="material-icons" style={{color:'white',fontSize:23}}>contact_phone</span>
                                </div>
                                <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                                marginLeft:'0',marginRight:'0'}}>
                                <p>{allStrings.info}</p>
                                </div>
                            </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                //onSubmit={this.submitHandler}
                                noValidate
                                >
                                    <div className="grey-text">
                                   {this.state.actionType==="VIEW"&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form6015" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form6015"  className="form-control" />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                          <div className="md-form">
                                            <label htmlFor="form6005" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.status}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.status === "PENDING"?allStrings.pending:allStrings.contactDone} type="text" id="form6005"  className="form-control" />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                        </MDBCol>
                                        
                                    </MDBRow>
                                    }
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.fullname}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.fullname} type="text" id="form645" name="fullname" className="form-control" required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.phone}</label>
                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^0-9+]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.phone} type="text" id="form56" name="phone" className="form-control" required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    {this.state.actionType==="VIEW"&& this.state.createdAt&&
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form062" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.date}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={moment(this.state.createdAt).format('LLL')} type="text"  id="form062"  className="form-control"  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    

                                    
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                      {this.state.actionType==="VIEW"&&this.state.actionType==="VIEW"&&
                                        <MDBBtn onClick={()=>{this.delete()}} rounded color="danger" className="btn-block z-depth-1a">
                                            <MDBIcon icon="trash-alt" className="mr-2" />
                                            {allStrings.remove}
                                        </MDBBtn>
                                      }
                                      {this.state.status ==="PENDING"&&this.state.actionType==="VIEW"&&
                                        <MDBBtn onClick={()=>{this.contactDone()}} rounded color="green" className="btn-block z-depth-1a">
                                            <MDBIcon  className="mr-2" />
                                            {allStrings.contactDone}
                                        </MDBBtn>
                                      }
                                        
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
            
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(interestInfo)
  );