
import {CHAT} from './types'
import { BASE_END_POINT } from "../../urls";
import axios from "axios";


export function getUnseenMessagesCount(token) {
    console.log('un seen messages')
    return dispatch => {
        axios.get(`${BASE_END_POINT}messages/unseenCount`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
          })
        .then(response=>{
          console.log("UN SEEN =>  ",response.data.unseen)
          console.log(response.data.unseen)
          dispatch({type:CHAT,payload:response.data.unseen})       
        })
        .catch(error=>{
          console.log("UN SEEN ERROR")
          console.log(error.response)
        })
      
    }
}