import {NOTIFICATIONS,UNREADED_NOTIFICATION} from "../actions/types"


const initialState = {
    notifications:[],
    notifPage:1,
    notifPages:1,
    unreadNotifCount:0,
}

const NotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATIONS:
            console.log('NOTIFICATIONS reducer =>'+action.payload)
            return {
                ...state, 
                notifications: action.refresh? action.payload: [...state.notifications,...action.payload],
                notifPage:action.page,
                notifPages:action.pages, 
            }; 
            case UNREADED_NOTIFICATION:
                console.log('UNREADED_NOTIFICATION NOTIFICATIONS reducer =>'+action.payload)
                return {...state,unreadNotifCount:action.payload };                
        default:
            return state;
    }

}

export default NotificationReducer;