
import React from 'react';
import './splash.css';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {userToken,setUser} from '../../redux/actions/AuthActions'
import  allStrings from '../../assets/strings'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import {Collapsed} from '../../redux/actions/MenuAction'

class Splash extends React.Component {

    state = {
        loading: true,
    };
  

  constructor(props){
    super(props)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

      checkToken =  (token) => {
        const t = localStorage.getItem('@USERSAAEI')
          if(t){
              this.props.userToken(t);
          }else{
            localStorage.setItem('@USERSAAEIToken',token)
              this.props.userToken(token)
          }
          console.log(token)
      }

      checkLogin =  () => {      
        const userJSON = localStorage.getItem('@USERSAAEI');
        if(userJSON){
            const userInfo = JSON.parse(userJSON);
            console.log(userInfo)
            if(userInfo.user.type !=="ADMIN"){
              if(userInfo.user.permission.pages.includes('DASHBOARD')){
                this.props.history.replace('/Home') 
              }else{
                if(userInfo.user.permission.pages.includes('CATEGORIES')){
                  this.props.history.replace('/Categories') 
                }
                if(userInfo.user.permission.pages.includes('Ads')){
                  this.props.history.replace('/Ads') 
                }
                if(userInfo.user.permission.pages.includes('REQUESTS')){
                  this.props.history.replace('/requests') 
                }
                if(userInfo.user.permission.pages.includes('ANONCEMENTS')){
                  this.props.history.replace('/anoncements') 
                }
                if(userInfo.user.permission.pages.includes('FEATURES')){
                  this.props.history.replace('/Features') 
                }
                if(userInfo.user.permission.pages.includes('PACKAGES')){
                  this.props.history.replace('/Packages') 
                }
                if(userInfo.user.permission.pages.includes('TRANSACTIONS')){
                  this.props.history.replace('/Transactions') 
                }
                if(userInfo.user.permission.pages.includes('COUNTRIES')){
                  this.props.history.replace('/Countries') 
                }
                if(userInfo.user.permission.pages.includes('ABOUT')){
                  this.props.history.replace('/about') 
                }
                if(userInfo.user.permission.pages.includes('SETTINGS')){
                  this.props.history.replace('/Setting') 
                }
                if(userInfo.user.permission.pages.includes('PERMISSIONS')){
                  this.props.history.replace('/Permissions') 
                }
                if(userInfo.user.permission.pages.includes('FAQ')){
                  this.props.history.replace('/Questions') 
                }
                if(userInfo.user.permission.pages.includes('NOTIFS')){
                  this.props.history.replace('/SendNotifs') 
                }
                if(userInfo.user.permission.pages.includes('CONTACT-US')){
                  this.props.history.replace('/ContactUs') 
                }
                if(userInfo.user.permission.pages.includes('CLIENTS')){
                  this.props.history.replace('/Users') 
                }
                if(userInfo.user.permission.pages.includes('OFFICES')){
                  this.props.history.replace('/Offices') 
                }
                if(userInfo.user.permission.pages.includes('SUB-ADMIN')){
                  this.props.history.replace('/Supervisors') 
                }
              }
            }else{
              this.props.history.replace('/Home') 
            }
            
            
          }else{
            this.props.history.push('/Login')
          }
      }

      checkLanguage =  () => {      
      const lang = localStorage.getItem('@LANG');
      if(lang){
            allStrings.setLanguage(lang) 
            if(lang==='ar'){
            this.props.ChangeLanguage(true)  
            }else{
            this.props.ChangeLanguage(false)
            }
          
        }else{
          allStrings.setLanguage('ar') 
          this.props.ChangeLanguage(true)  
        }
      }
      checkMenu =  () => {      
        const menu = localStorage.getItem('@MENU');
        if(menu){
              if(menu==='true'){
              this.props.Collapsed(true)  
              }else{
              this.props.Collapsed(false)
              }
            
          }else{
            this.props.Collapsed(true)  
          }
        }
      


   
     componentDidMount(){
      this.checkLanguage()
      this.checkMenu()
      setTimeout(()=>{this.checkLogin()},500)
     }
     
    
    render() {
      return (
        <div className="splash">
        <div className="theFrame">
          <div className="TheCenter">
              <div className="dot-1"></div>
              <div className="dot-2"></div>
              <div className="dot-3"></div>
          </div>
       </div>
      </div> 
      );
    }
  }

  
  const mapToStateProps = state => ({
    isRTL: state.lang.isRTL,
  })
  
  const mapDispatchToProps = {
    userToken,
    setUser,
    ChangeLanguage,
    Collapsed
  }

  export default  withRouter(connect(mapToStateProps,mapDispatchToProps)(Splash));

