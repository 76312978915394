import React from 'react';
import './region info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon,MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Tooltip from '@material-ui/core/Tooltip';
import { Info,Edit } from '@material-ui/icons';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message ,Empty} from "antd";
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from 'react-loading-skeleton';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { UploadOutlined } from '@ant-design/icons';

class regionInfo extends React.Component {
        state = {
          actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
            country:this.props.location.state.country?this.props.location.state.country:1,
            id:this.props.location.state.data.id,
            regionName_en:this.props.location.state.data.regionName_en,
            regionName_ar:this.props.location.state.data.regionName_ar,
            modal:false,
            latitude:this.props.location.state.data.location.coordinates[1],
            longitude:this.props.location.state.data.location.coordinates[0],
            cityName_en:'',
            cityName_ar:'',
            latitudeCity:'0',
            longitudeCity:'0',
            cities:[],
            page:1,
            pages:1,
            img:null,
            imgName:'',
            imgType:'data',
            loading:true,
        };

      constructor(props){
        super(props)
        this.getcities(1)
        window.scrollTo(0,0)
        if(this.props.isRTL){
          allStrings.setLanguage('ar')
        }else{
          allStrings.setLanguage('en')
        } 
      }
      getcities = (page) => {
        axios.get(`${BASE_END_POINT}countries/${this.props.location.state.data.id}/cities?page=${page}`)
        .then(response=>{
            console.log('cities  ',response.data.data)
            this.setState({
              cities:response.data.data,
              page:response.data.page,
              pages:response.data.pageCount, 
              loading:false
            })
        })
        .catch(error=>{
            console.log('cities ERROR  ',error)
            console.log('cities ERROR  ',error.respose)
        })
      }
      updateregion = () => {
        const {regionName_en,regionName_ar,latitude,longitude} = this.state
        if(hasValue(regionName_en)&&hasValue(regionName_ar)){
            let l = message.loading('Wait..', 2.5)
           
            const data={
                'regionName_en':regionName_en,
                'regionName_ar':regionName_ar,
                'location':[longitude,latitude]
            }
            
            axios.put(`${BASE_END_POINT}regions/${this.props.location.state.data.id}`,JSON.stringify(data),{
            headers: {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            console.log("region UPDATED  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
     }

     deleteregion = () => {
        let l = message.loading('Wait..', 2.5)
        axios.delete(`${BASE_END_POINT}regions/${this.props.location.state.data.id}`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization':`Bearer ${this.props.currentUser.token}`,
           'Accept-Language':this.props.isRTL?'ar':'en'
         },
        })
        .then(response=>{
          console.log("region DELETED")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
     }

      submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.updateregion()
      };
    
      changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
      };

    citiesTable = () =>{
      return(
      <MDBRow>
      <MDBCol md="12">
          <div className="infoTable">
              <div className="topInfoTable" style={{ background: '#00B483' }}>
                  <h4>
                  <MDBIcon icon="map" /> {allStrings.cities}
                  </h4>
              </div>
              <div className="tableDate">
                  <div className="table-responsive">
                      <table className="table table-striped">
                      <thead>
                        {this.props.isRTL?
                          <tr style={{textAlign:'center'}}>
                              <th>{allStrings.more}</th>
                              <th>{allStrings.officeCount}</th>
                              <th>{allStrings.clientCount}</th>
                              <th>{allStrings.adsRequestCount}</th>
                              <th>{allStrings.englishName}</th>
                              <th>{allStrings.arabicName}</th>
                              <th scope="col">#</th>
                          </tr>
                        :
                          <tr style={{textAlign:'center'}}>
                              <th scope="col">#</th>
                              <th>{allStrings.englishName}</th>
                              <th>{allStrings.arabicName}</th>
                              <th>{allStrings.adsRequestCount}</th>
                              <th>{allStrings.clientCount}</th>
                              <th>{allStrings.officeCount}</th>
                              <th>{allStrings.more}</th>
                          </tr>
                        }
                          
                      </thead>
                      <tbody>
                      {this.state.loading?
                          <tr style={{height: '200px'}}>
                              <th colSpan="6" className="noData">
                              <Skeleton height={400}/> 
                              </th>
                          </tr>
                          :
                          this.state.cities.length > 0 ?
                          this.state.cities.map(city =>(
                            this.props.isRTL?
                            <tr style={{textAlign:'center'}}>
                                <td>
                                  <span onClick={()=>{this.props.history.push("cityInfo",{data:city}) }}  style={{margin: "4px",cursor:'pointer'}}>
                                      <Tooltip title={allStrings.info} placement="top">
                                      <Info style={{color:'#33d633'}}/>
                                      </Tooltip>
                                  </span>
                                  <span onClick={()=>{this.props.history.push('cityInfo',{data:city,actionType:'EDIT'}) }} style={{margin: "4px",cursor:'pointer'}}>
                                    <Tooltip title={allStrings.edit} placement="top" arrow>
                                        <Edit style={{color:'cornflowerblue',fontSize: '20px'}}/>
                                    </Tooltip>
                                  </span> 
                                </td>
                                <th>{city.officeCount}</th>
                                <th>{city.clientCount}</th>
                                <th>{city.adsRequestCount}</th>
                                <td>{city.cityName_en}</td>
                                <td>{city.cityName_ar}</td>
                                <td>{city.id}</td>
                            </tr>
                            :
                            <tr style={{textAlign:'center'}}>
                                <td>{city.id}</td>
                                <td>{city.cityName_en}</td>
                                <td>{city.cityName_ar}</td>
                                <th>{city.adsRequestCount}</th>
                                <th>{city.clientCount}</th>
                                <th>{city.officeCount}</th>
                                <td>
                                <span style={{margin: "4px",cursor:'pointer'}} onClick={()=>{this.props.history.push("cityInfo",{data:city}) }} >
                                    <Tooltip title={allStrings.info} placement="top">
                                    <Info style={{color:'#33d633'}}/>
                                    </Tooltip>
                                </span>
                                <span onClick={()=>{this.props.history.push('cityInfo',{data:city,actionType:'EDIT'}) }} style={{margin: "4px",cursor:'pointer'}}>
                                    <Tooltip title={allStrings.edit} placement="top" arrow>
                                        <Edit style={{color:'cornflowerblue',fontSize: '20px'}}/>
                                    </Tooltip>
                                </span>  
                                </td>
                            </tr>
                          ))
                          :
                              <tr style={{height: '200px'}}>
                                  <th colSpan="6" className="noData">
                                  <Empty description={allStrings.noData} style={{margin:'0rem'}}/>
                                  </th>
                              </tr>
                          }
                      </tbody>
                      </table>
                      {this.state.cities.length > 0 &&
                          <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
                              {this.paginationButtons()}
                          </div>
                      }
                  </div>
              </div>
          </div>
      </MDBCol>
  </MDBRow>
      )
  
    }
    paginationButtons = () =>(
      <CardActions style={{display: 'inline-flex'}}>
          <Button 
          onClick={()=>{
            if(this.state.page!==1){
              this.getcities(1)
              this.setState({page:1})
            }
          }}
          className="pagenation" >{allStrings.first}</Button>
  
          <Button 
          onClick={()=>{
            if(this.state.page>1){
              this.getcities(this.state.page-1)
              this.setState({page:this.state.page-1})
            }
          }}
          className="pagenation">{allStrings.prev}</Button>
          
          <Pagination
          onChange={(event,page)=>{
          console.log("page   ",page)
          if(page!==this.state.page){
            this.getcities(page)
            this.setState({page:page})
          }
          
          }} 
          defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`${'#00B483'} !important`}} />
          
          <Button 
          onClick={()=>{
            if(this.state.page<this.state.pages){
              this.getcities(this.state.page+1)
              this.setState({page:this.state.page+1})
            }
          }}
          className="pagenation">{allStrings.next}</Button>
  
          <Button 
            onClick={()=>{
            if(this.state.page!==this.state.pages){
              this.getcities(this.state.pages)
              this.setState({page:this.state.pages})
            }
          }}
          className="pagenation">{allStrings.last}</Button>
  
      </CardActions>
    )
    addNewRecordButton = () =>(
      <div style={{width:'100%'}}>
          <Fab
            onClick={this.toggle} 
            style={{background:'#00B483',float:'left',margin:'50px 30px'}}
            letiant="extended"
            size="medium"
            color="primary"
            aria-label="add"
          >
            <AddIcon />{allStrings.addCity}
          </Fab>
      </div>
    )
    addCity = () => {
      const {cityName_en,cityName_ar,longitudeCity,latitudeCity,country,img} = this.state
      if(hasValue(cityName_en)&&hasValue(cityName_ar)&&img != null){
          let l = message.loading('Wait..', 2.5)
            let data = new FormData()
            data.append('cityName_ar',cityName_ar)
            data.append('cityName_en',cityName_en)
            data.append('country',country)
            data.append('location',JSON.stringify([longitudeCity,latitudeCity]))
            if(img){
                data.append('img',img); 
            }
            console.log(Array.from(data))
          axios.post(`${BASE_END_POINT}countries/${this.props.location.state.data.id}/cities`,data,{
              headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization':`Bearer ${this.props.currentUser.token}`,
              'Accept-Language':this.props.isRTL?'ar':'en'
              },
          })
          .then(response=>{
              console.log("CITY UPDATED  ",response.data)
              l.then(() => message.success(allStrings.done, 2.5) )
              this.toggle()
              this.getcities(1)
          })
          .catch(error=>{
              console.log(error.response)
              l.then(() => message.error(allStrings.error, 2.5))
          })
      }
   }
    submitHandlerAddCity = (event) => {
      event.preventDefault();
      event.target.className += " was-validated";
      this.addCity()
    };
    changeHandlerAddCity = (event) => {
        console.log(event.target.value);
        this.setState({ [event.target.name]: event.target.value });
    };
    toggle = () => {
      this.setState({
        modal: !this.state.modal,
      });
    }
    modal = () =>(
      <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
        <MDBModalHeader toggle={this.toggle} >{allStrings.completeData}</MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <form style={{paddingTop:'1rem'}}
                    className="needs-validation"
                    onSubmit={this.submitHandlerAddCity}
                    noValidate
                  >
                    <div className="grey-text">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="md-form">
                            <label htmlFor="form92" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form92" className="form-control" onChange={this.changeHandlerAddCity} required validate  name="cityName_en"/>
                            <div class="valid-feedback">{allStrings.correct}</div>
                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <div className="md-form">
                            <label htmlFor="form89" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form89" className="form-control" onChange={this.changeHandlerAddCity} required validate name="cityName_ar"/>
                            <div class="valid-feedback">{allStrings.correct}</div>
                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol md="6">
                            <div className="md-form">
                              <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.latitude}</label>
                              <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="number" step="0.01"  id="form65" name="latitudeCity" className="form-control" onChange={this.changeHandlerAddCity} required  validate/>
                              <div class="valid-feedback">{allStrings.correct}</div>
                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                            </div>
                        </MDBCol>
                        <MDBCol md="6">
                            <div className="md-form">
                              <label htmlFor="form64" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.longitude}</label>
                              <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="number" step="0.01" id="form64" name="longitudeCity" className="form-control" onChange={this.changeHandlerAddCity} required  validate/>
                              <div class="valid-feedback">{allStrings.correct}</div>
                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                            </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol md="12">
                            <div className="fileUpload2"> 
                                <input  key={this.state.img} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                            </div>
                            <div className="preview">
                            {this.state.img&&
                            <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                <img alt="" src={this.state.imgType==="data"?URL.createObjectURL(this.state.img):this.state.img}  className="previewImg2"/>
                                <span className="imgName">{this.state.imgName}</span>
                                <MDBIcon icon="trash" className="mr-2 removeImg2"
                                onClick={() => this.removeFile()}  ></MDBIcon>
                            </div>
                            }
                            </div>
                            
                        </MDBCol>
                          
                      </MDBRow>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="text-center">
                      <MDBBtn type="submit" style={{ background: '#00B483' }} rounded className="btn-block z-depth-1a" >
                        <MDBIcon icon="plus" className="mr-2" />
                        {allStrings.add}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    );
    fileChangedHandler = event => {
      this.setState({
        img: event.target.files[0],
        imgType:'data',
        imgName: event.target.files[0].name,
      })
    }
    removeFile = () => {
      this.setState({
          img:null,
          imgType:'url',
          imgName:'',
          
      });
    }
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('COUNTRIES')) return <Redirect to='/Home' />

  return (
    <div className="App">
       {this.modal()}
        <MDBContainer> 
            <MDBRow>
             
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#00B483'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>'location_city'</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{this.state.actionType==="VIEW"?allStrings.regionInfo:allStrings.edit}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                    {this.state.actionType==="VIEW"&&
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form8" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.regionName_en} type="text" id="form645" name="regionName_en" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.regionName_ar} type="text" id="form56" name="regionName_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                      <MDBCol md="6">
                                          <div className="md-form">
                                          <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.latitude}</label>
                                          <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^0-9.]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.latitude} type="number" step="0.01"  id="form65" name="latitude" className="form-control" onChange={this.changeHandler} required  validate/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                      </MDBCol>
                                      <MDBCol md="6">
                                          <div className="md-form">
                                          <label htmlFor="form64" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.longitude}</label>
                                          <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.longitude} type="number" step="0.01"  id="form64" name="longitude" className="form-control" onChange={this.changeHandler} required  validate/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                      </MDBCol>
                                    </MDBRow>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                      {this.state.actionType==="EDIT"&&
                                        <MDBBtn
                                        type="submit" style={{background:'#00B483'}}
                                        rounded
                                        className="btn-block z-depth-1a"
                                        >
                                            <MDBIcon icon="edit" className="mr-2" />
                                            {allStrings.update}          
                                        </MDBBtn>
                                      }{this.state.actionType==="VIEW"&&
                                        <MDBBtn onClick={()=>{this.deleteregion()}} rounded color="danger" className="btn-block z-depth-1a">
                                            <MDBIcon icon="trash-alt" className="mr-2" />
                                            {allStrings.remove}
                                        </MDBBtn>
                                      }
                                        
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
            {this.state.actionType==="VIEW"&&this.citiesTable()}
            {this.state.actionType==="VIEW"&&this.addNewRecordButton()}
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(regionInfo)
  );