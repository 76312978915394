import React from 'react';
import './add package.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,TreeSelect } from "antd";
const TreeNode = TreeSelect
class AddPackage extends React.Component {
    state = {
        name_en:'',
        name_ar:'',
        description_en:'',
        description_ar:'',
        available:true,
        availableAds:0,
        type:'ADS',
        supervisors:0,
        availableProperty:0,
        building:false,
        plan:false,
        booking:false,
        durationType1:null,
        duration1:0,
        cost1:0,
        durationType2:null,
        duration2:0,
        cost2:0,
    };

    constructor(props){
        super(props)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    addPackage = () => {
      const {name_en,name_ar,availableAds,description_en,building,booking,availableProperty,
        description_ar,type,plan,supervisors,duration1,durationType1,cost1
        ,durationType2,cost2,duration2,available} = this.state
        
        
        if(hasValue(name_en)&&hasValue(name_ar)&&hasValue(description_en)&&hasValue(description_ar)){
          let l = message.loading('Wait..', 2.5)
            const data={
                'name_en':name_en,
                'name_ar':name_ar,
                'description_ar':description_ar,
                'description_en':description_en,
                'supervisors':supervisors,
                'availableAds':availableAds,
                'type':type,
                'plan':plan,
                'building':building,
                'booking':booking,
                'available':available,
                'availableProperty':availableProperty
            }
            let costs = []
            if(durationType1 != null){
                costs.push({duration:duration1,durationType:durationType1,cost:cost1})
            }
            if(durationType2 != null){
                costs.push({duration:duration2,durationType:durationType2,cost:cost2})
            }
            data.costs = costs
           
            axios.post(`${BASE_END_POINT}packages`,JSON.stringify(data),{
            headers: {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            console.log("package UPDATED  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                if(error.response.data.errors[0].msg){
                    l.then(() => message.error(error.response.data.errors[0].msg, 2.5))

                }else{
                    l.then(() => message.error(allStrings.error, 2.5))

                }
            })
        }
    }

    
   

    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.addPackage()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
      
  
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('PACKAGES')) return <Redirect to='/Home' />

  return (
    <div className="App">
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div  style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#00B483'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>list_alt</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{allStrings.AddPackage}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                    <form
                                    className="needs-validation"
                                    onSubmit={this.submitHandler}
                                    noValidate
                                    >
                                        
                                        <div className="grey-text">
                                        
                                        <MDBRow>
                                            <MDBCol md="6">
                                                <div className="md-form">
                                                <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                                <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_en} type="text" id="form645" name="name_en" className="form-control" onChange={this.changeHandler} required  validate/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="6">
                                                <div className="md-form">
                                                <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                                <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_ar} type="text" id="form56" name="name_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                          <MDBCol md="12">
                                            <div className="md-form">
                                              <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishDescription}</label>
                                              <br></br>
                                              <textarea onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') }  name="description_en"  style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" className="form-control" required validate  onChange={this.changeHandler} defaultValue={this.state.description_en} />
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                          </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                          <MDBCol md="12">
                                            <div className="md-form">
                                              <label htmlFor="form90" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicDescription}</label>
                                              <br></br>
                                              <textarea onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } name="description_ar"  style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form90" className="form-control" required validate onChange={this.changeHandler}  defaultValue={this.state.description_ar} />
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                          </MDBCol>
                                        </MDBRow>
                                        <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                            <MDBCol md="6">
                                                <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.type}</label>
                                                <TreeSelect virtual={false}
                                                    showSearch={false} 
                                                    value={this.state.type}
                                                    style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                    treeCheckable = {false}         
                                                    treeNodeFilterProp="title"                      
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    placeholder={allStrings.type}
                                                    onChange={(value) => {
                                                    console.log(value)
                                                    this.setState({type:value});
                                                    }} 
                                                
                                                >
                                                    <TreeNode value="ADS" title={allStrings.adsManagment} key="ADS" />
                                                    <TreeNode value="PROPERTY" title={allStrings.propertyManagement} key="PROPERTY" />
                                                    
                                                </TreeSelect>
                                            </MDBCol>
                                            {this.state.type==="ADS" ?
                                            <MDBCol md="6">
                                                <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.plan}</label>
                                                <TreeSelect virtual={false}
                                                    showSearch={false} 
                                                    value={this.state.plan}
                                                    style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                    treeCheckable = {false}         
                                                    treeNodeFilterProp="title"                      
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    placeholder={allStrings.plan}
                                                    onChange={(value) => {
                                                    console.log(value)
                                                    this.setState({plan:value});
                                                    }} 
                                                
                                                >
                                                    <TreeNode value={true} title={allStrings.yes} key="true" />
                                                    <TreeNode value={false} title={allStrings.no} key="false" />
                                                    
                                                </TreeSelect>
                                            </MDBCol>
                                            :
                                            <MDBCol md="6">
                                                <div className="md-form">
                                                <label htmlFor="form569" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.availableProperty}</label>
                                                <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="number" min={1} value={this.state.availableProperty}  id="form569" name="availableProperty" className="form-control" onChange={this.changeHandler} required validate/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            }
                                        </MDBRow>
                                        {this.state.type==="ADS"&&
                                        <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                            
                                            <MDBCol md="6">
                                                <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.building}</label>
                                                <TreeSelect virtual={false}
                                                    showSearch={false} 
                                                    disabled={this.state.actionType==="VIEW"?true:false}
                                                    value={this.state.building}
                                                    style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                    treeCheckable = {false}         
                                                    treeNodeFilterProp="title"                      
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    placeholder={allStrings.building}
                                                    onChange={(value) => {
                                                    console.log(value)
                                                    this.setState({building:value});
                                                    }} 
                                                
                                                >
                                                    <TreeNode value={true} title={allStrings.yes} key="true" />
                                                    <TreeNode value={false} title={allStrings.no} key="false" />
                                                    
                                                </TreeSelect>
                                            </MDBCol>
                                            <MDBCol md="6"></MDBCol>
                                        </MDBRow>
                                        }
                                        {this.state.type!=="ADS"&&
                                        <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                            <MDBCol md="6">
                                                <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.bookings}</label>
                                                <TreeSelect virtual={false}
                                                    showSearch={false} 
                                                    disabled={this.state.actionType==="VIEW"?true:false}
                                                    value={this.state.booking}
                                                    style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                    treeCheckable = {false}         
                                                    treeNodeFilterProp="title"                      
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    placeholder={allStrings.bookings}
                                                    onChange={(value) => {
                                                    console.log(value)
                                                    this.setState({booking:value});
                                                    }} 
                                                
                                                >
                                                    <TreeNode value={true} title={allStrings.yes} key="true" />
                                                    <TreeNode value={false} title={allStrings.no} key="false" />
                                                    
                                                </TreeSelect>
                                            </MDBCol>
                                            <MDBCol md="6">
                                                <div className="md-form">
                                                <label htmlFor="form569" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.availableAds}</label>
                                                <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="number" min={1} value={this.state.availableAds}  id="form569" name="availableAds" className="form-control" onChange={this.changeHandler} required validate/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        }
                                        {this.state.type==="ADS" &&
                                        <MDBRow>
                                            <MDBCol md="6">
                                                <div className="md-form">
                                                <label htmlFor="form569" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.availableAds}</label>
                                                <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="number" min={1} value={this.state.availableAds}  id="form569" name="availableAds" className="form-control" onChange={this.changeHandler} required validate/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                           <MDBCol md="6">
                                              <div className="md-form">
                                                <label htmlFor="form90" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.supervisors}</label>
                                                <input name="supervisors" onChange={this.changeHandler} type="number" min={0} value={this.state.supervisors } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} id="form90" className="form-control" required validate   />
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                          
                                        </MDBRow>
                                        }
                                       
                                        <MDBRow>
                                            <MDBCol md="4">
                                                <div className="md-form">
                                                  <label htmlFor="form00" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.duration}</label>
                                                  <input name="duration1" onChange={this.changeHandler} type="number" min={0} value={this.state.duration1 } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} id="form000" className="form-control" required validate   />
                                                  <div class="valid-feedback">{allStrings.correct}</div>
                                                  <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="4">
                                                <div className="md-form">
                                                  <label htmlFor="form000" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.cost}</label>
                                                  <input name="cost1" onChange={this.changeHandler} type="number" min={0} value={this.state.cost1 } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} id="form90" className="form-control" required validate   />
                                                  <div class="valid-feedback">{allStrings.correct}</div>
                                                  <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="4">
                                                <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.durationType}</label>
                                                <TreeSelect virtual={false}
                                                    showSearch={false} 
                                                    value={this.state.durationType1}
                                                    style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                    treeCheckable = {false}         
                                                    treeNodeFilterProp="title"                      
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    placeholder={allStrings.durationType}
                                                    onChange={(value) => {
                                                    console.log(value)
                                                    this.setState({durationType1:value});
                                                    }} 
                                                
                                                >
                                                    {/*<TreeNode value="DAILY" title={allStrings.daily} key="DAILY" />*/}
                                                    <TreeNode value="MONTHLY" title={allStrings.monthly} key="MONTHLY" />
                                                    <TreeNode value="YEARLY" title={allStrings.yearly} key="YEARLY" />
                                                    
                                                </TreeSelect>
                                            </MDBCol>
                                        
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="4">
                                                <div className="md-form">
                                                  <label htmlFor="form00" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.duration}</label>
                                                  <input name="duration2" onChange={this.changeHandler} type="number" min={0} value={this.state.duration2 } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} id="form000" className="form-control" validate   />
                                                  <div class="valid-feedback">{allStrings.correct}</div>
                                                  <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="4">
                                                <div className="md-form">
                                                  <label htmlFor="form000" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.cost}</label>
                                                  <input name="cost2" onChange={this.changeHandler} type="number" min={0} value={this.state.cost2 } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} id="form90" className="form-control" validate   />
                                                  <div class="valid-feedback">{allStrings.correct}</div>
                                                  <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="4">
                                                <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.durationType}</label>
                                                <TreeSelect virtual={false}
                                                    showSearch={false} 
                                                    value={this.state.durationType2}
                                                    style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                    treeCheckable = {false}         
                                                    treeNodeFilterProp="title"                      
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    placeholder={allStrings.durationType}
                                                    onChange={(value) => {
                                                    console.log(value)
                                                    this.setState({durationType2:value});
                                                    }} 
                                                
                                                >
                                                    {/*<TreeNode value="DAILY" title={allStrings.daily} key="DAILY" />*/}
                                                    <TreeNode value="MONTHLY" title={allStrings.monthly} key="MONTHLY" />
                                                    <TreeNode value="YEARLY" title={allStrings.yearly} key="YEARLY" />
                                                    
                                                </TreeSelect>
                                            </MDBCol>
                                        
                                        </MDBRow>                                      
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="text-center">
                                            <MDBBtn
                                            style={{background:'#00B483',color:'#fff'}}
                                            rounded
                                            color="#00B483"
                                            type="submit"
                                            className="btn-block z-depth-1a"
                                            >
                                            <MDBIcon icon="plus" className="mr-2" />
                                                {allStrings.add}          
                                            </MDBBtn>
                                          
                                        </div>
                                    </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(AddPackage)
  );
