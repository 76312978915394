import React from 'react';
import './view info.css'
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";

class ViewInfo extends React.Component {
    state = {
        actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
        id:this.props.location.state.data.id,
        city:this.props.location.state.data.city?this.props.location.state.data.city:null,
        area:this.props.location.state.data.area?this.props.location.state.data.area:null,
        category:this.props.location.state.data.category?this.props.location.state.data.category:null,
        subCategory:this.props.location.state.data.subCategory?this.props.location.state.data.subCategory:null,
        user:this.props.location.state.data.user?this.props.location.state.data.user:null,
        marketer:this.props.location.state.data.marketer?this.props.location.state.data.marketer:null,
        viewOn:this.props.location.state.data.viewOn?this.props.location.state.data.viewOn:null,
        relatedTo:this.props.location.state.data.relatedTo?this.props.location.state.data.relatedTo:null,

       
    };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('VIEWS')) return <Redirect to='/Home' />

  return (
    <div className="App">
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12" >
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#00B483'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>pageview</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.edit}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                    {this.state.actionType==="VIEW"&&
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form8" className="form-control"  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.category}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false}   style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.category?this.state.category.categoryName:"غير معروف"} type="text" id="form645" name="city" className="form-control" required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.subCategory}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.subCategory?this.state.subCategory.categoryName:"غير معروف"} type="text" id="form56" name="area" className="form-control" required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                      <MDBCol md="6">
                                        <div className="md-form">
                                          <label htmlFor="formn920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.city}</label>
                                          <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.city?this.state.city.cityName:"غير معروف"} type="text" id="formn920" name="type" className="form-control" required validate/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                        </div>
                                      </MDBCol>
                                      <MDBCol md="6">
                                        <div className="md-form">
                                          <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.area}</label>
                                          <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.area?this.state.area.areaName:"غير معروف"} type="text" id="form920" name="cost" className="form-control" required validate/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                        </div>
                                      </MDBCol>
                                    </MDBRow>
                                    {this.state.user&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="10" xs="10">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.client}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.user?this.state.user.fullname:''} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="2" xs="2">
                                    
                                        <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                            <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.user}) }}>
                                            remove_red_eye
                                            </span>
                                            <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.user}) }}>
                                            {allStrings.show}
                                            </span> 
                                        </p>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                     {this.state.marketer&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="10" xs="10">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.marketer}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.marketer?this.state.marketer.fullname:''} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="2" xs="2">
                                    
                                        <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                            <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.marketer}) }}>
                                            remove_red_eye
                                            </span>
                                            <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.marketer}) }}>
                                            {allStrings.show}
                                            </span> 
                                        </p>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.viewOn&&this.state.relatedTo === "ADS"&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="10" xs="10">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.adveristment}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.viewOn?this.state.viewOn.id:''} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="2" xs="2">
                                    
                                        <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                            <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                            onClick={()=>{this.props.history.push('adsInfo',{data:this.state.viewOn}) }}>
                                            remove_red_eye
                                            </span>
                                            <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                            onClick={()=>{this.props.history.push('adsInfo',{data:this.state.viewOn}) }}>
                                            {allStrings.show}
                                            </span> 
                                        </p>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    
                                    
                                 
                                    </div>
                                    <br></br>
                                    <br></br>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol> 
            </MDBRow>
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(ViewInfo)
  );