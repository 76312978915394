import React from 'react';
import './marketing info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { message } from "antd";
class marketingInfo extends React.Component {
    state = {
        actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
        id:this.props.location.state.data.id,
        commission:this.props.location.state.data.commission,
        shareLink:this.props.location.state.data.shareLink,
        status:this.props.location.state.data.status,
        viewsCount:this.props.location.state.data.viewsCount?this.props.location.state.data.viewsCount:0,
        user:this.props.location.state.data.user?this.props.location.state.data.user:null,
        ads:this.props.location.state.data.ads?this.props.location.state.data.ads:null,

       
    };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    marketingComplete = () => {
      let l = message.loading('Wait..', 2.5)
      axios.put(`${BASE_END_POINT}marketerAds/${this.props.location.state.data.id}/complete`,{},{
       headers: {
         'Content-Type': 'application/json',
         'Authorization':`Bearer ${this.props.currentUser.token}`,
         'Accept-Language':this.props.isRTL?'ar':'en'
       },
      })
      .then(response=>{
        console.log("marketingComplete")
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack() 
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.error, 2.5))
      })
  }
  waitingCommission = () => {
    let l = message.loading('Wait..', 2.5)
    axios.put(`${BASE_END_POINT}marketerAds/${this.props.location.state.data.id}/waitCommission`,{},{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`,
       'Accept-Language':this.props.isRTL?'ar':'en'
     },
    })
    .then(response=>{
      console.log("waitingCommission")
      l.then(() => message.success(allStrings.done, 2.5) )
      this.props.history.goBack() 
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
}
    
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('VIEWS')) return <Redirect to='/Home' />

  return (
    <div className="App">
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12" >
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#00B483'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>pageview</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.edit}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                    {this.state.actionType==="VIEW"&&
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form8" className="form-control"  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                      <MDBCol md="6">
                                        <div className="md-form">
                                          <label htmlFor="formn920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.commission}</label>
                                          <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.commission} type="text" id="formn920" name="commission" className="form-control" required validate/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                        </div>
                                      </MDBCol>
                                      <MDBCol md="6">
                                        <div className="md-form">
                                          <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.shareLink}</label>
                                          <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.shareLink} type="text" id="form920" name="shareLink" className="form-control" required validate/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                        </div>
                                      </MDBCol>
                                    </MDBRow>
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                      <MDBCol md="6">
                                        <div className="md-form">
                                          <label htmlFor="formn920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.viewsCount}</label>
                                          <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.viewsCount} type="text" id="formn920" name="viewsCount" className="form-control" required validate/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                        </div>
                                      </MDBCol>
                                      <MDBCol md="6">
                                        <div className="md-form">
                                          <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.status}</label>
                                          <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.status ==="ON-PROGRESS"?allStrings.marketingOnProgress:this.state.status ==="WAITING-COMMISSION"?allStrings.waitingCommission:this.state.status ==="COMPLETED"?allStrings.marketingComplete:allStrings.marketingFailed} type="text" id="form920" name="status" className="form-control" required validate/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                        </div>
                                      </MDBCol>
                                    </MDBRow>
                                    {this.state.user&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="10" xs="10">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.marketer}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.user?this.state.user.fullname:''} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="2" xs="2">
                                    
                                        <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                            <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.user}) }}>
                                            remove_red_eye
                                            </span>
                                            <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.user}) }}>
                                            {allStrings.show}
                                            </span> 
                                        </p>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.ads&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="10" xs="10">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.adveristment}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.ads?this.state.ads.id:''} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="2" xs="2">
                                    
                                        <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                            <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                            onClick={()=>{this.props.history.push('adsInfo',{data:this.state.ads}) }}>
                                            remove_red_eye
                                            </span>
                                            <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                            onClick={()=>{this.props.history.push('adsInfo',{data:this.state.ads}) }}>
                                            {allStrings.show}
                                            </span> 
                                        </p>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    
                                 
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                    {this.state.status === "WAITING-COMMISSION" &&
                                      <MDBBtn
                                        rounded
                                        color="success"
                                        style={{ borderRadius: "2px" }}
                                        className="btn-block z-depth-1a"
                                        onClick={()=>{
                                          this.marketingComplete()
                                        }}
                                      >
                                        
                                        {allStrings.marketingComplete}
                                      </MDBBtn>
                                    }{this.state.status === "ON-PROGRESS" &&
                                      <MDBBtn
                                          rounded
                                          color="info"
                                          style={{ borderRadius: "2px" }}
                                          className="btn-block z-depth-1a"
                                          onClick={()=>{
                                            this.waitingCommission()
                                          }}
                                        >
                                          <MDBIcon icon="check-circle" className="mr-2" />
                                          {allStrings.waitingCommission}
                                      </MDBBtn>
                                    }
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol> 
            </MDBRow>
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(marketingInfo)
  );