import React from 'react';
import Table from '../../component/table/table'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import './question.css'
import {message,TreeSelect} from 'antd';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {NavLink, withRouter,Redirect} from 'react-router-dom'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import {SearchOutlined} from '@ant-design/icons';

const { TreeNode } = TreeSelect;

class question extends React.Component {
  
  state={
    question:[],
    question2:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    type:"ALL"
  }

  constructor(props){
    super(props)
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  componentDidMount(){
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    this.getQuestions(1,false)

  }


  getQuestions = (page,refresh) =>{
    this.setState({loading:true})
    let {type} = this.state
    let url = `${BASE_END_POINT}questions?page=${page}`
    if(type !== null){
      let str = '&type='+ type
      url += str
    }
    if(type === "ALL"){
      url = `${BASE_END_POINT}questions?page=${page}`
    }

    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log("question   ",response.data)
      let data = response.data.data.map(item=>[item.id,item.question_ar,item.question_en,"action"])
      console.log("DATA   ",data)
      this.setState({
        loading:false,
        refresh:false,
        question:response.data.data,
        question2:data,
        page:response.data.page,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }

  deletequestion = (id) => {
    console.log("ID   ",id)
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}questions/${id}`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`,
       'Accept-Language':this.props.isRTL?'ar':'en'
     },
    })
    .then(response=>{
      console.log("Year DELETED")
      l.then(() => message.success(allStrings.done, 2.5) )
      this.getQuestions(this.state.page)
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
 }

  addNewRecordButton = () =>(
    <div style={{width:'100%'}}>
      <NavLink to="AddQuestion">
        <Fab
          style={{background:'#00B483',float:'left',margin:'50px 30px'}}
          letiant="extended"
          size="medium"
          color="primary"
          aria-label="add"
        >
          <AddIcon />{allStrings.addNewRecored}
        </Fab>
      </NavLink>
    </div>
  )

  paginationButtons = () =>(
    <CardActions style={{display: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getQuestions(1)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
         if(this.state.page>1){
            this.getQuestions(this.state.page-1)
            this.setState({page:this.state.page-1})
         }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getQuestions(page)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`#00B483 !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getQuestions(this.state.page+1)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
         onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getQuestions(this.state.pages)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )

  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('FAQ')) return <Redirect to='/Home' />

  return (
    <div className="App">
        
      <MDBContainer> 
      <MDBRow>
        <MDBCol md="12">
          <div className="screenTable">
          <Table
          settings = {
              <div>
                <i class="fab fa-searchengin searchIcon" onClick={()=>this.setState({showFilter:!this.state.showFilter})}></i>
                <span 
                style={{zIndex: '1',position: 'relative',padding: '4px',color: '#659FF1',fontSize: '25px',borderRadius:'50%',top:'4px',cursor: 'pointer'}}
                onClick={() => {this.getQuestions(1,false)}}
                class="material-icons">
                  replay
                </span>
              </div>
              
          }
          filter ={
            <div>
              <div className={this.state.showFilter?'showClass':'hideClass'} style={{height: this.state.showFilter?'150px':'0px'}}>
               
                <MDBRow>
                  
                  
                  <MDBCol xl="12" lg="12" md="12" sm="12">
                      <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.type}</label>
                      <TreeSelect virtual={false}
                            showSearch={false} 
                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                            treeCheckable = {false}         
                            treeNodeFilterProp="title"    
                            value={this.state.type}                  
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={allStrings.type}
                            onChange={(value) => {
                                console.log(value)
                                this.setState({type:value});

                            }} 
                                            
                          >
                            <TreeNode value="ALL" title={allStrings.all} key="ALL" />
                            <TreeNode value="GLOBAL" title={allStrings.globalQuestion} key="GLOBAL" />
                            <TreeNode value="MARKET" title={allStrings.marketQuestion} key="MARKET" />
                                                
                      </TreeSelect>
                  </MDBCol>
                  <Button style={{background: '#00B483',color: '#fff',width: '100%',margin: '32px auto'}} 
                    onClick={()=>{this.getQuestions(1)}}><SearchOutlined /> {allStrings.Search}</Button>

                </MDBRow>
              </div>
            </div>
          }
            title={allStrings.questions}
            icon='help_outline'
            data={this.state.question}
            data2={this.state.question2}
            tableColumns={["#",allStrings.arabicQuestion,allStrings.englishQuestion,allStrings.action]}
            loading={this.state.loading}
            deleteUser={(id)=>{this.deletequestion(id)}}
            path='/questionInfo'
            actionIndex={3}
            />
            {this.paginationButtons()}
            {this.addNewRecordButton()}
            </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer >
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(question)
);

