import {MENU} from '../actions/types';
const initState = {
    menu:true,
}

const MenuReducer = (state=initState,action) => {
    switch(action.type){
        case MENU:
            console.log("menu",action.payload)
        return {...state,menu:action.payload}
        default:
        return state;
    }
}

export default MenuReducer;