import React from 'react';
import Table from '../../component/table/table'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import './user.css'
import {message,TreeSelect,DatePicker,ConfigProvider} from 'antd';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {withRouter,Redirect} from 'react-router-dom'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { CSVLink } from "react-csv";
import 'moment/locale/ar';
import moment from 'moment'
import {SearchOutlined} from '@ant-design/icons';
import locale_ar from 'antd/lib/locale/ar_EG';
import locale_en from 'antd/lib/locale/en_GB';
const { TreeNode } = TreeSelect;
const { SHOW_PARENT } = TreeSelect;
const { RangePicker } = DatePicker;
let headers = [
  { label: "#", key: "id" },
  { label: "الاسم بالكامل", key: "fullname" },
  { label: "الايميل", key: "email" },
  { label: "رقم الهاتف", key: "phone" },
  { label: "البلد", key: "country" },
  { label: "التاريخ", key: "createdAt" },
];
 
let csvData = [
  
];
class user extends React.Component {
  
  state={
    users:[],
    users2:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    count:0,
    countries:[],
    cities:[],
    areas:[],
    country:null,
    area:null,
    city:null,
    accountType:null,
    search:null,
    csvData:[],
    startDate:null,
    endDate:null,
    isReady:false,
    buildCsv:false

  }

  constructor(props){
    super(props)
    window.scrollTo(0,0)
    
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  componentDidMount(){
    let pageNum = this.state.page
    if(sessionStorage.getItem('pageName') === "USERS"){
      pageNum = sessionStorage.getItem('pageNum');
      this.setState({page:pageNum})
    }
    this.getUsers(pageNum)
    this.getCountries()
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }
  getCountries = () => {
    axios.get(`${BASE_END_POINT}countries/withoutPagenation/get`)
    .then(response=>{
      console.log(response.data)
      this.setState({countries:response.data.countries})
    })
    .catch(error=>{
      console.log("ALL countries ERROR")
      console.log(error.response)
    })
  }
  getAreas = (city) => {
    axios.get(`${BASE_END_POINT}areas/${city}/withoutPagenation/get`)
    .then(response=>{
      console.log(response.data)
      this.setState({areas:response.data.areas})
    })
    .catch(error=>{
      console.log("ALL areas ERROR")
      console.log(error.response)
    })
  }
  getCities = (country) => {
      axios.get(`${BASE_END_POINT}countries/${country}/cities/withoutPagenation/get?country=${country}`)
      .then(response=>{
        console.log(response.data)
        this.setState({cities:response.data.cities})
      })
      .catch(error=>{
        console.log("ALL cities ERROR")
        console.log(error.response)
      })
  }
  getUsers = (page,refresh) =>{
    this.setState({loading:true})
    let url = `${BASE_END_POINT}find?type=USER&page=${page}`
    let {city,area,country,accountType,search,startDate,endDate} = this.state
    if(refresh !== true ){
      let keysToFilter = {city,area,country,accountType,search,startDate,endDate}
      Object.keys(keysToFilter).forEach(key => {
        if (keysToFilter[key] !== null) {
          url += `&${key}=${keysToFilter[key]}`;
        }
      });
    }
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      
      let data = response.data.data.map(item=>[
        item.id,
        item.fullname?item.fullname.substr(0,20):'',
        item.img?{type:"img",img:item.img}:{type:"img",img:""},
        item.phone?item.phone:"",
        item.country?item.country.countryName:"",
        item.accountType==="ACTIVE"?{type:'tag',value:allStrings.active}:{type:'tag',value:allStrings.notActive} ,
        "action"])
      console.log("DATA   ",data)
      this.setState({
        loading:false,
        refresh:false,
        users:response.data.data,
        users2:data,
        count:response.data.totalCount,
        page:parseInt(response.data.page),
        pages:response.data.pageCount,
      })
      sessionStorage.setItem('pageNum', page);
      sessionStorage.setItem('pageName', "USERS");
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  getUsersWithoutPagenation = (refresh) =>{
    let url = `${BASE_END_POINT}withoutPagenation/get?type=USER`
    let {city,area,country,accountType,search,startDate,endDate} = this.state
    if(refresh !== true ){
      let keysToFilter = {city,area,country,accountType,search,startDate,endDate}
      Object.keys(keysToFilter).forEach(key => {
        if (keysToFilter[key] !== null) {
          url += `&${key}=${keysToFilter[key]}`;
        }
      });
    }
    
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      
        csvData= response.data.data.map(item => {
          return {
            ...{id:item.id},
            ...{fullname:item.fullname?item.fullname:''},
            ...{email:item.email?item.email:""},
            ...{phone:item.phone},
            ...{country:item.country?item.country.countryName:""},
            ...{createdAt:item.createdAt?this.props.isRTL?moment(item.createdAt).locale('ar').format('ll'):moment(item.createdAt).locale('en').format('ll'):""}
          };
        });
        this.setState({csvData:csvData,isReady:true})      
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
    })
  }

  deleteUser = (id) => {
    console.log("ID   ",id)
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}/${id}/delete`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
     },
    })
    .then(response=>{
      console.log("USER DELETED")
      l.then(() => message.success(allStrings.done, 2.5) )
      this.getUsers(this.state.page)
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
 }


  paginationButtons = () =>(
  <CardActions style={{display: 'inline-flex'}}>
      <Button 
      onClick={()=>{
        if(this.state.page!==1){
          this.getUsers(1)
          this.setState({page:1})
        }
      }}
      className="pagenation" >{allStrings.first}</Button>

      <Button 
      onClick={()=>{
       if(this.state.page>1){
          this.getUsers(this.state.page-1)
          this.setState({page:this.state.page-1})
       }
      }}
      className="pagenation">{allStrings.prev}</Button>
      
      <Pagination
      onChange={(event,page)=>{
      console.log("page   ",page)
      if(page!==this.state.page){
        this.getUsers(page)
        this.setState({page:page})
      }
      
      }} 
      defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`${'#659FF1'} !important`}} />
      
      <Button 
      onClick={()=>{
        if(this.state.page<this.state.pages){
          this.getUsers(this.state.page+1)
          this.setState({page:this.state.page+1})
        }
      }}
      className="pagenation">{allStrings.next}</Button>

      <Button 
       onClick={()=>{
        if(this.state.page!==this.state.pages){
          this.getUsers(this.state.pages)
          this.setState({page:this.state.pages})
        }
      }}
      className="pagenation">{allStrings.last}</Button>

  </CardActions>
  )


  onChange = (date, dateString) =>{
    if(this.props.isRTL){
      this.setState({
        startDate:moment(dateString[0]).locale('en').format("YYYY-MM-DD"),
        endDate:moment(dateString[1]).locale('en').format("YYYY-MM-DD")
      })
    }else{
      this.setState({to:moment(dateString).locale('en').format("YYYY/MM/DD") })
      this.setState({
        startDate:moment(dateString[0]).locale('en').format("YYYY-MM-DD") ,
        endDate:moment(dateString[1]).locale('en').format("YYYY-MM-DD")
      })
      console.log(moment(dateString[0]).locale('en').format("YYYY-MM-DD") )

    }
    console.log( dateString);
  }

  render() {
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('CLIENTS')) return <Redirect to='/Home' />
    return (
        
      <MDBContainer> 
      <MDBRow>
        <MDBCol md="12">
          <div className="screenTable">
          
          <Table
            settings = {
              <div>
                <i class="fab fa-searchengin searchIcon" onClick={()=>this.setState({showFilter:!this.state.showFilter})}></i>
                <span 
                style={{zIndex: '1',position: 'relative',padding: '4px',color: '#659FF1',fontSize: '25px',borderRadius:'50%',top:'4px',cursor: 'pointer'}}
                onClick={() => {this.getUsers(1,true);this.setState({buildCsv:false})}}
                class="material-icons">
                  replay
                </span>
                {!this.state.buildCsv && 
                  <img crossorigin="anonymous"alt="" onClick={() => {this.setState({buildCsv:true});this.getUsersWithoutPagenation()}}
                  style={{padding: '0px',position: 'relative',top: '-6px',marginLeft:'5px',cursor: 'pointer'}} 
                  src={require('../../assets/images/buildCsv.png')} width="26"></img>
                }{!this.state.isReady && this.state.buildCsv&&
                  <img crossorigin="anonymous" alt="" 
                  style={{padding: '0px',position: 'relative',top: '-5px',marginLeft:'-15px'}} 
                  src={require('../../assets/images/loading.gif')} width="70"></img>
                }{this.state.isReady && this.state.buildCsv&&
                <CSVLink 
                  data={this.state.csvData} 
                  headers={headers} 
                  filename={"users.csv"}
                  style={{padding: '10px',position: 'relative',top: '-5px'}}
                  >
                  <img alt="" src={require('../../assets/images/export-csv.png')} width="28"></img>
                </CSVLink>
                }
              </div>
              
            }
            filter ={
              <div>
                <div className={this.state.showFilter?'showClass':'hideClass'}>
                  <MDBRow>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.area}</label>
                        <TreeSelect virtual={false}
                            showSearch={false}
                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                            treeCheckable = {false}         
                            treeNodeFilterProp="title"                      
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={allStrings.area}
                            value={this.state.area}
                            showCheckedStrategy= {SHOW_PARENT}
                            onChange={(value) => {
                            console.log(value)
                            this.setState({area:value});
                            
                            }} 
                        
                        >
                            {this.state.areas.map(val=>(
                                <TreeNode value={val.id} title={this.props.isRTL?val.areaName_ar:val.areaName_en} key={val.id} />
                            ))}
                        </TreeSelect>
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.city}</label>
                        <TreeSelect virtual={false}
                            showSearch={true}
                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                            treeCheckable = {false}         
                            treeNodeFilterProp="title"                      
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={allStrings.city}
                            value={this.state.city}
                            showCheckedStrategy= {SHOW_PARENT}
                            onChange={(value) => {
                            console.log(value)
                            this.setState({city:value});
                            this.getAreas(value)
                            }} 
                        
                        >
                            {this.state.cities.map(val=>(
                                <TreeNode value={val.id} title={this.props.isRTL?val.cityName_ar:val.cityName_en} key={val.id} />
                            ))}
                        </TreeSelect>
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.country}</label>
                        <TreeSelect virtual={false}
                            showSearch={false}
                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                            treeCheckable = {false}         
                            treeNodeFilterProp="title"                      
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={allStrings.country}
                            value={this.state.country}
                            showCheckedStrategy= {SHOW_PARENT}
                            onChange={(value) => {
                            console.log(value)
                            this.setState({country:value});
                            this.getCities(value)
                            }} 
                        
                        >
                            {this.state.countries.map(val=>(
                                <TreeNode value={val.id} title={this.props.isRTL?val.countryName_ar:val.countryName_en} key={val.id} />
                            ))}
                        </TreeSelect>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                      <div class="md-form">
                      <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.Search}</label>
                      <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',fontWeight:'300'}} type="text" id="form605"  class="form-control"
                        placeholder={allStrings.filterSearch}
                        onChange={(e)=>{
                          this.setState({search:e.target.value})
                        }}  
                      />
                      </div>
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                      <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.date}</label>
                      <ConfigProvider locale={this.props.isRTL?locale_ar:locale_en}>
                        <RangePicker format={"YYYY-MM-DD"} placeholder={[allStrings.startDate,allStrings.endDate]} onChange={this.onChange} />
                      </ConfigProvider>
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                      <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.accountType}</label>
                      <TreeSelect virtual={false}
                          showSearch={false}
                          value={this.state.accountType}
                          style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                          treeCheckable = {false}         
                          treeNodeFilterProp="title"                      
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          placeholder={allStrings.accountType}
                          onChange={(value) => {
                          console.log(value)
                          this.setState({accountType:value});
                          }} 
                      
                      >
                          <TreeNode value="ACTIVE" title={allStrings.active} key="ACTIVE" />
                          <TreeNode value="BLOCKED" title={allStrings.blocked} key="BLOCKED" />
                          <TreeNode value="NOT-ACTIVE" title={allStrings.notActive} key="NOT-ACTIVE" />
                          <TreeNode value="SIGNUP-PROCESS" title={allStrings.signUpProgcess} key="SIGNUP-PROCESS" />
                        
                      </TreeSelect>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol xl="12" lg="12" md="12" sm="12">
                      <Button style={{background: '#00B483',color: '#fff',width: '100%',margin: '32px auto'}} 
                      onClick={()=>{this.getUsers(1);this.setState({buildCsv:false})}}><SearchOutlined /> {allStrings.Search}</Button>
                    </MDBCol>
                  </MDBRow>
                  
                </div>
              </div>
            }
            title={allStrings.users}
            lang={this.props.isRTL}
            icon='group'
            data={this.state.users}
            data2={this.state.users2}
            tableColumns={["#",allStrings.name,allStrings.img,allStrings.phone,allStrings.country,allStrings.accountType,allStrings.action]}
            loading={this.state.loading}
            deleteUser={(id)=>{this.deleteUser(id)}}
            path='/UserInfo'
            actionIndex={7}
            dataCount={this.state.count}
            />
            {this.paginationButtons()}
              
            </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer >
    
    );
  }

}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(user)
);

