import React from 'react';
import './add permission.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message } from "antd";

class Addpermission extends React.Component {
    state = {
        permission_en:'',
        permission_ar:'',
        type:'ADMIN',
        showFeatures:false,
          showSettings:false,
          showDashboard:false,
          showContactUs:false,
          showAds:false,
          showPackages:false,
          showCountries:false,
          showAbout:false,
          showTransactions:false,
          showOffices:false,
          showClients:false,
          showCategories:false,
          showFaq:false,
          showAnoncement:false,
          showNotifs:false,
          showPermissions:false,
          showSubAdmin:false,
          showRequest:false,
          showContactRequest:false,
          showViews:false,
          showAddUser:false,
          showBusiness:false,
          showInterests:false
    };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    add = () => {
        const {permission_en,permission_ar,type,showOffices,showRequest,showAds
            ,showAbout,showAnoncement,showSubAdmin,showPackages,showCategories,showInterests,
            showNotifs,showClients,showContactUs,showCountries,showFaq,showDashboard,showFeatures,
            showPermissions,showSettings,showTransactions,showContactRequest,showViews,showAddUser,showBusiness} = this.state
        if(hasValue(permission_en)&&hasValue(permission_ar)){
            let l = message.loading('Wait..', 2.5)
            const data={
                'permission_en':permission_en,
                'permission_ar':permission_ar,
                'type':type,
            }
            let permissions =[];
            if(showContactRequest ===true){
              permissions.push('CONTACT-REQUEST');
            }
            if(showViews ===true){
              permissions.push('VIEWS')
            }
            if(showAddUser ===true){
              permissions.push('ADD-USER')
            }
            if(showBusiness ===true){
              permissions.push('BUSINESS')
            }
            if(showInterests ===true){
              permissions.push('INTERESTS')
            }
            if(showOffices ===true){
                permissions.push('OFFICES')
            }
            if(showRequest ===true){
                permissions.push('REQUESTS')
            }
            if(showAds ===true){
                permissions.push('ADS')
            }
            if(showClients ===true){
                permissions.push('CLIENTS')
            }
            if(showAbout ===true){
                permissions.push('ABOUT')
            }
            if(showAnoncement ===true){
                permissions.push('ANONCEMENTS')
            }
            if(showSubAdmin ===true){
                permissions.push('SUB-ADMIN')
            }
            if(showPackages ===true){
                permissions.push('PACKAGES')
            }
            if(showCategories ===true){
                permissions.push('CATEGORIES')
            }
            if(showNotifs ===true){
                permissions.push('NOTIFS')
            }
            if(showContactUs ===true){
                permissions.push('CONTACT-US')
            }
            if(showCountries ===true){
                permissions.push('COUNTRIES')
            }
            if(showFaq ===true){
                permissions.push('FAQ')
            }
            if(showDashboard ===true){
                permissions.push('DASHBOARD')
            }
            if(showFeatures ===true){
                permissions.push('FEATURES')
            }
            if(showPermissions ===true){
                permissions.push('PERMISSIONS')
            }
            if(showSettings ===true){
                permissions.push('SETTINGS')
            }
            if(showTransactions ===true){
                permissions.push('TRANSACTIONS')
            }
            
            data.pages = permissions
            axios.post(`${BASE_END_POINT}permission`,data,{
            headers: {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            console.log("Addpermission UPDATED  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
    }
    
      submitHandler = event => {
          event.preventDefault();
          console.log(event.target.className)
          event.target.className += " was-validated";
          this.add()
      };

      changeHandler = event => {
          this.setState({ [event.target.name]: event.target.value });
      };
    
      showAddUserOn =()=>{
        this.setState({
          showAddUser: true,
        })
      }
      showAddUserOff =()=>{
        this.setState({
          showAddUser: false,
        })
      }
      showBusinessOn =()=>{
        this.setState({
          showBusiness: true,
        })
      }
      showBusinessOff =()=>{
        this.setState({
          showBusiness: false,
        })
      }
      showInterestsOn =()=>{
        this.setState({
          showInterests: true,
        })
      }
      showInterestsOff =()=>{
        this.setState({
          showInterests: false,
        })
      }
      showViewsOn =()=>{
        this.setState({
          showViews: true,
        })
      }
      showViewsOff =()=>{
        this.setState({
          showViews: false,
        })
      }
      showContactRequestOn =()=>{
        this.setState({
          showContactRequest: true,
        })
      }
      showContactRequestOff =()=>{
        this.setState({
          showContactRequest: false,
        })
      }
      showSettingsOn =()=>{
        this.setState({
          showSettings: true,
        })
      }
      showSettingsOff =()=>{
        this.setState({
          showSettings: false,
        })
      }
      showFeaturesOn =()=>{
        this.setState({
          showFeatures: true,
        })
      }
      showFeaturesOff =()=>{
        this.setState({
          showFeatures: false,
        })
      }
      showDashboardOn =()=>{
        this.setState({
          showDashboard: true,
        })
      }
      showDashboardOff =()=>{
        this.setState({
          showDashboard: false,
        })
      }
      showContactUsOn =()=>{
        this.setState({
          showContactUs: true,
        })
      }
      showContactUsOff =()=>{
        this.setState({
          showContactUs: false,
        })
      }
      showAdsOn =()=>{
        this.setState({
          showAds: true,
        })
      }
      showAdsOff =()=>{
        this.setState({
          showAds: false,
        })
      }
      showPackagesOn =()=>{
        this.setState({
          showPackages: true,
        })
      }
      showPackagesOff =()=>{
        this.setState({
          showPackages: false,
        })
      }
      showCountriesOn =()=>{
        this.setState({
          showCountries: true,
        })
      }
      showCountriesOff =()=>{
        this.setState({
          showCountries: false,
        })
      }
      showAboutOn =()=>{
        this.setState({
          showAbout: true,
        })
      }
      showAboutOff =()=>{
        this.setState({
          showAbout: false,
        })
      }
      showTransactionsOn =()=>{
        this.setState({
          showTransactions: true,
        })
      }
      showTransactionsOff =()=>{
        this.setState({
          showTransactions: false,
        })
      }
      showOfficesOn =()=>{
        this.setState({
          showOffices: true,
        })
      }
      showOfficesOff =()=>{
        this.setState({
          showOffices: false,
        })
      }
      showClientsOn =()=>{
        this.setState({
          showClients: true,
        })
      }
      showClientsOff =()=>{
        this.setState({
          showClients: false,
        })
      }
      showCategoriesOn =()=>{
        this.setState({
          showCategories: true,
        })
      }
      showCategoriesOff =()=>{
        this.setState({
          showCategories: false,
        })
      }
      showFaqOn =()=>{
        this.setState({
          showFaq: true,
        })
      }
      showFaqOff =()=>{
        this.setState({
          showFaq: false,
        })
      }
      showAnoncementOn =()=>{
        this.setState({
          showAnoncement: true,
        })
      }
      showAnoncementOff =()=>{
        this.setState({
          showAnoncement: false,
        })
      }
      showNotifsOn =()=>{
        this.setState({
          showNotifs: true,
        })
      }
      showNotifsOff =()=>{
        this.setState({
          showNotifs: false,
        })
      }
      showPermissionsOn =()=>{
        this.setState({
          showPermissions: true,
        })
      }
      showPermissionsOff =()=>{
        this.setState({
          showPermissions: false,
        })
      }
      showSubAdminOn =()=>{
        this.setState({
          showSubAdmin: true,
        })
      }
      showSubAdminOff =()=>{
        this.setState({
          showSubAdmin: false,
        })
      }
      showRequestOn =()=>{
        this.setState({
          showRequest: true,
        })
      }
      showRequestOff =()=>{
        this.setState({
          showRequest: false,
        })
      }
   
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('PERMISSIONS')) return <Redirect to='/Home' />

  return (
    <div className="App">
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div  style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#00B483'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>lock</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{allStrings.addPermission}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.permission_en} type="text" id="form645" name="permission_en" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.permission_ar} type="text" id="form56" name="permission_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow style={{flexDirection:this.props.isRTL ? 'row-reverse' : 'row'}}>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showViewsOff}
                                              style={{background:this.state.showViews?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showViews?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showViewsOn}
                                              style={{background:this.state.showViews?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showViews?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.views}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      


                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showFeaturesOff}
                                              style={{background:this.state.showFeatures?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showFeatures?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showFeaturesOn}
                                              style={{background:this.state.showFeatures?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showFeatures?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.Features}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showSettingsOff}
                                              style={{background:this.state.showSettings?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showSettings?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showSettingsOn}
                                              style={{background:this.state.showSettings?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showSettings?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.setting}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showContactUsOff}
                                              style={{background:this.state.showContactUs?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showContactUs?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showContactUsOn}
                                              style={{background:this.state.showContactUs?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showContactUs?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.contactUs}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showPackagesOff}
                                              style={{background:this.state.showPackages?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showPackages?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showPackagesOn}
                                              style={{background:this.state.showPackages?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showPackages?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.Packages}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showCountriesOff}
                                              style={{background:this.state.showCountries?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showCountries?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showCountriesOn}
                                              style={{background:this.state.showCountries?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showCountries?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.Countries}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showAboutOff}
                                              style={{background:this.state.showAbout?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showAbout?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showAboutOn}
                                              style={{background:this.state.showAbout?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showAbout?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.about}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showTransactionsOff}
                                              style={{background:this.state.showTransactions?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showTransactions?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showTransactionsOn}
                                              style={{background:this.state.showTransactions?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showTransactions?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.Transactions}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showOfficesOff}
                                              style={{background:this.state.showOffices?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showOffices?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showOfficesOn}
                                              style={{background:this.state.showOffices?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showOffices?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.offices}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showClientsOff}
                                              style={{background:this.state.showClients?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showClients?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showClientsOn}
                                              style={{background:this.state.showClients?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showClients?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.Clients}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showCategoriesOff}
                                              style={{background:this.state.showCategories?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showCategories?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showCategoriesOn}
                                              style={{background:this.state.showCategories?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showCategories?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.categories}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showFaqOff}
                                              style={{background:this.state.showFaq?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showFaq?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showFaqOn}
                                              style={{background:this.state.showFaq?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showFaq?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.questions}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showNotifsOff}
                                              style={{background:this.state.showNotifs?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showNotifs?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showNotifsOn}
                                              style={{background:this.state.showNotifs?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showNotifs?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.notifs}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showSubAdminOff}
                                              style={{background:this.state.showSubAdmin?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showSubAdmin?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showSubAdminOn}
                                              style={{background:this.state.showSubAdmin?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showSubAdmin?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.supervisors}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showPermissionsOff}
                                              style={{background:this.state.showPermissions?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showPermissions?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showPermissionsOn}
                                              style={{background:this.state.showPermissions?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showPermissions?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.permissions}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking" style={{pointerEvents:this.state.actionType !=="EDIT"?'none':'auto'}}>
                                              <div className="checkingLeft" onClick={this.showBusinessOff}
                                              style={{background:this.state.showBusiness?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showBusiness?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showBusinessOn}
                                              style={{background:this.state.showBusiness?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showBusiness?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.business}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showInterestsOff}
                                              style={{background:this.state.showInterests?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showInterests?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showInterestsOn}
                                              style={{background:this.state.showInterests?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showInterests?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.interests}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showRequestOff}
                                              style={{background:this.state.showRequest?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showRequest?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showRequestOn}
                                              style={{background:this.state.showRequest?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showRequest?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.requests}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showAdsOff}
                                              style={{background:this.state.showAds?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showAds?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showAdsOn}
                                              style={{background:this.state.showAds?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showAds?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.ads}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showContactRequestOff}
                                              style={{background:this.state.showContactRequest?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showContactRequest?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showContactRequestOn}
                                              style={{background:this.state.showContactRequest?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showContactRequest?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.contactRequests}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showDashboardOff}
                                              style={{background:this.state.showDashboard?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showDashboard?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showDashboardOn}
                                              style={{background:this.state.showDashboard?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showDashboard?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.dashboard}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showAddUserOff}
                                              style={{background:this.state.showAddUser?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showAddUser?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showAddUserOn}
                                              style={{background:this.state.showAddUser?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showAddUser?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.AddUser}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showTransactionsOff}
                                              style={{background:this.state.showTransactions?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showTransactions?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showTransactionsOn}
                                              style={{background:this.state.showTransactions?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showTransactions?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.Transactions}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showAnoncementOff}
                                              style={{background:this.state.showAnoncement?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showAnoncement?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showAnoncementOn}
                                              style={{background:this.state.showAnoncement?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showAnoncement?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.anoncements}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                    </MDBRow>
                                   
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                        <MDBBtn
                                        style={{background:'#00B483',color:'#fff'}}
                                        rounded
                                        type="submit"
                                        color="#00B483"
                                        className="btn-block z-depth-1a"
                                        >
                                        <MDBIcon icon="plus" className="mr-2" />
                                            {allStrings.add}          
                                        </MDBBtn>
                                       
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(Addpermission)
  );