import React from 'react';
import { Layout } from 'antd';
import SideMenu from '../component/sideMenu/sideMenu';
import './layout.css'
import OurHeader from '../component/header/header';

const { Content,Header,Footer } = Layout;

const LayoutWithRoute = ({ children ,marginLeft,marginRight}) => {
  return (
    <Layout style={{ minHeight: '100vh',flexDirection: 'row',marginLeft:marginLeft?marginLeft:0,marginRight:marginRight?marginRight:0 }}>
      <SideMenu />
      <Layout>
        <Header className="site-layout-background" style={{ padding: '0px' }}>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:0,width:'100%',marginBottom:30,padding: '0px 0px'}} >
          <OurHeader></OurHeader>
        </div> 
        </Header>
        <Content style={{ margin: '20px 16px' }}>{children}</Content>
        
        <Footer style={{ textAlign: 'center' }}>DashBoard ©2021 Created by SAAEI COMPANY</Footer>
      </Layout>
    </Layout>
  );
};

export default LayoutWithRoute;