export const hasValue = (val) => {
 return val.replace(/\s/g, '').length?true:false
}

export const isEmail = (email) =>{
    let exp = /\S+@\S+\.\S+/;
    return exp.test(email);
}

export const isPhone = (phone) =>{
    let exp = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s/./0-9]*$/g
    return exp.test(phone); 
}

export const isLength = (val,len) =>{
    return val.replace(/\s/g, '').length===len?true:false 
}

export const isNumber = (val) =>{
    return typeof(val)=='number'?true:false
}



